<template>
  <div class="login">
    <div class="cont-con">
      <div class="ti-con">
        <p>{{ systitle }}</p>
      </div>
      <div class="c-login">
        <div class="c-1" />
        <div class="c-2" />
        <div class="c-3" />
        <div class="c-4" />
        <div class="hl-1" />
        <div class="hl-2" />
        <div class="vl-1" />
        <div class="vl-2" />
        <div class="in-con">
          <div class="ic-1" />
          <div class="ic-2" />
          <div class="ic-3" />
          <div class="bin-con">
            <div class="ti-con">
              <div class="indi" />
              <p class="title">
                用户登录
                <span class="en">USER LOGIN</span>
              </p>
            </div>
            <div class="f-con">
              <a-form
                :form="form"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
                @submit="handleSubmit"
              >
                <a-form-item label="">
                  <a-input
                    autocomplete="new-password"
                    v-decorator="[
                      'userName',
                      {
                        rules: [{ required: true, message: '用户名不能为空' }],
                      },
                    ]"
                    ref="userNameInput"
                    placeholder="用户名"
                  >
                    <a-icon
                      :style="{ color: 'rgba(0, 224, 234, 1)' }"
                      slot="prefix"
                      type="user"
                    />
                  </a-input>
                </a-form-item>
                <a-form-item label="">
                  <a-input-password
                    autocomplete="new-password"
                    ref="pwdInput"
                    v-decorator="[
                      'pwd',
                      {
                        rules: [{ required: true, message: '密码不能为空' }],
                      },
                    ]"
                    placeholder="密码"
                  >
                    <a-icon
                      :style="{ color: 'rgba(0, 224, 234, 1)' }"
                      slot="prefix"
                      type="lock"
                    />
                  </a-input-password>
                </a-form-item>
                <a-form-item :wrapper-col="{ span: 24 }">
                  <div class="lock-con">
                    <drag-verify
                      ref="dragVerify"
                      :width="382"
                      :isPassing.sync="isPassingLock"
                      text="请将滑块拖动到右侧"
                      successText="验证通过"
                      handlerIcon="right"
                      successIcon="check"
                      progressBarBg="rgba(0, 224, 234, 0.3)"
                      completedBg="rgba(0, 224, 234, 0.3)"
                    >
                      <a-icon
                        slot="handlerIconSt"
                        :style="{ color: '#fff' }"
                        type="double-right"
                      />
                      <a-icon slot="successIconSt" type="check" />
                    </drag-verify>
                  </div>
                </a-form-item>

                <a-form-item :wrapper-col="{ span: 24 }">
                  <a-button
                    type="primary"
                    class="submit-btn"
                    html-type="submit"
                    :loading="submitLoading"
                  >
                    登录
                  </a-button>
                </a-form-item>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loginApi from "@/request/api/user.js";
import storage from "@/storage/index.js";
import { mapGetters } from "vuex";
import MutationType from "@/store/mutation-type.js";

export default {
  name: "Login",
  data() {
    return {
      isPassingLock: false,
      userName: "",
      pwd: "",
      submitLoading: false,
      form: this.$form.createForm(this, { name: "login" }),
    };
  },
  computed: {
    ...mapGetters(["systitle"]),
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (!this.isPassingLock) {
            this.$message.error("请拖动验证条验证通过");
            return;
          }
          this.submitLoading = true;
          try {
            let res = await loginApi
              .login({
                username: values.userName,
                password: values.pwd,
              })
              .catch((err) => {
                this.submitLoading = false;
              });
            this.submitLoading = false;
            let { access_token: token } = res;
            this.$store.commit(MutationType.SET_TOKEN_MUTATION, token);
            storage.setToken(token);
            this.$store.commit(
              MutationType.SET_USERNAME_MUTATION,
              values.userName
            );
            storage.setUserName(values.userName);
            this.$store.commit(MutationType.SET_PWD_MUTATION, values.pwd);
            storage.setPwd(values.pwd);
            this.$router.push({
              path: "/datav",
            });
          } catch (e) {
            console.log(e);
            this.submitLoading = false;
          }
        }
      });
    },
  },
  mounted() {
    // setTimeout(() => {
    //   let inputs = document.getElementsByTagName("input");
    //   for (let i = 0; i < inputs.length; i++) {
    //     inputs[i].value = "";
    //     inputs[i].setAttribute('autocomplete','off');
    //   }
    // }, 200)
  },
};
</script>

<style lang="scss" scoped>
.logincard-con {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);

  .action-con {
    margin-top: 20px;
  }
}

.lock-con {
  margin-top: 0;
}

.login {
  position: relative;
  height: 100vh;
  width: 100vw;
  background: no-repeat url("~@/assets/images/login/bg-default.jpeg");
  background-size: cover;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
}

.cont-con {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @include flcoljusali();

  .ti-con {
    p {
      font-size: 38px;
      color: #13fffc;
      text-align: center;
    }

    margin: 20px 0;
  }

  .c-login {
    position: relative;
    width: 502px;
    height: 542px;
    background: no-repeat 100%/100% url("~@/assets/images/login/bc-2.png");

    .c-1 {
      position: absolute;
      left: 0;
      top: 0;
      background: no-repeat 100%/100% url("~@/assets/images/login/c-4.png");
      width: 13px;
      height: 13px;
    }

    .c-2 {
      position: absolute;
      left: 0;
      bottom: 0;
      background: no-repeat 100%/100% url("~@/assets/images/login/c-2.png");
      width: 13px;
      height: 13px;
    }

    .c-3 {
      position: absolute;
      right: 0;
      bottom: 0;
      background: no-repeat 100%/100% url("~@/assets/images/login/c-3.png");
      width: 13px;
      height: 13px;
    }

    .c-4 {
      position: absolute;
      right: 0;
      top: 0;
      background: no-repeat 100%/100% url("~@/assets/images/login/c-1.png");
      width: 13px;
      height: 13px;
    }

    .hl-1 {
      position: absolute;
      left: 152px;
      top: 0;
      width: 351px;
      height: 2px;
      background: no-repeat 100%/100% url("~@/assets/images/login/hl-2.png");
    }

    .hl-2 {
      position: absolute;
      right: 152px;
      bottom: 0;
      width: 351px;
      height: 2px;
      background: no-repeat 100%/100% url("~@/assets/images/login/hl-1.png");
    }

    .vl-1 {
      position: absolute;
      left: 0;
      top: 0;
      width: 2px;
      height: 351px;
      background: no-repeat 100%/100% url("~@/assets/images/login/vl-1.png");
    }

    .vl-2 {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 2px;
      height: 351px;
      background: no-repeat 100%/100% url("~@/assets/images/login/vl-2.png");
    }

    .in-con {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 462px;
      height: 502px;
      background: no-repeat 100%/100% url("~@/assets/images/login/bc-1.png");

      .ic-1 {
        position: absolute;
        top: 32px;
        width: 6px;
        height: 21px;
        background: no-repeat 100%/100% url("~@/assets/images/login/ic-1.png");
        opacity: 1;
      }

      .ic-2 {
        position: absolute;
        top: 53px;
        width: 6px;
        height: 21px;
        background: no-repeat 100%/100% url("~@/assets/images/login/ic-1.png");
        opacity: 0.8;
      }

      .ic-3 {
        position: absolute;
        top: 74px;
        width: 6px;
        height: 21px;
        background: no-repeat 100%/100% url("~@/assets/images/login/ic-1.png");
        opacity: 0.6;
      }

      .bin-con {
        position: relative;
        padding: 30px 40px;
        box-sizing: border-box;
        width: 100%;
        height: 100%;

        .ti-con {
          @include flrowjusali(flex-start);

          .indi {
            background-color: #13fffc;
            width: 6px;
            height: 20px;
            margin-right: 8px;
          }

          .title {
            color: #13fffc;
            font-size: 20px;

            .en {
              font-size: 14px;
            }
          }
        }

        .f-con {
          margin-top: 45px;

          .submit-btn {
            width: 100%;
            height: 50px;
            background-color: rgba(0, 224, 234, 1);
            box-sizing: border-box;
            border: unset !important;
          }
        }

        ::v-deep .f-con .drag_verify {
          background-color: transparent !important;
          --textColor: #999 !important;
          border: 1px solid #105777;
          box-sizing: border-box;

          .dv_handler {
            background-color: rgba(0, 224, 234, 1) !important;
          }
        }

        ::v-deep .f-con .ant-input:hover {
          background-color: transparent !important;
        }
      }
    }

    ::v-deep .in-con input {
      line-height: 52px;
      height: 52px;
      border: 1px solid #105777;
      background-color: transparent !important;
      -webkit-box-shadow: unset !important;
      font-size: 15px;
      font-weight: bold;
      // 自动填充文本样式
      &:-webkit-autofill::first-line {
        font-size: 15px;
      }

      &:hover {
        border: 1px solid #13fffc;
      }
    }

    //覆盖自动填充的样式
    ::v-deep .in-con input:-internal-autofill-selected,
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      line-height: 52px;
      height: 52px;
      //文字颜色
      -webkit-text-fill-color: rgba(255, 255, 255, 0.65) !important;
      //光标颜色
      caret-color: rgba(255, 255, 255, 0.65) !important;
      transition: background-color 5000s ease-in-out 0s !important;
      background-color: transparent !important;
      //修改字体的设置无效
      font-size: 15px;
      font-weight: bold;

      &::first-line {
        font-size: 15px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.65);
      }
    }
  }
}

#ui {
  margin-left: 40%;
}
</style>
