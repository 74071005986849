import AMap from 'AMap';
import lineList from '@/views/trans/index/line-list';
import trans from '@/request/api/trans';

export default {
  name: 'Trans',
  data() {
    return {
      marker: null,
      lineArr: lineList['0'],
      dashInfo: [
        {
          id: 1,
          name: '今日车次',
          value: '12车',
        },
        {
          id: 2,
          name: '今日重量',
          value: '425.04吨',
        },
        {
          id: 1,
          name: '累计车次',
          value: '549车',
        },
        {
          id: 1,
          name: '累计重量',
          value: '19481.54吨',
        },
      ],
      carList: [],
      notNextTickOfCarSwiper: true,
      carSwiperKey: 1001,
    };
  },
  computed: {
    carSwiperOption() {
      let { carList } = this;
      let slidesPerView = 8; //这个会控制swiper-slide的高度 最好不要去强制修改css
      this.carSwiperKey += 1;
      return {
        speed: 500,
        loop: carList.length > slidesPerView,
        autoplay: { disableOnInteraction: false, delay: 3000 },
        direction: 'vertical',
        autoHeight: false,
        initialSlide: 0,
        slidesPerView,
      };
    },
  },
  components: {},
  mounted() {
    (async () => {
      let res = await trans.getCarList();
      this.carList = res;
    })();
    setTimeout(() => {
      this.count = 1300;
      {
        let temp = [];
        for (let i = 0; i < 10; i++) {
          let item = {
            id: `war${i + 1}`,
            no: `A${i + 1}`,
            status: '未开工',
            all: '2445',
            al: '0',
            done: '0%',
          };
          temp.push(item);
        }
        this.warlist = temp;
      }
      {
        let temp = [];
        for (let i = 0; i < 10; i++) {
          let item = {
            id: `tec${i + 1}`,
            name: '热烧附',
            all: '2445',
            al: '0',
            done: '0%',
          };
          temp.push(item);
        }
        this.teclist = temp;
      }
    }, 2000);
    let map = new AMap.Map('mapc', {
      mapStyle: 'amap://styles/dark', //设置地图的显示样式
      resizeEnable: true,
      center: [106.50673034665613, 29.487320720791487],
      zoom: 15,
    });
    // this.marker = new AMap.Marker({
    //     map: map,
    //     position: [116.478935, 39.997761],
    //     icon: "https://webapi.amap.com/images/car.png",
    //     offset: new AMap.Pixel(-26, -13),
    //     autoRotation: true,
    //     angle: -90,
    // });
    let { lineArr, marker } = this;
    // 绘制轨迹
    let polyline = new AMap.Polyline({
      map: map,
      path: lineArr,
      isOutline: true,
      outlineColor: '#ffeeff',
      borderWeight: 3,
      strokeColor: '#3366FF',
      strokeOpacity: 1,
      strokeWeight: 6,
      // 折线样式还支持 'dashed'
      strokeStyle: 'solid',
      // strokeStyle是dashed时有效
      strokeDasharray: [10, 5],
      lineJoin: 'round',
      lineCap: 'round',
      zIndex: 50,
    });
    {
      let polyline = new AMap.Polyline({
        map: map,
        path: lineList['1'],
        isOutline: true,
        outlineColor: '#ffeeff',
        borderWeight: 3,
        strokeColor: '#3366FF',
        strokeOpacity: 1,
        strokeWeight: 6,
        // 折线样式还支持 'dashed'
        strokeStyle: 'solid',
        // strokeStyle是dashed时有效
        strokeDasharray: [10, 5],
        lineJoin: 'round',
        lineCap: 'round',
        zIndex: 50,
      });
    }
    {
      let polyline = new AMap.Polyline({
        map: map,
        path: lineList['2'],
        isOutline: true,
        outlineColor: '#ffeeff',
        borderWeight: 3,
        strokeColor: '#3366FF',
        strokeOpacity: 1,
        strokeWeight: 6,
        // 折线样式还支持 'dashed'
        strokeStyle: 'solid',
        // strokeStyle是dashed时有效
        strokeDasharray: [10, 5],
        lineJoin: 'round',
        lineCap: 'round',
        zIndex: 50,
      });
    }

    // let passedPolyline = new AMap.Polyline({
    //     map: map,
    //     // path: lineArr,
    //     strokeColor: "#AF5",  //线颜色
    //     // strokeOpacity: 1,     //线透明度
    //     strokeWeight: 6,      //线宽
    //     // strokeStyle: "solid"  //线样式
    // });
    /*
        marker.on('moving', function (e) {
            passedPolyline.setPath(e.passedPath);
        });

        map.setFitView();

        function startAnimation() {
            marker.moveAlong(lineArr, 200);
        }

        function pauseAnimation() {
            marker.pauseMove();
        }

        function resumeAnimation() {
            marker.resumeMove();
        }

        function stopAnimation() {
            marker.stopMove();
        }
        */

    // startAnimation();
  },
  methods: {
    sadehandleOk() {},
    sadehandleCancel() {},
    onOutStartChange() {},
    onOutEndChange() {},
  },
};
