// 全局变量以及方法
const vglobal = function(_this) {
  return {
    // loading
    loading: {
      obj: null,
      show: function() {
        this.obj = _this.$toast({
          type: "loading",
          duration: 0,
          message: "加载中...",
          forbidClick: true,
        });
      },
      hide: function() {
        try {
          this.obj.clear();
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
};
export default vglobal;
