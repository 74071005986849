import echarts from "echarts";
export default {
  data() {
    return {
      /** 轮询定时器id */
      pollingid: null,
      /** 轮询定时器时间间隔 5分钟*/
      pollingtime: 5 * 1000 * 60,
    };
  },
  methods: {
    loadChart(domId, option) {
      let _this = this;
      return new Promise((resolve, reject) => {
        _this.$nextTick(() => {
          try {
            const chart = echarts.init(document.getElementById(domId));
            chart.setOption(option);
            resolve(chart);
          } catch (error) {
            reject(error);
          }
        });
      }).catch((error) => {
        console.log("error", error); //这里会打印捕获的异常是什么，我这里是false
      });
    },
    /** 开启轮询数据请求 */
    startPollingData() {
      if (!this.handlePollingData) {
        return;
      }
      if (!this.pollingid) {
        this.pollingid = setInterval(() => {
          this.handlePollingData();
        }, this.pollingtime);
      }
    },
  },
  beforeDestroy() {
    if (this.pollingid) {
      clearInterval(this.pollingid);
    }
  },
};
