var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"safely datav-box"},[_vm._m(0),_c('div',{staticClass:"right-con"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"five-con dv-box-border"},[_vm._m(3),_c('div',{staticClass:"trend-con"},[_c('div',{staticClass:"table-con"},[_vm._m(4),_c('div',{staticClass:"scr-con"},[_c('swiper',{ref:"recSwiper",attrs:{"options":_vm.recSwiperOption,"not-next-tick":_vm.notNextTickOfRecSwiper}},_vm._l(([
                  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12,
                ]),function(item,index){return _c('swiper-slide',{key:index},[_c('div',{staticClass:"tr"},[_c('div',{staticClass:"td"},[_c('span',{},[_vm._v("施工机具")])]),_c('div',{staticClass:"td"},[_c('span',{},[_vm._v("安全")])]),_c('div',{staticClass:"td"},[_c('span',{},[_vm._v("高殿泽")])]),_c('div',{staticClass:"td"},[_c('span',{},[_vm._v(" 高殿泽 ")])]),_c('div',{staticClass:"td"},[_c('span',{},[_vm._v(" 是否合格 ")])]),_c('div',{staticClass:"td"},[_c('span',{},[_vm._v(" 07-27 09:30 ")])])])])}),1)],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"left-con"},[_c('div',{staticClass:"fi-con dv-box-border"},[_c('div',{staticClass:"dv-board-title nptrem"},[_c('div',{staticClass:"title-con"},[_c('span',{staticClass:"title"},[_vm._v("检查人排行榜")]),_c('span',{staticClass:"title2"},[_vm._v("Checker leaderboard")])])]),_c('div',{staticClass:"cheperchart-con"},[_c('div',{staticClass:"cheperchart",attrs:{"id":"cheperchart"}})])]),_c('div',{staticClass:"se-con dv-box-border"},[_c('div',{staticClass:"dv-board-title nptrem"},[_c('div',{staticClass:"title-con"},[_c('span',{staticClass:"title"},[_vm._v("安全类型数量")]),_c('span',{staticClass:"title2"},[_vm._v("Security Type")])])]),_c('div',{staticClass:"seqchart-con"},[_c('div',{staticClass:"seqchart",attrs:{"id":"seqchart"}})])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"fi-con dv-box-border"},[_c('div',{staticClass:"dv-board-title nptrem"},[_c('div',{staticClass:"title-con"},[_c('span',{staticClass:"title"},[_vm._v("质量检查")]),_c('span',{staticClass:"title2"},[_vm._v("Quality Inspection")])])]),_c('div',{staticClass:"tab-con"},[_c('div',{staticClass:"item-con active"},[_vm._v("今日检查")]),_c('div',{staticClass:"item-con"},[_vm._v("七日检查")])]),_c('div',{staticClass:"inplist-con"},[_c('div',{staticClass:"item-con",staticStyle:{"background":"rgb(20, 181, 178)"}},[_c('div',{staticClass:"title"},[_vm._v("已完成")]),_c('div',{staticClass:"content"},[_vm._v("3")])]),_c('div',{staticClass:"item-con",staticStyle:{"background":"rgb(85, 112, 181)"}},[_c('div',{staticClass:"title"},[_vm._v("待整改")]),_c('div',{staticClass:"content"},[_vm._v("3")])]),_c('div',{staticClass:"item-con",staticStyle:{"background":"rgb(255, 149, 117)"}},[_c('div',{staticClass:"title"},[_vm._v("待审核")]),_c('div',{staticClass:"content"},[_vm._v("3")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"se-con dv-box-border"},[_c('div',{staticClass:"dv-board-title nptrem"},[_c('div',{staticClass:"title-con"},[_c('span',{staticClass:"title"},[_vm._v("最新动态")]),_c('span',{staticClass:"title2"},[_vm._v("Latest News")])])]),_c('div',{staticClass:"saprchart-con"},[_c('div',{staticClass:"saprchart",attrs:{"id":"saprchart"}})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"dv-board-title nptrem"},[_c('div',{staticClass:"title-con"},[_c('span',{staticClass:"title"},[_vm._v("最新动态")]),_c('span',{staticClass:"title2"},[_vm._v("Latest News")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"title-con"},[_c('div',{staticClass:"item-con"},[_c('p',[_vm._v("检查名称")])]),_c('div',{staticClass:"item-con"},[_c('p',[_vm._v("检查类型")])]),_c('div',{staticClass:"item-con"},[_c('p',[_vm._v("创建人")])]),_c('div',{staticClass:"item-con"},[_c('p',[_vm._v("检查人")])]),_c('div',{staticClass:"item-con"},[_c('p',[_vm._v("状态")])]),_c('div',{staticClass:"item-con"},[_c('p',[_vm._v("创建时间")])])])
}]

export { render, staticRenderFns }