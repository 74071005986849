import {Progress, Tooltip} from "ant-design-vue";
import NumCount from "@/components/num-count";
import overlayApi from "@/request/api/overlay.js";

/**
 页面上的 v-if="warlist && warlist.length > 0" 针对swiper的判断
 是有必要的 目前如果不加这个会发现设置loop循环的会失效
 */
export default {
    name: 'Progress',
    data() {
        return {
            /** 项目信息 */
            projectInfo: {
                all: {
                    all: 50,
                    online: 10,
                    percentage: 70,
                },
                ba: {
                    all: 0,
                    online: 0,
                    percentage: 0,
                },
                al: {
                    all: 0,
                    online: 0,
                    percentage: 0,
                }
            },
            count: 800,
            notNextTickOfLogSwiper: true,
            notNextTickOfTecSwiper: true,
            /** 总/开挖量 */
            proAnl: {},
            /** 基坑/开挖量 */
            jkAnl: {},
            /* 开挖/修复 */
            kxAnl: {},
            /* 修复/验收 */
            xfysAnl: {},
            /** 开挖工程量 */
            proAlAmount: [],
            /* 基坑修复 */
            jkxfAnl: [],
            /* 污染土工艺 */
            wrtgyAnl: [],
            logskey: 1001,
            tecskey: 2001
        };
    },
    components: {
        "a-progress": Progress,
        NumCount,
        "a-tooltip": Tooltip,
    },
    computed: {
        logSwiperOption() {
            let {jkxfAnl} = this;
            let slidesPerView = 8;//这个会控制swiper-slide的高度 最好不要去强制修改css
            this.logskey += 1;
            return {
                speed: 500,
                loop: jkxfAnl.length > slidesPerView,
                autoplay: {disableOnInteraction: false, delay: 3000},
                direction: "vertical",
                autoHeight: false,
                initialSlide: 0,
                //这个属性为一页显示的数量 一定要准确对照页面上
                //能够显示的个数填写 不然会出现问题
                slidesPerView
            }
        },
        tecSwiperOption(){
            let {wrtgyAnl} = this;
            let slidesPerView = 8;//这个会控制swiper-slide的高度 最好不要去强制修改css
            this.tecskey += 1;
            return {
                speed: 500,
                loop: wrtgyAnl.length > slidesPerView,
                autoplay: {disableOnInteraction: false, delay: 3000},
                direction: "vertical",
                autoHeight: false,
                initialSlide: 0,
                slidesPerView,
            }

        },
    },
    mounted() {
        /** 进度管理 */
        (async () => {
            try {
                let res = await overlayApi.getQuantitiesTotal();
                if (res) {
                    let {jzcl, zjkl, ykwl, xfys} = res;
                    jzcl.ratio = parseFloat(jzcl.ratio);
                    zjkl.ratio = parseFloat(zjkl.ratio);
                    ykwl.ratio = parseFloat(ykwl.ratio);

                    this.proAnl = jzcl;
                    this.jkAnl = zjkl;
                    this.kxAnl = ykwl;

                    for (const key in xfys) {
                        xfys[key] = parseInt(xfys[key]);
                    }
                    this.xfysAnl = xfys;
                }
            } catch (e) {
                console.warn(e);
            }
        })();
        /** 开挖工程/基坑修复状态/各类污染土工艺 */
        (async () => {
            try {
                let res = await overlayApi.getProgress();
                if (res) {
                    let {kwgcl, jkxf, wrtgy} = res;
                    this.proAlAmount = kwgcl;
                    this.jkxfAnl = jkxf;
                    this.wrtgyAnl = wrtgy;
                }
            } catch (e) {
                console.warn(e);
            }
        })();
    },
    methods: {},
};
