import moment from 'moment';
// 工具方法
const utils = {
  /**
   * 获取url中的参数 兼容history 和 hash
   */
  getUrlQuery: function() {
    try {
      let qs =
        location.search.length > 0
          ? location.search.substring(1)
          : window.location.hash.split('?')[1];
      let args = {},
        //取得每一项
        items = qs.length ? qs.split('&') : [],
        item = null,
        name = null,
        value = null,
        //在 for 循环中使用
        i = 0,
        len = items.length;
      for (i = 0; i < len; i++) {
        item = items[i].split('=');
        name = decodeURIComponent(item[0]);
        value = decodeURIComponent(item[1]);
        if (name.length) {
          args[name] = value;
        }
      }
      return args;
    } catch (error) {
      console.warn(error);
      return {};
    }
  },
  /**
   * 返回含有年月日的对象
   * @param time 时间戳
   */
  getDateObj: function(time) {
    var date = new Date(time);
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
      text:
        date.getFullYear() +
        '年' +
        (date.getMonth() + 1) +
        '月' +
        date.getDate() +
        '日',
    };
  },
  /**
   * 阿拉伯数字转换汉字数字
   * @param num 阿拉伯数字
   */
  arabToCnNumber: function(num) {
    const data = {
      0: '零',
      1: '一',
      2: '二',
      3: '三',
      4: '四',
      5: '五',
      6: '六',
      7: '七',
      8: '八',
      9: '九',
    };
    let result = num
      .toString()
      .split('')
      .map((v) => data[v] || v)
      .join('');
    return result;
  },
  /**
   * 分转元
   * @param cent 人民币元
   * @param isFloat 是否保留两位小数
   */
  centToYuan: function(cent, isFloat) {
    if (typeof cent === 'number') {
      if (isFloat === undefined) {
        return (cent / 100).toFixed(2);
      }
      if (isFloat === true) {
        return (cent / 100).toFixed(2);
      } else {
        return (cent / 100).toFixed(0);
      }
    } else {
      return cent;
    }
  },
  /**
   * 元转分
   * @param cent 人民币分
   */
  yuanToCent: function(yuan) {
    return Number(yuan) * 100;
  },
  /**
   * 校验
   */
  verify: {
    // 金额校验
    money: function(str) {
      return /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(
        str
      );
    },
    // 手机号校验
    phone: function(str) {
      return /^1\d{10}$/.test(str);
    },
    // 身份证号校验
    idCard: function(str) {
      return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/gi.test(str);
    },
  },
  /**
   * 数字转xxxx.xxk
   * @param n 数字
   */
  numberToK: function(n) {
    if (n >= 1000) {
      var a = Math.floor(n / 1000);
      var b = Math.floor((n % 1000) / 100);
      if (b === 0) {
        return a + 'k';
      } else {
        return a + '.' + b + 'k';
      }
    } else {
      return n + '';
    }
  },
  /**
   * 为MobileSelect插件组装日期数据
   * @param start 开始日期（moment对象）
   * @param end 结束日期（moment对象）
   */
  generateDateDataForMobileSelect: function(start, end) {
    let dateArrayForMobileSelect = [];
    let dateInitObj = {};
    for (let d = 0; d < moment.duration(end.diff(start)).asDays(); d++) {
      let _start = moment(Object.assign({}, start));
      _start.add(d, 'd');
      if (!(_start.year() in dateInitObj)) {
        dateInitObj[_start.year()] = {
          [_start.month() + 1]: {
            [_start.date()]: {},
          },
        };
      } else {
        if (!(_start.month() + 1 in dateInitObj[_start.year()])) {
          dateInitObj[_start.year()][_start.month() + 1] = {
            [_start.date()]: {},
          };
        } else {
          if (
            !(_start.date() in dateInitObj[_start.year()][_start.month() + 1])
          ) {
            dateInitObj[_start.year()][_start.month() + 1][_start.date()] = {};
          }
        }
      }
    }
    for (let year in dateInitObj) {
      dateArrayForMobileSelect.push({
        id: `${year}`,
        value: `${year}`,
        title: `${year}年`,
        childs: [],
      });
      for (let month in dateInitObj[year]) {
        for (let i = 0; i < dateArrayForMobileSelect.length; i++) {
          if (dateArrayForMobileSelect[i].id === year + '') {
            dateArrayForMobileSelect[i].childs.push({
              id: `${year}${month < 10 ? '0' + month : month}`,
              value: `${year}-${month < 10 ? '0' + month : month}`,
              title: `${month < 10 ? '0' + month : month}月`,
              childs: [],
            });
            break;
          }
        }
        for (let date in dateInitObj[year][month]) {
          for (let i = 0; i < dateArrayForMobileSelect.length; i++) {
            if (dateArrayForMobileSelect[i].id === year + '') {
              for (
                let j = 0;
                j < dateArrayForMobileSelect[i].childs.length;
                j++
              ) {
                if (
                  dateArrayForMobileSelect[i].childs[j].id ===
                  `${year}${month < 10 ? '0' + month : month}`
                ) {
                  dateArrayForMobileSelect[i].childs[j].childs.push({
                    id: `${year}${month < 10 ? '0' + month : month}${
                      date < 10 ? '0' + date : date
                    }`,
                    value: `${year}-${month < 10 ? '0' + month : month}-${
                      date < 10 ? '0' + date : date
                    }`,
                    title: `${date < 10 ? '0' + date : date}日`,
                    childs: [],
                  });
                }
              }
              break;
            }
          }
        }
      }
    }
    return dateArrayForMobileSelect;
  },
  /**
   * 根据日期数组为MobileSelect插件组装日期数据
   * @param dateArr 日期数组 如['2020-03-04', '2020-03-05', '2020-04-01', '2021-01-31']
   */
  generateDateDataByDateArrayForMobileSelect: function(dateArr) {
    let dateObj = {};
    let selectData = [];
    for (let i = 0; i < dateArr.length; i++) {
      let momentDate = moment(dateArr[i]);
      let year = momentDate.year();
      let month = momentDate.month() + 1;
      let date = momentDate.date();
      if (!dateObj[year]) {
        dateObj[year] = {};
      }
      for (let j in dateObj) {
        if (parseInt(j) === year && !dateObj[year][month]) {
          dateObj[year][month] = {};
        }
        for (let z in dateObj[year]) {
          if (parseInt(z) === month) {
            dateObj[year][month][date] = {};
          }
        }
      }
    }
    for (let year in dateObj) {
      selectData.push({
        id: `${year}`,
        value: `${year}`,
        title: `${year}年`,
        childs: [],
      });
      for (let month in dateObj[year]) {
        for (let i = 0; i < selectData.length; i++) {
          if (selectData[i].id === `${year}`) {
            selectData[i].childs.push({
              id: `${year}${month < 10 ? '0' + month : month}`,
              value: `${year}${month < 10 ? '0' + month : month}`,
              title: `${month < 10 ? '0' + month : month}月`,
              childs: [],
            });
          }
          for (let date in dateObj[year][month]) {
            for (let j = 0; j < selectData[i].childs.length; j++) {
              if (
                selectData[i].childs[j].id ===
                `${year}${month < 10 ? '0' + month : month}`
              ) {
                selectData[i].childs[j].childs.push({
                  id: `${year}${month < 10 ? '0' + month : month}${
                    date < 10 ? '0' + date : date
                  }`,
                  value: `${year}-${month < 10 ? '0' + month : month}-${
                    date < 10 ? '0' + date : date
                  }`,
                  title: `${date < 10 ? '0' + date : date}日`,
                  childs: [],
                });
              }
            }
          }
        }
      }
    }
    return selectData;
  },
  /**
   * 根据日期查询该日期在MobileSelect日期数据里对应的索引值
   * @param dateData 日期数据元
   * @param target 时间戳
   */
  getDateDataForMobileSelectIndexByDate: function(dateData, target) {
    let index = [];
    for (let y = 0; y < dateData.length; y++) {
      for (let m = 0; m < dateData[y].childs.length; m++) {
        for (let d = 0; d < dateData[y].childs[m].childs.length; d++) {
          if (
            dateData[y].childs[m].childs[d].id ==
            moment(Number(target)).format('YYYYMMDD')
          ) {
            index = [y, m, d];
            break;
          }
        }
      }
    }
    return index;
  },
  /**
   * 判断是否为云之家webview
   */
  isSupportQing: function() {
    return qing.isSupportNativeJsBridge;
  },
  /**
   * 后端时间戳转前端时间戳
   * @param timestamp 后端时间戳
   */
  backendToFrontendTimestamp: function(timestamp) {
    return timestamp * 1000;
  },
  /**
   * 格式化日期
   */
  formatDate: {
    /**
     * 转为 YYYY-MM-DD hh:mm 格式
     * @param timestamp 时间戳
     */
    toYYYYMMDDhhmm: function(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD HH:mm');
    },
    /**
     * 转为 YYYY-MM-DD 格式
     * @param timestamp 时间戳
     */
    toYYYYMMDD: function(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD');
    },
    toYYYYMM: function(timestamp) {
      return moment(timestamp).format('YYYY-MM');
    },
    toMMDD: function(timestamp) {
      return moment(timestamp).format('MM-DD');
    },
  },
  /**
   * 游玩时间 转换为分钟
   * @param minutes 分钟数
   */
  formatMinutes: function(minutes) {
    const days = Math.floor(minutes / 1440);
    const hours = Math.floor((minutes - days * 1440) / 60);
    const mins = minutes % 60;
    let string = '';
    if (days > 0) {
      string += `${days}天`;
    }
    if (hours > 0) {
      string += `${hours}小时`;
    }
    if (mins > 0) {
      string += `${mins}分`;
    }
    return string;
  },
  /**
   * 数字转xxxx.xxW
   * @param n 数字
   */
  numberToW: function(n) {
    if (n >= 10000) {
      if (n >= 9999000) {
        return '999w+';
      } else {
        var a = Math.floor(n / 10000);
        var b = Math.floor((n % 10000) / 1000);
        if (b === 0) {
          return a + 'w';
        } else {
          return a + '.' + b + 'w';
        }
      }
    } else {
      return n + '';
    }
  },
  /*
   *   功能:实现VBScript的DateAdd功能.
   *   参数:interval,字符串表达式，表示要添加的时间间隔.
   *   参数:number,数值表达式，表示要添加的时间间隔的个数.
   *   参数:date,时间对象.
   *   返回:新的时间对象.
   *   var now = new Date();
   *   var newDate = DateAdd( "d", 5, now);
   *---------------   DateAdd(interval,number,date)   -----------------
   */
  dateAdd: function(interval, number, date) {
    switch (interval) {
      case 'y ': {
        date.setFullYear(date.getFullYear() + number);
        return date;
        break;
      }
      case 'q ': {
        date.setMonth(date.getMonth() + number * 3);
        return date;
        break;
      }
      case 'm ': {
        date.setMonth(date.getMonth() + number);
        return date;
        break;
      }
      case 'w ': {
        date.setDate(date.getDate() + number * 7);
        return date;
        break;
      }
      case 'd ': {
        date.setDate(date.getDate() + number);
        return date;
        break;
      }
      case 'h ': {
        date.setHours(date.getHours() + number);
        return date;
        break;
      }
      case 'm ': {
        date.setMinutes(date.getMinutes() + number);
        return date;
        break;
      }
      case 's ': {
        date.setSeconds(date.getSeconds() + number);
        return date;
        break;
      }
      default: {
        date.setDate(d.getDate() + number);
        return date;
        break;
      }
    }
  },
  /**
   * 生成bigdata模块顶部时间选择的默认数据对象
   * @return { Object } object
   */
  generateBigDataTimeRangeDefault: function() {
    let now = new Date();
    // 昨天.
    let yesterDayDate = this.dateAdd('d ', -1, now);
    let ydateString = this.formatDate.toYYYYMMDD(yesterDayDate.getTime());
    // 开始.
    let startDayDate = this.dateAdd('d ', -1 - 30, new Date());
    let sdateString = this.formatDate.toYYYYMMDD(startDayDate.getTime());
    let dateRangeString = `${sdateString} ~ ${ydateString}`;
    return {
      yesterDayDate,
      ydateString,
      startDayDate,
      sdateString,
      dateRangeString,
    };
  },
  /**
   * 乘法
   */
  NumMul(arg1, arg2) {
    var m = 0,
      s1 = arg1.toString(),
      s2 = arg2.toString();
    try {
      m += s1.split('.')[1].length;
    } catch (e) {}
    try {
      m += s2.split('.')[1].length;
    } catch (e) {}
    return (
      (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
      Math.pow(10, m)
    );
  },
  /**
   * 除法 arg1/arg2
   */
  NumDiv(arg1, arg2) {
    var t1 = 0,
      t2 = 0,
      r1,
      r2;
    try {
      t1 = arg1.toString().split('.')[1].length;
    } catch (e) {}
    try {
      t2 = arg2.toString().split('.')[1].length;
    } catch (e) {}
    r1 = Number(arg1.toString().replace('.', ''));
    r2 = Number(arg2.toString().replace('.', ''));
    return (r1 / r2) * Math.pow(10, t2 - t1);
  },
  /**
   * 加法
   */
  NumAdd(arg1, arg2) {
    var r1, r2, m;
    try {
      r1 = arg1.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    return (this.NumMul(arg1, m) + this.NumMul(arg2, m)) / m;
  },
  /**
   * 减法 arg1-arg2
   */
  NumAdd(arg1, arg2) {
    var r1, r2, m;
    try {
      r1 = arg1.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    return (this.NumMul(arg1, m) - this.NumMul(arg2, m)) / m;
  },
  checkStringEmpty(value) {
    if (!value) {
      return true;
    }
    return (
      value == '' ||
      value == '(null)' ||
      value.length == 0 ||
      value == '<null>' ||
      value == 'null' ||
      value == '(nil)'
    );
  },
  // num为设置的大小（px）
  getSizeByScreen: function(num) {
    // 这里的3200为你设计稿的宽度。
    return Math.ceil((num / 1920) * window.screen.width);
  },
  /**
   * 处理路由页面切换时，异步组件加载过渡的处理函数
   * @param  {Object} AsyncView 需要加载的组件，如 import('@/components/home/Home.vue')
   * @return {Object} 返回一个promise对象
   */
  lazyLoadView: function(AsyncView) {
    const AsyncHandler = () => ({
      // 需要加载的组件 (应该是一个 `Promise` 对象)
      component: AsyncView,
      // 异步组件加载时使用的组件
      loading: require('@/components/preloading/index.vue').default,
      // 加载失败时使用的组件
      error: require('@/components/preloading/error.vue').default,
      // 展示加载时组件的延时时间。默认值是 200 (毫秒)
      delay: 0,
      // 如果提供了超时时间且组件加载也超时了，
      // 则使用加载失败时使用的组件。默认值是：`Infinity`
      timeout: 10000,
    });
    return Promise.resolve({
      functional: true,
      render(h, { data, children }) {
        return h(AsyncHandler, data, children);
      },
    });
  },
};

export default utils;
