<template>
  <div class="towermo datav-box">
    <div class="left-con">
      <div class="fi-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">设备报警</span>
            <span class="title2">Checker leaderboard</span>
          </div>
        </div>
        <div class="list-con">
          <div class="table-con">
            <div class="title-con">
              <div class="item-con">
                <p>时间</p>
              </div>
              <div class="item-con">
                <p>设备编号</p>
              </div>
              <div class="item-con">
                <p>报警内容</p>
              </div>
            </div>
            <div class="scr-con">
              <swiper
                v-if="warningList && warningList.length > 0"
                :options="realfSwiperOption"
                :not-next-tick="notNextTickOfrealfSwiper"
                ref="realfSwiper"
              >
                <swiper-slide v-for="(item, index) in warningList" :key="index">
                  <div class="tr">
                    <div class="td">
                      <span class="">{{ item.time }}</span>
                    </div>
                    <div class="td">
                      <span class="">{{ item.serialNo }}</span>
                    </div>
                    <div class="td">
                      <span class="">
                        {{ item.message }}
                      </span>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
      <div class="se-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">设备报警统计</span>
            <span class="title2">Security Type</span>
          </div>
        </div>
        <div class="seqchart-con">
          <bbchart-con :data="seqData">
            <div class="seqchart" id="seqchart"></div>
          </bbchart-con>
        </div>
      </div>
    </div>
    <div class="right-con">
      <div class="fi-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">设备统计</span>
            <span class="title2">Quality Inspection</span>
          </div>
        </div>
        <div class="tab-con" :style="{ opacity: 0 }">
          <div class="item-con active">今日检查</div>
          <div class="item-con">七日检查</div>
        </div>
        <div class="inplist-con">
          <div class="item-con" style="background: rgb(20, 181, 178)">
            <div class="title">总数量</div>
            <div class="content">{{ deCountInfo.total }}</div>
          </div>
          <div class="item-con" style="background: rgb(85, 112, 181)">
            <div class="title">在线</div>
            <div class="content">{{ deCountInfo.online }}</div>
          </div>
          <div class="item-con" style="background: rgb(255, 149, 117)">
            <div class="title">离线</div>
            <div class="content">{{ deCountInfo.offline }}</div>
          </div>
        </div>
      </div>
      <div class="se-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">报警问题趋势</span>
            <span class="title2">Latest News</span>
          </div>
        </div>
        <div class="saprchart-con">
          <bbchart-con :data="saprData">
            <div id="saprchart" class="saprchart"></div>
          </bbchart-con>
        </div>
      </div>
      <div class="five-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">历史数据</span>
            <span class="title2">Latest News</span>
          </div>
        </div>
        <div class="trend-con">
          <div class="table-con">
            <div class="title-con">
              <div class="item-con">
                <p>塔吊备案号</p>
              </div>
              <div class="item-con">
                <p>设备序列号</p>
              </div>
              <div class="item-con">
                <p>采集时间</p>
              </div>
              <div class="item-con">
                <p>转角</p>
              </div>
              <div class="item-con">
                <p>高度(m)</p>
              </div>
              <div class="item-con">
                <p>吊重(吨)</p>
              </div>
              <div class="item-con">
                <p>风速（m/s）</p>
              </div>
            </div>
            <div class="scr-con">
              <swiper
                :options="recSwiperOption"
                :not-next-tick="notNextTickOfRecSwiper"
                ref="recSwiper"
              >
                <swiper-slide v-for="(item, index) in realList" :key="index">
                  <div class="tr">
                    <div class="td">
                      <span class="">{{ item.registNo }}</span>
                    </div>
                    <div class="td">
                      <span class="">{{ item.serialNo }}</span>
                    </div>
                    <div class="td">
                      <span class="">{{ item.recordTime }}</span>
                    </div>
                    <div class="td">
                      <span class="">{{ item.angle }}</span>
                    </div>
                    <div class="td">
                      <span class=""> {{ item.height }} </span>
                    </div>
                    <div class="td">
                      <span class=""> {{ item.load }} </span>
                    </div>
                    <div class="td">
                      <span class=""> {{ item.windSpeed }} </span>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Index from "./index.js";
export default Index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>