<template>
  <div class="home">
    <div class="container-con">
      <nav-bar :list="meunlist" @change="handleNavChange"></nav-bar>
      <div
        class="main-con nptrem"
        :style="{
          height: showBtabs ? 'calc(100vh - 142px)' : 'calc(100vh - 72px)',
        }"
      >
        <keep-alive>
          <!--使用keep-alive会将页面缓存-->
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </div>
      <bottom-tabs v-show="showBtabs" :list="btabsList" />
    </div>
  </div>
</template>
<script>
import Index from "./index";
export default Index;
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
