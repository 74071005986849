export let result = [
  
	{
		"id": 227,
		"name": "重庆轨道交通十八号线工程轨道工程标",
		"pid": 0,
		"node": [{
			"id": 2082,
			"name": "办公区后场",
			"pid": 227,
			"deviceSerial": "K31351670",
			"channelNo": 7,
			"channelStatus": 0,
			"channelId": "59228cac17984264b5f10594dc66c76a"
		}, {
			"id": 2083,
			"name": "办公区外1",
			"pid": 227,
			"deviceSerial": "K31351670",
			"channelNo": 1,
			"channelStatus": 0,
			"channelId": "c995656c64c647cf8661e27bbe49264d"
		}, {
			"id": 2084,
			"name": "办公区外2",
			"pid": 227,
			"deviceSerial": "K31351670",
			"channelNo": 4,
			"channelStatus": 0,
			"channelId": "d4a994b265234c46b51187e8a0dff096"
		}, {
			"id": 2085,
			"name": "厨房后场",
			"pid": 227,
			"deviceSerial": "K31351670",
			"channelNo": 5,
			"channelStatus": 0,
			"channelId": "5e676576ba6a45da9f0bfc0214bf6b71"
		}, {
			"id": 2086,
			"name": "大门口",
			"pid": 227,
			"deviceSerial": "K31351670",
			"channelNo": 3,
			"channelStatus": 0,
			"channelId": "669b415e7a6e4151af787c4dcf3c34e0"
		}, {
			"id": 2087,
			"name": "宿舍后场",
			"pid": 227,
			"deviceSerial": "K31351670",
			"channelNo": 2,
			"channelStatus": 0,
			"channelId": "15c40c4cbbaa454c87414fa7c4ef0f48"
		}, {
			"id": 2088,
			"name": "生活区过道",
			"pid": 227,
			"deviceSerial": "K31351670",
			"channelNo": 6,
			"channelStatus": 0,
			"channelId": "ae44005f6e684ed1addd63161d6cf0cf"
		}, {
			"id": 2319,
			"name": "加工区建委",
			"pid": 227,
			"deviceSerial": "K62528311",
			"channelNo": 1,
			"channelStatus": 0,
			"channelId": "d3936008b9c54756b991f4dae5307f5a"
		}, {
			"id": 2320,
			"name": "金鳌山轨道左右球机建委",
			"pid": 227,
			"deviceSerial": "K61674235",
			"channelNo": 1,
			"channelStatus": 0,
			"channelId": "7f81517995d54430b187da4ae3ba21d4"
		}, {
			"id": 2321,
			"name": "金鳌山站轨道左球机建委",
			"pid": 227,
			"deviceSerial": "K61674196",
			"channelNo": 1,
			"channelStatus": 0,
			"channelId": "742e47448882450a99bad6a6043bd967"
		}, {
			"id": 2322,
			"name": "伏牛溪站车辆段交汇处球机建委",
			"pid": 227,
			"deviceSerial": "K61674251",
			"channelNo": 1,
			"channelStatus": 0,
			"channelId": "31cc4bf682d94f639f33f5f764e4117d"
		}, {
			"id": 2436,
			"name": "金鳌山生活区枪机1",
			"pid": 227,
			"deviceSerial": "K15910825",
			"channelNo": 1,
			"channelStatus": 0,
			"channelId": "2594cc456e07453593f6dac3b9201f49"
		}, {
			"id": 2437,
			"name": "金鳌山生活区枪机2",
			"pid": 227,
			"deviceSerial": "K23719561",
			"channelNo": 1,
			"channelStatus": 0,
			"channelId": "fd2acd39462648c0a9c587270f9378a1"
		}, {
			"id": 2441,
			"name": "电厂站生活区枪机建委",
			"pid": 227,
			"deviceSerial": "K44084189",
			"channelNo": 1,
			"channelStatus": 0,
			"channelId": "f202f7f227ba41959b9bb16bc5f9b341"
		}, {
			"id": 2442,
			"name": "电厂站生活区枪机建委",
			"pid": 227,
			"deviceSerial": "K44084090",
			"channelNo": 1,
			"channelStatus": 0,
			"channelId": "8245cde1eba241e387a2f28c4a830a41"
		}, {
			"id": 2443,
			"name": "电厂站现场球机建委",
			"pid": 227,
			"deviceSerial": "K78359154",
			"channelNo": 1,
			"channelStatus": 0,
			"channelId": "f7ac16edb65f4fd7b684b9c51034da79"
		}, {
			"id": 2444,
			"name": "电厂站现场球机建委",
			"pid": 227,
			"deviceSerial": "K78359153",
			"channelNo": 1,
			"channelStatus": 0,
			"channelId": "143419f3026a42ccb606b57d51efc2a3"
		}, {
			"id": 2445,
			"name": "指挥部2楼-枪机",
			"pid": 227,
			"deviceSerial": "K31359240",
			"channelNo": 3,
			"channelStatus": 0,
			"channelId": "74dcd38e45c04c6fa25b52ec7494af62"
		}, {
			"id": 2446,
			"name": "龙门吊-球机1建委",
			"pid": 227,
			"deviceSerial": "K31359240",
			"channelNo": 1,
			"channelStatus": 0,
			"channelId": "ecc40bab108b446f9491a09657a3b713"
		}, {
			"id": 2447,
			"name": "龙门吊-球机2建委",
			"pid": 227,
			"deviceSerial": "K31359240",
			"channelNo": 2,
			"channelStatus": 0,
			"channelId": "bffec42126cd4af8a1ee89d86710df03"
		}, {
			"id": 2448,
			"name": "指挥部后场通道-枪机",
			"pid": 227,
			"deviceSerial": "K31359240",
			"channelNo": 4,
			"channelStatus": 0,
			"channelId": "22c1280367364195af33d2f8935e30b6"
		}, {
			"id": 2456,
			"name": "伏牛溪站大门口建委",
			"pid": 227,
			"deviceSerial": "K44084016",
			"channelNo": 1,
			"channelStatus": 0,
			"channelId": "bc874dcd0ac1450d945803a7858e406c"
		}, {
			"id": 2457,
			"name": "伏牛锡站龙门吊枪机建委",
			"pid": 227,
			"deviceSerial": "L08952322",
			"channelNo": 1,
			"channelStatus": 0,
			"channelId": "913cbe211f1c462f8e1e59b874314cef"
		}, {
			"id": 2458,
			"name": "外河坪站隧道右侧枪机建委",
			"pid": 227,
			"deviceSerial": "K44084207",
			"channelNo": 1,
			"channelStatus": 0,
			"channelId": "a099de1294be42848ce08dd7822e294c"
		}, {
			"id": 2459,
			"name": "外河坪站隧道左侧枪机建委",
			"pid": 227,
			"deviceSerial": "K44084015",
			"channelNo": 1,
			"channelStatus": 0,
			"channelId": "f3f645c9a711429c9d767893abad3cf7"
		}]
	}
];
