<template>
  <div class="hm-con">
    <div class="left-con">
      <div class="fi-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">采集仪设备信息</span>
            <span class="title2"></span>
          </div>
        </div>
        <div class="dvlist-con">
          <div class="item-con active">
            <div class="device-left-detail">
              <div class="device-state istate"></div>
              <div class="device-name">高支模</div>
              <div class="card-num">采集仪编号：1000200628</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mi-con dv-box-border"></div>
    <div class="ri-con">
      <div class="fi-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">实时数据</span>
            <span class="title2"></span>
          </div>
        </div>
        <div class="list-con">
          <div class="wr-con">
            <div class="item-con">
              <div class="ri-icon">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAMAAADyHTlpAAAAeFBMVEVMaXEAm/4Am/8Am/8AnP8Am/4Am/8Am/8Akv8Anv8Anf8Am/4Am/4Am/4Am/8Am/8Am/4Am/0Am/4Am/4AnP8Am/4Am/8Am/4AnP8Am/0AnP8AnP8Am/0Am/0Am/4Am/4Am/4Am/4Am/0Am/4Am/8Am/8Am/4Am/6+8bA8AAAAJ3RSTlMAyQwoMPNXHwIGFoHZwxJo1Yq05TjpSd54n19AmKWs7Pi4kc9PcL1eEuNgAAACG0lEQVR42pVV6darIAyEKriAAuK+b5X3f8OvtRbU2t5z8yscJ5lMliM4mmthEUHKYUtSB/wwDzcSTVFTF83AER+rb8Aby2SLndvrFVosSIL0EpnSJD9x2jUarA+gK1QdfsbbAyrP5IO85gJ3JI5IyL/qTVG9RwbcB18t3ucdk589TBV+u7OqDgody15bpi1HW6pQEmDMyYNeKZXV1S44iF4OyXYpWKdkI0gNlWpNVXa/BoYJM5MtFMfh6tmFymL9oVnWxpmkbqEKw4D7ztJpkf2sYzTsqtD+Kjfw3v5EXOAkmsZP6EE4IKrUTZgcgBMdeVf4NG45uXoOMxCDrnTpzgszKvvNSAmIRs3fRR9z0hV4sAVTrptvRGnhSn8dIKBMZ13wJ1QPMuIgY2Z+4GyVYhpKdQFrQU877ImqTAF7WW3wMNiGhmaS3tt9yBKRKWy1zECxKdWnAmDpHaDcMvuYSN8swQysLt5Dpdk8i6vUXCN3gAfFIeu4Yb0yUcwIhOJBntObhvKqUagtq3gWVGUzMAzoye0jvD3V88LTpV+z03x/xDVcmy7oS9ittF5qZnYv48M8rD7d1jQH/7Bh2SLL3v6NZL3uYJOFv5DxrhXhFNy+I62u3r18GoRfc3btQaIPaXyNxKhwTxdX9OSiCKdB94twmbHwBCQdvCTzSULrKtzoPB+3kpcuuLaQLUkXFIKQsYEoa/HhJP7jF/cHVHUmu/wpGzkAAAAASUVORK5CYII="
                  alt=""
                />
                <div class="txt">初始电量</div>
              </div>
              <div class="ri-con">
                <div class="num">84%</div>
              </div>
            </div>
            <div class="item-con">
              <div class="ri-icon">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAMAAADyHTlpAAAAeFBMVEVMaXEAm/4Am/8Am/8AnP8Am/4Am/8Am/8Akv8Anv8Anf8Am/4Am/4Am/4Am/8Am/8Am/4Am/0Am/4Am/4AnP8Am/4Am/8Am/4AnP8Am/0AnP8AnP8Am/0Am/0Am/4Am/4Am/4Am/4Am/0Am/4Am/8Am/8Am/4Am/6+8bA8AAAAJ3RSTlMAyQwoMPNXHwIGFoHZwxJo1Yq05TjpSd54n19AmKWs7Pi4kc9PcL1eEuNgAAACG0lEQVR42pVV6darIAyEKriAAuK+b5X3f8OvtRbU2t5z8yscJ5lMliM4mmthEUHKYUtSB/wwDzcSTVFTF83AER+rb8Aby2SLndvrFVosSIL0EpnSJD9x2jUarA+gK1QdfsbbAyrP5IO85gJ3JI5IyL/qTVG9RwbcB18t3ucdk589TBV+u7OqDgody15bpi1HW6pQEmDMyYNeKZXV1S44iF4OyXYpWKdkI0gNlWpNVXa/BoYJM5MtFMfh6tmFymL9oVnWxpmkbqEKw4D7ztJpkf2sYzTsqtD+Kjfw3v5EXOAkmsZP6EE4IKrUTZgcgBMdeVf4NG45uXoOMxCDrnTpzgszKvvNSAmIRs3fRR9z0hV4sAVTrptvRGnhSn8dIKBMZ13wJ1QPMuIgY2Z+4GyVYhpKdQFrQU877ImqTAF7WW3wMNiGhmaS3tt9yBKRKWy1zECxKdWnAmDpHaDcMvuYSN8swQysLt5Dpdk8i6vUXCN3gAfFIeu4Yb0yUcwIhOJBntObhvKqUagtq3gWVGUzMAzoye0jvD3V88LTpV+z03x/xDVcmy7oS9ittF5qZnYv48M8rD7d1jQH/7Bh2SLL3v6NZL3uYJOFv5DxrhXhFNy+I62u3r18GoRfc3btQaIPaXyNxKhwTxdX9OSiCKdB94twmbHwBCQdvCTzSULrKtzoPB+3kpcuuLaQLUkXFIKQsYEoa/HhJP7jF/cHVHUmu/wpGzkAAAAASUVORK5CYII="
                  alt=""
                />
                <div class="txt">初始电量</div>
              </div>
              <div class="ri-con">
                <div class="num">84%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="se-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">设备日志</span>
            <span class="title2"></span>
          </div>
        </div>
        <div class="list-con">
          <div class="table-con">
            <div class="title-con">
              <div class="item-con">
                <p>序号</p>
              </div>
              <div class="item-con">
                <p>类型</p>
              </div>
              <div class="item-con">
                <p>时间</p>
              </div>
              <div class="item-con">
                <p>内容</p>
              </div>
            </div>
            <div class="scr-con">
              <!-- <swiper
                :options="logSwiperOption"
                :not-next-tick="notNextTickOfLogSwiper"
                ref="logSwiper"
              >
                <swiper-slide v-for="(item, index) in warlist" :key="item.id">
                  <div class="tr">
                    <div class="td">{{ index + 1 }}</div>
                    <div class="td">
                      <a-tooltip>
                        <template slot="title">
                          <span> {{ item.rule_name }} </span>
                        </template>
                        <span class=""> {{ item.rule_name }} </span>
                      </a-tooltip>
                    </div>
                    <div class="td">
                      <span class=""> {{ item.creation_time }} </span>
                    </div>
                    <div class="td rule-name">
                      <span class="">
                        {{ item.warn_desc }}
                      </span>
                    </div>
                  </div>
                </swiper-slide>
              </swiper> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Index from "./index.js";
export default Index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>