import BYMixins from '@/mixins/index/index.js';
import towermoApi from '@/request/api/towermo.js';
export default {
  name: 'Towermo',
  mixins: [BYMixins],
  components: {},
  data() {
    return {
      /** 设备统计 */
      deCountInfo: {
        total: '-',
        online: '-',
        offline: '-',
      },
      cheproData: {},
      seqData: {},
      saprData: [],
      realfSwiperOption: {
        speed: 500,
        loop: true,
        autoplay: { disableOnInteraction: false, delay: 3000 },
        direction: 'vertical',
        autoHeight: false,
        initialSlide: 0,
        slidesPerView: 5,
      },
      /** 设备信息 */
      reals: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12],
      /** 实时信息 */
      realList: [],
      /** 报警信息 */
      warningList: [],
      recSwiperOption: {
        initialSlide: 0,
        slidesPerView: 9,
        direction: 'vertical',
        loop: false,
        speed: 500,
        autoplay: { disableOnInteraction: false, delay: 3000 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiper: true,
      notNextTickOfrealfSwiper: true,
    };
  },
  mounted() {
    this.loadData();
    // this.loadCheperChart();
  },
  methods: {
    loadData() {
      /** 统计信息 */
      (async () => {
        try {
          let res = await towermoApi.getonline();
          let { allcount, offline, online } = res;
          this.deCountInfo.total = allcount;
          this.deCountInfo.offline = offline;
          this.deCountInfo.online = online;
        } catch (error) {}
      })();
      /** 实时信息 */
      (async () => {
        try {
          let res = await towermoApi.getrealtime();
          this.realList = res;
        } catch (error) {}
      })();
      /** 报警列表 */
      (async () => {
        try {
          let res = await towermoApi.getwarning();
          this.warningList = res;
        } catch (error) {}
      })();
      /** 报警统计 */
      (async () => {
        try {
          let res = await towermoApi.getwarningtotal();
          this.seqData = {
            values: [res],
          };
          this.loadSeqChart();
        } catch (error) {}
      })();
      /** 报警统计按天 */
      (async () => {
        try {
          let res = await towermoApi.getwarningday();
          this.saprData = res;
          this.loadSaprChart();
        } catch (error) {}
      })();
    },
    async loadCheperChart() {
      let { getRankingList } = this.getMockData();
      let { result } = getRankingList;
      let names = result.map((item) => {
        return item.creatorName;
      });
      let values = result.map((item) => {
        return item.peopleNum;
      });
      let option = {
        color: ['#00ffdb'],
        tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
        grid: {
          left: '3%',
          right: '3%',
          top: '4%',
          bottom: '1%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: names,
            axisTick: { alignWithLabel: true },
            axisLabel: {
              interval: 0,
              rotate: 50,
              textStyle: { color: '#FFF', fontSize: 12 },
            },
            axisLine: {
              lineStyle: { color: 'rgba(255, 255, 255, .3)' },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            minInterval: 1,
            axisLabel: {
              interval: 0,
              textStyle: { color: '#FFF', fontSize: 12 },
            },
            axisLine: {
              lineStyle: { color: 'rgba(255, 255, 255, .3)' },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgba(255, 255, 255, .1)',
              },
            },
          },
        ],
        series: [{ name: '责任人', type: 'bar', barWidth: '30', data: values }],
      };
      await this.loadChart(`cheperchart`, option);
    },
    async loadSeqChart() {
      if (!this.seqData) {
        return;
      }
      if (!this.seqData.values) {
        return;
      }
      let result = this.seqData.values[0];
      let names = ['设备数量', '报警数量'];
      let values = [
        { name: '设备数量', value: result.allcount },
        { name: '报警数量', value: result.warning },
      ];
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        color: [
          '#00c8cc',
          '#407fff',
          '#f29961',
          '#e15d68',
          '#a081e4',
          '#03a43b',
        ],
        legend: {
          orient: 'vertical',
          x: 'left',
          data: names,
          textStyle: {
            fontSize: 12,
            color: 'rgba(255, 255, 255, .7)',
          },
        },
        series: [
          {
            name: '报警统计',
            type: 'pie',
            radius: [0, 70],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            label: { normal: { show: true } },
            labelLine: { normal: { show: true } },
            data: values,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
              normal: {
                label: {
                  show: true,
                  position: 'outer',
                  formatter: '{b}: {c}({d}%)',
                },
              },
            },
          },
        ],
      };
      await this.loadChart(`seqchart`, option);
    },
    async loadSaprChart() {
      if (!this.saprData) {
        return;
      }
      let names = this.saprData.map((item) => {
        let { day } = item;
        let dayps = day.split('-');
        let rday = `${dayps[1]}-${dayps[2]}`;
        return rday;
      });
      let data = this.saprData.map((item) => item.warning);
      let option = {
        color: ['#305683'],
        tooltip: { trigger: 'axis' },
        grid: {
          left: '3%',
          right: '3%',
          top: '4%',
          bottom: '1%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: names,
            axisTick: { alignWithLabel: true },
            axisLabel: {
              interval: 0,
              rotate: 50,
              textStyle: { color: '#fff', fontSize: 12 },
            },
            axisLine: {
              lineStyle: { color: 'rgba(255, 255, 255, .3)' },
            },
          },
        ],
        yAxis: {
          type: 'value',
          minInterval: 1,
          axisLabel: {
            interval: 0,
            textStyle: { color: '#fff', fontSize: 12 },
          },
          axisLine: {
            lineStyle: { color: 'rgba(255, 255, 255, .3)' },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: 'rgba(255, 255, 255, .1)',
            },
          },
        },
        series: [
          {
            name: '数量',
            data,
            type: 'line',
            areaStyle: {},
          },
        ],
      };
      await this.loadChart(`saprchart`, option);
    },
  },
};
