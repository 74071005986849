<template>
  <div class="vod">
    <iframe
      src="https://d.xin-ptt.com/#/Login?encryption=MTIzNDU2Nzg5MDk4NzAwLDE="
      frameborder="0"
      style="width: 100%; height: 100%"
      ref="iframe"
    ></iframe>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$loading.show();
    this.$refs.iframe.onload = () => {
      this.$loading.hide();
    };
  },
};
</script>

<style lang="scss" scoped>
.vod {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
