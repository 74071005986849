<template>
  <div class="labour datav-box">
    <div class="content-con">
      <div class="left-con">
        <div class="top-con dv-box-border">
          <div class="dv-board-title nptrem">
            <div class="title-con">
              <span class="title">实时动态</span>
            </div>
          </div>
          <div class="list-con">
            <div class="table-con">
              <div class="title-con">
                <div class="item-con">
                  <p>人脸信息</p>
                </div>
                <div class="item-con">
                  <p>姓名</p>
                </div>
                <div class="item-con">
                  <p>时间</p>
                </div>
                <div class="item-con">
                  <p>进出状态</p>
                </div>
              </div>
              <div class="scr-con">
                <swiper
                  v-if="reals && reals.length > 0"
                  :options="realfSwiperOption"
                  :not-next-tick="notNextTickOfrealfSwiper"
                  ref="realfSwiper"
                >
                  <swiper-slide v-for="(item, index) in reals" :key="index">
                    <div class="tr">
                      <div class="td">
                        <img class="avatar" :src="item.FaceData" alt="" />
                      </div>
                      <div class="td">
                        <span class="">{{ item.PersonName }}</span>
                      </div>
                      <div class="td">
                        <span class=""> {{ item.addtime }} </span>
                      </div>
                      <div class="td rule-name">
                        <span class=""> {{ item.entry }} </span>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
        </div>
        <div class="se-con dv-box-border">
          <div class="dv-board-title nptrem">
            <div class="title-con">
              <span class="title">工种在册分析</span>
            </div>
          </div>
          <div class="anchart-con">
            <bbchart-con :data="anChartData">
              <div id="anchart" class="anchart"></div>
            </bbchart-con>
          </div>
        </div>
      </div>
      <div class="middle-con">
        <div class="top-con">
          <div
            v-for="(item, index) in mtopvaInfo"
            :key="index"
            class="item-con dv-box-border"
          >
            <p class="title">{{ item.name }}</p>
            <p class="value">
              {{ `${item.value} ` }}<span class="unit">{{ item.unit }}</span>
            </p>
          </div>
        </div>
        <div class="se-con dv-box-border">
          <div class="dv-board-title nptrem">
            <div class="title-con">
              <span class="title">出勤率趋势</span>
            </div>
          </div>
          <div class="man-con">
            <div class="item-con">
              <div class="tag-con">
                <p>劳务人员</p>
              </div>
              <div class="num-con">
                <div class="item-con">
                  <p v-plhovalue="atcaselabInfo.sumcount"></p>
                  <p>在册</p>
                </div>
                <div class="item-con">
                  <p v-plhovalue="atcaselabInfo.cqcount"></p>
                  <p>出勤</p>
                </div>
                <div class="rate-con">
                  <p class="title">出勤率</p>
                  <div class="inner-con">
                    <p><span v-plhovalue="atcaselabInfo.attendance"></span>%</p>
                  </div>
                </div>
              </div>
              <div class="msechart-con">
                <bbchart-con :data="mselaChartData">
                  <div id="mselachart" class="chart"></div>
                </bbchart-con>
              </div>
            </div>
            <div class="item-con">
              <div class="tag-con">
                <p>管理人员</p>
              </div>
              <div class="num-con">
                <div class="item-con">
                  <p v-plhovalue="atcasemanInfo.sumcount"></p>
                  <p>在册</p>
                </div>
                <div class="item-con">
                  <p v-plhovalue="atcasemanInfo.cqcount"></p>
                  <p>出勤</p>
                </div>
                <div class="rate-con">
                  <p class="title">出勤率</p>
                  <div class="inner-con">
                    <p><span v-plhovalue="atcasemanInfo.attendance"></span>%</p>
                  </div>
                </div>
              </div>
              <div class="msechart-con">
                <bbchart-con :data="msemanChartData">
                  <div id="msemanagechart" class="chart"></div>
                </bbchart-con>
              </div>
            </div>
          </div>
        </div>
        <div class="thr-con dv-box-border">
          <div class="dv-board-title nptrem">
            <div class="title-con">
              <span class="title">总出勤率趋势</span>
            </div>
          </div>
          <div class="mthrchart-con">
            <bbchart-con :data="mthrChartData">
              <div class="chart" id="mthrchart"></div>
            </bbchart-con>
          </div>
        </div>
      </div>
      <div class="right-con dv-box-border">
        <div class="item-con">
          <div class="dv-board-title nptrem">
            <div class="title-con">
              <span class="title">管理人员</span>
            </div>
          </div>
          <div class="list-con">
            <div
              class="ct-con"
              v-for="(item, index) in emManaList"
              :key="index"
            >
              <div class="title-con">
                <p class="title">{{ item.company }}</p>
              </div>
              <div class="data-con">
                <p class="title">
                  在册人数{{ item.count }} 今日出勤{{ item.kqcount }}
                </p>
                <div class="dc-con">
                  <div
                    class="line-con"
                    :style="{ width: `${item.per}%` }"
                  ></div>
                  <p class="dcline-title">{{ item.per }}%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item-con">
          <div class="dv-board-title nptrem">
            <div class="title-con">
              <span class="title">劳务人员</span>
            </div>
          </div>
          <div class="list-con">
            <div class="ct-con" v-for="(item, index) in emLabList" :key="index">
              <div class="title-con">
                <p class="title">{{ item.company }}</p>
              </div>
              <div class="data-con">
                <p class="title">
                  在册人数{{ item.count }} 今日出勤{{ item.kqcount }}
                </p>
                <div class="dc-con">
                  <div
                    class="line-con"
                    :style="{ width: `${item.per}%` }"
                  ></div>
                  <p class="dcline-title">{{ item.per }}%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Index from "./index.js";
export default Index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
