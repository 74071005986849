import http from '../http.js';

const api = {
  devicelist: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/radio/devicelist`,
      data: params,
    });
  },
  startshout: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'post',
      url: `/datav/radio/startshout`,
      data: params,
    });
  },
  sendshout: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'post',
      url: `/datav/radio/sendshout`,
      data: params,
    });
  },
  uploadvideo: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'post',
      url: `/datav/radio/uploadvideo`,
      data: params,
      formData: true,
    });
  },
};
export default api;
