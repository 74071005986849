<template>
  <div class="quality datav-box">
    <div class="left-con">
      <div class="fi-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">检查人排行榜</span>
            <span class="title2">Checker leaderboard</span>
          </div>
        </div>
        <div class="cheperchart-con">
          <div id="cheperchart" class="cheperchart"></div>
        </div>
      </div>
      <div class="se-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">安全类型数量</span>
            <span class="title2">Security Type</span>
          </div>
        </div>
        <div class="seqchart-con">
          <div class="seqchart" id="seqchart"></div>
        </div>
      </div>
    </div>
    <div class="right-con">
      <div class="fi-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">质量检查</span>
            <span class="title2">Quality Inspection</span>
          </div>
        </div>
        <div class="tab-con">
          <div class="item-con active">今日检查</div>
          <div class="item-con">七日检查</div>
        </div>
        <div class="inplist-con">
          <div class="item-con" style="background: rgb(20, 181, 178)">
            <div class="title">已完成</div>
            <div class="content">3</div>
          </div>
          <div class="item-con" style="background: rgb(85, 112, 181)">
            <div class="title">待整改</div>
            <div class="content">3</div>
          </div>
          <div class="item-con" style="background: rgb(255, 149, 117)">
            <div class="title">待审核</div>
            <div class="content">3</div>
          </div>
        </div>
      </div>
      <div class="se-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">最新动态</span>
            <span class="title2">Latest News</span>
          </div>
        </div>
        <div class="trend-con">
          <div class="table-con">
            <div class="title-con">
              <div class="item-con">
                <p>检查图片</p>
              </div>
              <div class="item-con">
                <p>任务名称</p>
              </div>
              <div class="item-con">
                <p>任务状态</p>
              </div>
              <div class="item-con">
                <p>检查人</p>
              </div>
              <div class="item-con">
                <p>检查时间</p>
              </div>
            </div>
            <div class="scr-con">
              <swiper
                :options="trendSwiperOption"
                :not-next-tick="notNextTickOfTrendSwiper"
                ref="trendSwiper"
              >
                <swiper-slide>
                  <div class="tr">
                    <div class="td">
                      <img
                        class="avatar"
                        :src="require(`@/assets/images/main/avatar-icon1.png`)"
                        alt=""
                      />
                    </div>
                    <div class="td">
                      <span class="">隐蔽验收工程</span>
                    </div>
                    <div class="td">
                      <span class=""> 合格 </span>
                    </div>
                    <div class="td">
                      <span class=""> 行为均 </span>
                    </div>
                    <div class="td">
                      <span class=""> 04-15 10:21 </span>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="tr">
                    <div class="td">
                      <img
                        class="avatar"
                        :src="require(`@/assets/images/main/avatar-icon1.png`)"
                        alt=""
                      />
                    </div>
                    <div class="td">
                      <span class="">隐蔽验收工程</span>
                    </div>
                    <div class="td">
                      <span class=""> 合格 </span>
                    </div>
                    <div class="td">
                      <span class=""> 行为均 </span>
                    </div>
                    <div class="td">
                      <span class=""> 04-15 10:21 </span>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
      <div class="thr-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">质量问题趋势</span>
            <span class="title2">Quality Problem trends</span>
          </div>
        </div>
        <div class="quprochart-con">
          <div class="quprochart" id="quprochart"></div>
        </div>
      </div>
      <div class="fout-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">待审核责任人统计</span>
            <span class="title2">Tobechecked Responsible</span>
          </div>
        </div>
        <div class="trend-con">
          <div class="table-con">
            <div class="title-con">
              <div class="item-con">
                <p>序号</p>
              </div>
              <div class="item-con">
                <p>待审核任务名称</p>
              </div>
              <div class="item-con">
                <p>检查人姓名</p>
              </div>
              <div class="item-con">
                <p>检查内容</p>
              </div>
            </div>
            <div class="scr-con">
              <swiper
                :options="tobSwiperOption"
                :not-next-tick="notNextTickOfTobSwiper"
                ref="tobSwiper"
              >
                <swiper-slide>
                  <div class="tr">
                    <div class="td">
                      <span class="">1</span>
                    </div>
                    <div class="td">
                      <span class="">隐蔽工程验收</span>
                    </div>
                    <div class="td">
                      <span class=""> 高殿泽 </span>
                    </div>
                    <div class="td">
                      <span class=""> 是否合格 </span>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="tr">
                    <div class="td">
                      <span class="">1</span>
                    </div>
                    <div class="td">
                      <span class="">隐蔽工程验收</span>
                    </div>
                    <div class="td">
                      <span class=""> 高殿泽 </span>
                    </div>
                    <div class="td">
                      <span class=""> 是否合格 </span>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="tr">
                    <div class="td">
                      <span class="">1</span>
                    </div>
                    <div class="td">
                      <span class="">隐蔽工程验收</span>
                    </div>
                    <div class="td">
                      <span class=""> 高殿泽 </span>
                    </div>
                    <div class="td">
                      <span class=""> 是否合格 </span>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="tr">
                    <div class="td">
                      <span class="">1</span>
                    </div>
                    <div class="td">
                      <span class="">隐蔽工程验收</span>
                    </div>
                    <div class="td">
                      <span class=""> 高殿泽 </span>
                    </div>
                    <div class="td">
                      <span class=""> 是否合格 </span>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
      <div class="five-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">待整改责任人统计</span>
            <span class="title2">Toberectified Responsible</span>
          </div>
        </div>
        <div class="trend-con">
          <div class="table-con">
            <div class="title-con">
              <div class="item-con">
                <p>序号</p>
              </div>
              <div class="item-con">
                <p>待审核任务名称</p>
              </div>
              <div class="item-con">
                <p>修改人姓名</p>
              </div>
              <div class="item-con">
                <p>整改内容</p>
              </div>
            </div>
            <div class="scr-con">
              <swiper
                :options="recSwiperOption"
                :not-next-tick="notNextTickOfRecSwiper"
                ref="recSwiper"
              >
                <!-- slides -->
                <swiper-slide>
                  <div class="tr">
                    <div class="td">
                      <span class="">1</span>
                    </div>
                    <div class="td">
                      <span class="">隐蔽工程验收</span>
                    </div>
                    <div class="td">
                      <span class=""> 高殿泽 </span>
                    </div>
                    <div class="td">
                      <span class=""> 是否合格 </span>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="tr">
                    <div class="td">
                      <span class="">1</span>
                    </div>
                    <div class="td">
                      <span class="">隐蔽工程验收</span>
                    </div>
                    <div class="td">
                      <span class=""> 高殿泽 </span>
                    </div>
                    <div class="td">
                      <span class=""> 是否合格 </span>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="tr">
                    <div class="td">
                      <span class="">1</span>
                    </div>
                    <div class="td">
                      <span class="">隐蔽工程验收</span>
                    </div>
                    <div class="td">
                      <span class=""> 高殿泽 </span>
                    </div>
                    <div class="td">
                      <span class=""> 是否合格 </span>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="tr">
                    <div class="td">
                      <span class="">1</span>
                    </div>
                    <div class="td">
                      <span class="">隐蔽工程验收</span>
                    </div>
                    <div class="td">
                      <span class=""> 高殿泽 </span>
                    </div>
                    <div class="td">
                      <span class=""> 是否合格 </span>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Index from "./index.js";
export default Index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>