<template>
  <div class="trans datav-box">
    <div class="content-con">
      <div class="left-con">
        <div class="top-con dv-box-border">
          <div class="dv-board-title nptrem">
            <div class="title-con">
              <span class="title">外运概况</span>
            </div>
          </div>
          <div class="dash-con">
            <div class="item-con" v-for="item in dashInfo">
              <div class="ti-con">
                <p>{{ item.name }}</p>
              </div>
              <div class="va-con">
                <p>{{ item.value }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-con dv-box-border">
          <div class="dv-board-title nptrem">
            <div class="title-con">
              <span class="title">外运列表</span>
            </div>
          </div>
          <div class="list-con">
            <div class="search-con">
              <div class="item-con">
                <a-select placeholder="请选择运输单位">
                  <a-select-option value="Option1">
                    单位1
                  </a-select-option>
                  <a-select-option value="Option2">
                    单位2
                  </a-select-option>
                </a-select>
              </div>
              <div class="item-con">
                <a-select placeholder="请选择处置单位">
                  <a-select-option value="Option1">
                    单位1
                  </a-select-option>
                  <a-select-option value="Option2">
                    单位2
                  </a-select-option>
                </a-select>
              </div>
              <div class="item-con">
                <a-date-picker placeholder="出场开始日期" @change="onOutStartChange"/>
              </div>
              <div class="item-con">
                <a-date-picker placeholder="出场结束日期" @change="onOutEndChange"/>
              </div>
              <div class="item-con">
                <a-input placeholder="车牌号"/>
              </div>
              <div class="item-con">
                <a-button type="primary">
                  查询
                </a-button>
              </div>
            </div>
            <div class="l-con">
              <div class="table-con">
                <div class="title-con">
                  <div class="item-con">
                    <p>车牌号</p>
                  </div>
                  <div class="item-con">
                    <p>出场时间</p>
                  </div>
                  <div class="item-con">
                    <p>路线</p>
                  </div>
                </div>
                <div class="scr-con">
                  <swiper
                      v-if="carList && carList.length > 0"
                      :options="carSwiperOption"
                      :not-next-tick="notNextTickOfCarSwiper"
                      ref="carSwiper"
                      :key="carSwiperKey"
                  >
                    <swiper-slide v-for="(item, index) in carList" :key="item.id">
                      <div class="tr">
                        <div class="td">
                          <span class=""> {{item.carNumber}} </span>
                        </div>
                        <div class="td">
                          <span class=""> {{item.time}} </span>
                        </div>
                        <div class="td rule-name">
                          <div class="icons-con">
                            <div class="item-con">
                              <img :src="require('@/assets/images/trans/pin.png')" alt="">
                            </div>
                            <div class="item-con">
                              <img :src="require('@/assets/images/trans/distance.png')" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="middlef-con">
        <div class="top-con dv-box-border">
          <div class="dv-board-title nptrem">
            <div class="title-con">
              <span class="title">地图</span>
            </div>
          </div>
          <div id="mapc" class="c-con"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Index from "./index";

export default Index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>

