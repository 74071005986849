import BYMixins from '@/mixins/index/index.js';
import 'swiper/dist/css/swiper.css';
import overlayApi from '@/request/api/overlay.js';
import labourApi from '@/request/api/labour.js';

export default {
    name: 'Labour',
    mixins: [BYMixins],
    components: {},
    data() {
        return {
            notNextTickOfrealfSwiper: true,
            realfSwiperOption: {
                speed: 500,
                loop: true,
                autoplay: {disableOnInteraction: false, delay: 3000},
                direction: 'vertical',
                autoHeight: false,
                initialSlide: 0,
                slidesPerView: 5,
            },
            /** 出勤各情况数据 */
            mtopvaInfo: [
                {
                    name: '在册人数',
                    value: '-',
                    key: 'sumcount',
                    unit: '人',
                },
                {
                    name: '出勤人数',
                    value: '-',
                    key: 'cqcount',
                    unit: '人',
                },
                {
                    name: '总出勤率',
                    value: '-',
                    key: 'attendance',
                    unit: '%',
                },
            ],
            /** 实时动态 */
            reals: [],
            /** 工种在册分析数据 */
            anChartData: [],
            /** 劳务人员各项信息 */
            atcaselabInfo: {},
            /** 管理人员各项信息 */
            atcasemanInfo: {},
            /** 出勤率趋势劳务人员数据 */
            mselaChartData: [],
            /** 出勤率趋势管理人员数据 */
            msemanChartData: [],
            /** 出勤率总趋势数据 */
            mthrChartData: [],
            /** 劳务实名列表-劳务 */
            emLabList: [],
            /** 劳务实名列表-管理 */
            emManaList: [],
        };
    },
    mounted() {
        this.handleLoadData();
        this.startPollingData();
        // this.handleLoadDataTestCode();
    },
    methods: {
        /** 处理轮询的数据 */
        handlePollingData() {
            this.handleLoadData();
        },
        //testcode method start
        handleLoadDataTestCode() {
            let reals = [];
            for (let i = 0; i < 10; i++) {
                let item = {
                    FaceData: "https://img2.baidu.com/it/u=3457745480,1768344681&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=800",
                    PersonName: "张三",
                    addtime: "2022/8/2",
                    entry: "进入"
                }
                reals.push(item);
            }
            this.reals = reals;

            this.mtopvaInfo.forEach((element) => {
                element.value = 10;
            });

            let anChartData = [];
            for (let i = 0; i < 10; i++) {
                let item = {
                    count: 10,
                    name: `水泥工${i}`
                }
                anChartData.push(item);
            }
            this.anChartData = anChartData;
            this.loadAnchart();

            let mthrChartData = [];
            for (let i = 0; i < 20; i++) {
                let item = {
                    sumcount: this.$rnum(10, 60),
                    attcount: this.$rnum(30, 100),
                    create_date: "2022/08/03"
                }

                mthrChartData.push(item);
            }
            this.mthrChartData = mthrChartData;
            this.loadAttenTotalChart();

            let emManaList = [];
            for (let i = 0; i < 10; i++) {
                let item = {
                    company: `测试集团${i}`,
                    count: this.$rnum(10000, 60000),
                    kqcount: this.$rnum(2000, 30000),
                    per: this.$rnum(60, 99),
                }
                emManaList.push(item);
            }
            this.emManaList = emManaList;

            let emLabList = [];
            for (let i = 0; i < 10; i++) {
                let item = {
                    company: `测试集团${i}`,
                    count: this.$rnum(10000, 60000),
                    kqcount: this.$rnum(2000, 30000),
                    per: this.$rnum(60, 99),
                }
                emLabList.push(item);
            }
            this.emLabList = emLabList;

            let atcaselabInfo = {
                sumcount: this.$rnum(10000, 60000),
                cqcount: this.$rnum(2000, 30000),
                attendance: this.$rnum(60, 99)
            }
            this.atcaselabInfo = atcaselabInfo;

            let mselaChartData = [];
            for (let i = 0; i < 20; i++) {
                let item = {
                    sumcount: this.$rnum(10, 60),
                    attcount: this.$rnum(30, 100),
                    create_date: "2022/08/03"
                }
                mselaChartData.push(item);
            }
            this.mselaChartData = mselaChartData;
            this.loadAttendanceChart();

            let atcasemanInfo = {
                sumcount: this.$rnum(10000, 60000),
                cqcount: this.$rnum(2000, 30000),
                attendance: this.$rnum(60, 99)
            }
            this.atcasemanInfo = atcasemanInfo;

            let msemanChartData = [];
            for (let i = 0; i < 20; i++) {
                let item = {
                    sumcount: this.$rnum(10, 60),
                    attcount: this.$rnum(30, 100),
                    create_date: "2022/08/03"
                }
                msemanChartData.push(item);
            }
            this.msemanChartData = msemanChartData;
            this.loadAttManageChart();
        },
        //testcode method end
        handleLoadData() {
            /** 实时动态 */
            (async () => {
                let res = await overlayApi.getnewkaoqin();
                this.reals = res;
            })();
            /** 工种在册分析 */
            (async () => {
                let res = await labourApi.getworkertype();
                this.anChartData = res.list;
                this.loadAnchart();
            })();
            /** 出勤管理 */
            (async () => {
                let res = await overlayApi.getAttachment({
                    type: `all`,
                });
                let {
                    attachment,
                    allAttlist_lw,
                    allAttlist_gl,
                    allAttlist,
                    attachment_lw,
                    attachment_gl,
                } = res;
                this.mtopvaInfo.forEach((element) => {
                    let {key} = element;
                    element.value = attachment[key];
                });
                this.atcaselabInfo = attachment_lw;
                this.atcasemanInfo = attachment_gl;
                this.mselaChartData = allAttlist_lw;
                this.msemanChartData = allAttlist_gl;
                this.mthrChartData = allAttlist;
                this.loadAttendanceChart();
                this.loadAttManageChart();
                this.loadAttenTotalChart();
            })();

            /** 劳务实名列表 */

            (async () => {
                let res = await labourApi.getworkerenter();
                let {buildersworker, manageworker} = res;
                this.emLabList = buildersworker;
                this.emManaList = manageworker;
            })();

        },
        // 出勤率趋势图
        async loadAttendanceChart() {
            if (!this.mselaChartData) {
                return;
            }
            let times = this.mselaChartData.map((item) => {
                return item.create_date;
            });
            let sumcounts = this.mselaChartData.map((item) => {
                return item.sumcount;
            });
            let atcounts = this.mselaChartData.map((item) => {
                return item.attcount;
            });
            let option = {
                tooltip: {
                    trigger: 'axis',
                },
                grid: {
                    top: '8%',
                    left: '3%',
                    right: '4%',
                    bottom: '12%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: times,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0693D0',
                        },
                    },
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: '#0693D0',
                        },
                    },
                    axisLabel: {
                        color: '#0693D0',
                        fontSize: 12,
                    },
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#303030',
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0693D0',
                        },
                    },
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: '#0693D0',
                        },
                    },
                    axisLabel: {
                        color: '#0693D0',
                    },
                },
                series: [
                    {
                        type: 'line',
                        name: '出勤人数',
                        data: atcounts,
                        lineStyle: {
                            color: '#3C78EF',
                        },
                        itemStyle: {
                            color: '#3C78EF',
                        },
                    },
                    {
                        type: 'line',
                        name: '在册人数',
                        data: sumcounts,
                        lineStyle: {
                            color: '#15BBB6',
                        },
                        itemStyle: {
                            color: '#15BBB6',
                        },
                    },
                ],
            };
            await this.loadChart(`mselachart`, option);
        },
        // 出勤率管理人员图
        async loadAttManageChart() {
            if (!this.msemanChartData) {
                return;
            }
            let times = this.msemanChartData.map((item) => {
                return item.create_date;
            });
            let sumcounts = this.msemanChartData.map((item) => {
                return item.sumcount;
            });
            let atcounts = this.msemanChartData.map((item) => {
                return item.attcount;
            });
            let option = {
                tooltip: {
                    trigger: 'axis',
                },
                grid: {
                    top: '8%',
                    left: '3%',
                    right: '4%',
                    bottom: '12%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: times,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0693D0',
                        },
                    },
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: '#0693D0',
                        },
                    },
                    axisLabel: {
                        color: '#0693D0',
                        fontSize: 12,
                    },
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#303030',
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0693D0',
                        },
                    },
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: '#0693D0',
                        },
                    },
                    axisLabel: {
                        color: '#0693D0',
                    },
                },
                series: [
                    {
                        type: 'line',
                        name: '出勤人数',
                        data: atcounts,
                        lineStyle: {
                            color: '#3C78EF',
                        },
                        itemStyle: {
                            color: '#3C78EF',
                        },
                    },
                    {
                        type: 'line',
                        name: '在册人数',
                        data: sumcounts,
                        lineStyle: {
                            color: '#15BBB6',
                        },
                        itemStyle: {
                            color: '#15BBB6',
                        },
                    },
                ],
            };
            await this.loadChart(`msemanagechart`, option);
        },
        // 出勤率劳务人员图
        async loadAnchart() {
            if (!this.anChartData) {
                return;
            }
            let result = this.anChartData.map((item, index) => {
                return {
                    value: item.count,
                    name: item.name,
                };
            });
            let option = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c} ({d}%)',
                },
                color: [
                    '#01B4FF',
                    '#91cc75',
                    '#fac858',
                    '#ee6666',
                    '#73c0de',
                    '#3ba272',
                    '#fc8452',
                    '#9a60b4',
                    '#ea7ccc',
                ],
                series: [
                    {
                        type: 'pie',
                        radius: '38%',
                        center: ['50%', '50%'],
                        selectedMode: 'single',
                        data: result,
                    },
                ],
            };
            await this.loadChart(`anchart`, option);
        },
        //总出勤率
        async loadAttenTotalChart() {
            if (!this.mthrChartData) {
                return;
            }
            let times = this.mthrChartData.map((item) => {
                return item.create_date;
            });
            let sumcounts = this.mthrChartData.map((item) => {
                return item.sumcount;
            });
            let atcounts = this.mthrChartData.map((item) => {
                return item.attcount;
            });
            let option = {
                tooltip: {
                    trigger: 'axis',
                },
                legend: {
                    show: true,
                    textStyle: {
                        color: '#fff',
                    },
                },
                grid: {
                    top: '18%',
                    left: '8%',
                    right: '9%',
                    bottom: '10%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: times,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0693D0',
                        },
                    },
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: '#0693D0',
                        },
                    },
                    axisLabel: {
                        color: '#0693D0',
                        fontSize: 12,
                    },
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#303030',
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0693D0',
                        },
                    },
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: '#0693D0',
                        },
                    },
                    axisLabel: {
                        color: '#0693D0',
                    },
                },
                series: [
                    {
                        type: 'line',
                        name: '出勤',
                        data: atcounts,
                        lineStyle: {
                            color: '#3C78EF',
                        },
                        itemStyle: {
                            color: '#3C78EF',
                        },
                    },
                    {
                        type: 'line',
                        name: '在册',
                        data: sumcounts,
                        lineStyle: {
                            color: '#15BBB6',
                        },
                        itemStyle: {
                            color: '#15BBB6',
                        },
                    },
                ],
            };
            await this.loadChart(`mthrchart`, option);
        },
    },
};
