import Vue from "vue";
import VueRouter from "vue-router";
import { Toast } from "vant";
//导入
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from "@/store";
import storage from "@/storage";
import MutationType from "@/store/mutation-type.js";
import utils from "@/utils/utils.js";
Vue.use(VueRouter);

//解决编程式路由往同一地址跳转时会报错的情况 貌似不加这个有时候会引起页面卡住
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    // 主布局
    path: "/",
    redirect: { name: "Tile" },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index/index.vue"),
    meta: {
      title: "login",
      // keepAlive: false,
    },
  },
  {
    // preloading
    path: "/preloading",
    name: "Preloading",
    component: () => import("@/components/preloading/index.vue"),
    meta: {
      title: "preloading",
      // keepAlive: false,
    },
  },
  {
    // 主布局
    path: "/datav",
    name: "Datav",
    component: () => import("@/views/home/index/index.vue"),
    meta: {
      title: "项目概览",
      // keepAlive: false,
    },
    children: [
      {
        // 三维
        path: "/",
        name: "Tile",
        component: () => import("@/views/tile/index/index.vue"),
        meta: {
          title: "三维",
        },
      },
      {
        //  人员管理
        path: "/employ",
        name: "Employ",
        component: () => import("@/views/employ/index.vue"),
        meta: {
          title: "人员管理",
        },
      },
      {
        // 项目概览
        path: "/Overlay",
        name: "Overlay",
        component: () => import(`@/views/overlay/index/index.vue`),
        meta: {
          title: "项目概览",
          // keepAlive: false,
        },
      },
      {
        // 劳务实名
        path: "labour",
        name: "Labour",
        component: () => import("@/views/labour/index/index.vue"),
        meta: {
          title: "劳务实名",
          // keepAlive: false,
        },
      },
      {
        // 进度管理
        path: "progress",
        name: "Progress",
        component: () => import("@/views/progress/index/index.vue"),
        meta: {
          title: "进度管理",
          // keepAlive: false,
        },
      },
      {
        // 进度管理
        path: "devices",
        name: "Devices",
        component: () => import("@/views/devices/index/index.vue"),
        meta: {
          title: "设备管理",
          // keepAlive: false,
        },
      },
      {
        // 绿色施工
        path: "green",
        name: "Green",
        component: () => import("@/views/green/index/index.vue"),
        meta: {
          title: "绿色施工",
        },
      },
      {
        // 质量检查
        path: "quality",
        name: "Quality",
        component: () => import("@/views/quality/index/index.vue"),
        meta: {
          title: "质量检查",
        },
      },
      {
        // 安全检查
        path: "safely",
        name: "Safely",
        component: () => import("@/views/safely/index/index.vue"),
        meta: {
          title: "安全检查",
        },
      },
      {
        // 塔吊监控
        path: "towermo",
        name: "Towermo",
        component: () => import("@/views/towermo/index/index.vue"),
        meta: {
          title: "塔吊监控",
        },
      },
      {
        // 升降机
        path: "lift",
        name: "Lift",
        component: () => import("@/views/lift/index/index.vue"),
        meta: {
          title: "塔吊监控",
        },
      },
      {
        // 视频中心
        path: "video",
        name: "Video",
        component: () => import("@/views/video/index/index.vue"),
        meta: {
          title: "视频中心",
        },
      },
      {
        // 语音调度
        path: "vod",
        name: "Vod",
        component: () => import("@/views/vodispatch/index.vue"),
        meta: {
          title: "语音调度",
        },
      },
      {
        // 应急广播
        path: "voice",
        name: "Voice",
        component: () => import("@/views/voice/index.vue"),
        meta: {
          title: "应急广播",
        },
      },
      {
        // 高支模
        path: "highmold",
        name: "Highmold",
        component: () => import("@/views/highmold/index/index.vue"),
        meta: {
          title: "高支模",
        },
      },
      {
        // 基坑监控
        path: "towercr",
        name: "Towercr",
        component: () => import("@/views/towercr/index/index.vue"),
        meta: {
          title: "基坑监控",
        },
      },
      {
        // 基坑监控
        path: "trans",
        name: "Trans",
        component: () => import("@/views/trans/index/index.vue"),
        meta: {
          title: "智慧运输",
        },
      },
      {
        // 正在开发
        path: "wait",
        name: "Wait",
        component: () => import("@/views/wait/index/index.vue"),
        meta: {
          title: "正在开发",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  // base: "/bagcloud/",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  Toast.clear();
  NProgress.start();
  store.commit(MutationType.CLEAR_REQ_TOKEN); // 取消请求
  /** 从地址栏中取出token 如果有就以它作为整个程序token的值 */
  let { token } = utils.getUrlQuery();
  if (token) {
    store.commit(MutationType.SET_TOKEN_MUTATION, token);
    storage.setToken(token);
  } else {
    /** 对 storage中的信息进行提取到内存 */
    let token = store.getters.token;
    if (!token) {
      token = storage.getToken();
      if (token) {
        store.commit(MutationType.SET_TOKEN_MUTATION, token);
      }
    }
  }
  let userName = store.getters.userName;
  if (!userName) {
    userName = storage.getUserName();
    if (userName) {
      store.commit(MutationType.SET_USERNAME_MUTATION, userName);
    }
  }
  let pwd = store.getters.pwd;
  if (!pwd) {
    pwd = storage.getPwd();
    if (pwd) {
      store.commit(MutationType.SET_PWD_MUTATION, pwd);
    }
  }
  console.log(to);
  let { name } = to;
  if (name != "Tile") {
    store.commit("setShowBtabs", false);
  } else {
    store.commit("setShowBtabs", true);
  }
  next();
});
router.afterEach((to, from) => {
  NProgress.done();
});
export default router;
