import utils from "@/utils/utils";
import NavBar from "@/components/nav/index/index.vue";
import BottomTabs from "@/components/bottom-tabs/index.vue";
import menuApi from "@/request/api/menu.js";
import MutationType from "@/store/mutation-type";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  mixins: [],
  components: {
    NavBar,
    BottomTabs,
  },
  data() {
    return {
      meunlist: [],
      btabsList: [],
    };
  },
  watch: {},
  filters: {},
  created() {},
  computed: {
    ...mapGetters(["showBtabs"]),
  },
  mounted() {
    // this.loadMenu();
    //test code start
    this.meunlist = [
      {
        name: "首页",
        id: 8,
        router: {
          name: "Tile",
        },
      },
      {
        name: "人员管理",
        id: 1,
        router: {
          name: "Employ",
        },
      },
      {
        name: "视频监控",
        id: 2,
        router: {
          name: "Video",
        },
      },
      {
        name: "设备管理",
        id: 3,
        router: {
          name: "Devices",
        },
      },
      {
        name: "语音调度",
        id: 4,
        router: {
          name: "Vod",
        },
      },
      {
        name: "应急广播",
        id: 7,
        router: {
          name: "Voice",
        },
      },
      {
        name: "进度管理",
        id: 5,
        router: {
          name: "Wait",
        },
      },
    ];
    this.btabsList = [
      {
        name: "视频监控",
        id: 2,
        router: {
          name: "",
        },
        icon: "security-camera.png",
      },
      {
        name: "人员管理",
        id: 1,
        router: {
          name: "",
        },
        icon: "human-resources.png",
      },
      {
        name: "车辆定位",
        id: 3,
        router: {
          name: "",
        },
        icon: "tools.png",
      },
      {
        name: "应急广播",
        id: 7,
        router: {
          name: "",
        },
        icon: "siren.png",
      },

      // {
      //   name: '考勤管理',
      //   id: 6,
      //   router: {
      //     name: '',
      //   },
      //   icon: 'daily-calendar.png',
      // },
      // {
      //   name: '语音调度',
      //   id: 4,
      //   router: {
      //     name: '',
      //   },
      //   icon: 'mic.png',
      // },

      // {
      //   name: '进度管理',
      //   id: 5,
      //   router: {
      //     name: '',
      //   },
      //   icon: 'calendar.png',
      // },
    ];
    //test code end
  },
  methods: {
    handleNavChange(item) {
      let { name } = item.router;
      console.log(item.router);
      this.$router.push({ name });
    },
    async loadMenu() {
      try {
        let res = await menuApi.getProjectMenu();
        this.meunlist = res;
      } catch (error) {}
    },
  },
};
