<template>
  <div class="bomenu-tree">
    <div class="list-con">
      <div
        class="item-con"
        :class="{ normal: !item.expand, expand: item.expand }"
        v-for="(item, index) in indata"
        :key="`bomlistitem-${index}`"
      >
        <div class="c-con" @click="handleClickItem(item, index)">
          <!-- <div
            :style="{ backgroundColor: item.active ? color : null }"
            class="indi-con"
          /> -->
          <div :class="['indi-con0', 'indi-con1'][item.channelStatus]" />
          <p :style="{ color: item.active ? color : null }" class="title">
            {{ item.name }}
          </p>
        </div>
        <transition name="bmentree_ani">
          <BoMenuTree
            v-if="item[childKey] && item.expand"
            :active-index="activeIndex.length > 1 ? activeIndex.slice(1) : []"
            @selectComplete="selectComplete"
            :style="{ paddingLeft: '20px' }"
            color="rgb(10, 144, 242)"
            :data="item[childKey]"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BoMenuTree",
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    color: {
      type: String,
      default: "#fff",
    },
    activeIndex: {
      type: Array,
      default: () => {
        return [];
      },
    },
    childKey: {
      type: String,
      default: "children",
    },
  },
  data() {
    return {
      data_copy: [],
      indata: [],
      sel_index: -1,
    };
  },
  mounted() {
    /* 如果需要深拷贝和下面这种需求的去掉注释代码即可
     * 深拷贝一下对象 不然会影响到上层展开的时候下层显示还是之前的状态
     *  另外发现拷贝代码貌似需要写在这里 测试了 写在computed 和 外部传递进来
     * 都不好使
     */
    // this.indata = JSON.parse(JSON.stringify(this.data));
    this.indata = JSON.parse(JSON.stringify(this.data));
    this.data_copy = JSON.parse(JSON.stringify(this.data));
    if (this.activeIndex.length > 0) {
      try {
        let index = this.activeIndex[0];
        let item = this.indata[index];
        this.handleClickItem(item, index);
      } catch (error) {
        console.warn(`mounted active index list handle error`);
      }
    }
  },
  watch: {
    data(nv, ov) {
      this.indata = nv;
      this.data_copy = JSON.parse(JSON.stringify(nv));
    },
    activeIndex(nv, ov) {
      if (this.activeIndex.length > 0) {
        let index = this.activeIndex[0];
        let item = this.indata[index];
        this.handleClickItem(item, index);
      }
    },
  },
  methods: {
    //递归向上传递
    selectComplete(item) {
      this.$emit("selectComplete", item);
    },
    handleClickItem(item, index) {
      this.indata.forEach((element) => {
        this.$set(element, "active", false);
      });
      this.$set(item, "active", true);
      if (item.expand) {
        if (item[this.childKey] && item[this.childKey].length > 0) {
          this.$set(item, "expand", false);
          this.sel_index = index;
          return;
        }
      }
      this.indata.forEach((element) => {
        this.$set(element, "expand", false);
      });
      //判断点击的是否为之前item 如果是那么将之前children保留的状态
      //直接显示
      if (this.sel_index == index) {
        this.$set(item, "expand", true);
        this.sel_index = index;
        if (item[this.childKey] && item[this.childKey].length > 0) {
          return;
        }
        this.$emit("selectComplete", item);
        return;
      }
      //如果不是之前点击的item 那么重新生成一个重置状态的数据
      let re_item = JSON.parse(JSON.stringify(this.data_copy[index]));
      this.indata.splice(index, 1, re_item);
      this.$set(re_item, "expand", true);
      this.$set(re_item, "active", true);
      this.sel_index = index;
      if (re_item[this.childKey] && re_item[this.childKey].length > 0) {
        return;
      }
      this.$emit("selectComplete", re_item);
    },
  },
};
</script>


<style lang="scss" scoped>
$main-color: #1490f2;
.bomenu-tree {
  position: relative;
  box-sizing: border-box;
  .list-con {
    position: relative;
    .item-con {
      cursor: pointer;
      position: relative;
      transition: all 0.25s ease;
      .c-con {
        height: 36px;
        @include flrowjusali(flex-start);
        .indi-con,
        .indi-con0 {
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: #fff;
          margin-right: 5px;
        }
        .indi-con1 {
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: rgb(255, 93, 93);
          margin-right: 5px;
        }
        .title {
          font-size: 14px;
          color: #fff;
          font-weight: 200;
        }
      }
    }
  }
  // https://cdn.jsdelivr.net/gh/HotWordland/Picbed/technique/20211211185515.png
  .bmentree_ani-enter-active,
  .bmentree_ani-leave-active {
    transition: all 0.25s;
    max-height: 1000px;
  }
  .bmentree_ani-enter, .bmentree_ani-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(-3px);
    max-height: 0px;
  }
}
</style>
