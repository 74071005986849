<template>
  <div class="wait">
    <a-result status="warning" title="正在建设中 敬请期待."> </a-result>
  </div>
</template>

<script>
import { Result } from "ant-design-vue";
import "ant-design-vue/lib/result/style/index.css";

export default {
  name: "Waitdevelop",
  components: {
    "a-result": Result,
  },
};
</script>

<style lang="scss" scoped>
.wait {
  position: relative;
  width: 100%;
  ::v-deep .ant-result-title {
    color: #fff;
  }
}
</style>
