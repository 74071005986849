let videoComponent = null;
var oWebControl = null;
var accessToken = '';

/**
 * video 初始化及配置
 * @param {*video挂载的dom id} id
 * @param {*连接成功的回调} cbConnectSuccess
 * @param {*连接报错的回调} cbConnectError
 * @param {*连接关闭的回调} cbConnectClose
 */
function WebControlInit(id, cbConnectSuccess, cbConnectError, cbConnectClose) {
  return new WebControl({
    szPluginContainer: id,
    iServicePortStart: 14510, // 对应 LocalServiceConfig.xml 中的ServicePortStart值
    iServicePortEnd: 14519, // 对应 LocalServiceConfig.xml 中的ServicePortEnd值
    cbConnectSuccess: cbConnectSuccess,
    cbConnectError: cbConnectError,
    cbConnectClose: cbConnectClose,
  });
}

/**
 * 离开页面时调用 断开链接的方法
 * @param {*视频插件实例} oWebControl
 */
function WebControlDistory() {
  return new Promise((resolve, reject) => {
    if (oWebControl == null) {
      reject();
      return;
    }
    let count = 0;
    let doneHandle = function () {
      if (count == 2) {
        resolve();
      }
    };
    oWebControl.JS_DestroyWnd().then(
      function () {
        console.log('JS_DestroyWnd');
        count += 1;
        doneHandle();
      },
      function (e) {
        reject(e);
      }
    );
    oWebControl.JS_StopService('window').then(function () {
      oWebControl.JS_Disconnect().then(
        function () {
          console.log('JS_Disconnect');
          count += 1;
          doneHandle();
        },
        function (e) {
          reject(e);
        }
      );
    });
  });
}

// 监听视频控件的事件
function cbIntegrationCallBack(oData) {
  videoComponent.showCBInfo(oData);
  if (oData.responseMsg.eventName === 'FireTransFunction') {
    // 如果视频控件需要请求服务端数据，这里可以处理透传逻辑
  }
}

function getDomVideoContainerSize() {
  let width = document.getElementById('playWnd').clientWidth;
  let height = document.getElementById('playWnd').clientHeight;
  return {
    width,
    height,
  };
}

function cbConnectSuccess(responseItems) {
  // 设置窗口控制回调
  oWebControl.JS_SetWindowControlCallback({
    cbIntegrationCallBack: cbIntegrationCallBack,
  });
  //创建视频窗口
  oWebControl
    .JS_StartService('window', {
      dllPath: './chain/cloudTransform.dll',
    })
    .then(function () {
      let size = getDomVideoContainerSize();
      let width = size.width;
      let height = size.height;
      oWebControl
        .JS_CreateWnd('playWnd', width, height)
        .then(function () {
          console.log('JS_CreateWnd success');
          initVideo(responseItems);
        })
        .catch(function (e) {
          console.log(e);
        });
    })
    .catch(function (e) {
      console.log(e);
    });
}

function cbConnectError() {
  console.log('cbConnectError');
  oWebControl = null;
  console.error('确认本地进程是否已安装并开启成功！');
}

function cbConnectClose(bNormalClose) {
  // 连接异常断开：bNormalClose = false
  // JS_Disconnect正常断开：bNormalClose = true
  console.log('cbConnectClose');
  oWebControl = null;
}

window.onscroll = function () {
  if (oWebControl != null) {
    let size = getDomVideoContainerSize();
    oWebControl.JS_Resize(size.width, size.height);
  }
};
window.onresize = function () {
  if (oWebControl != null) {
    let size = getDomVideoContainerSize();
    oWebControl.JS_Resize(size.width, size.height);
  }
};
var limitStart = parseInt(
  new Date('2021-03-21T01:01:00').getTime() / 1000
).toString();
var limitEnd = parseInt(
  new Date('2021-03-21T01:01:30').getTime() / 1000
).toString();

import { Icon, Select } from 'ant-design-vue';
import Hls from 'hls.js';
import videoApi from '@/request/api/video';
import MenuTree from '@/components/menu-tree';
import { result } from '@/views/video/index/videolist';

export default {
  name: 'Video',
  components: {
    'a-icon': Icon,
    'a-select': Select,
    'a-select-option': Select.Option,
    MenuTree,
  },
  data() {
    return {
      swiperOption: {
        initialSlide: 0,
        autoplay: false,
        loop: false,
        speed: 1000,
        observer: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      notNextTickOfSwiper: true,
      videoBoxId: 'sixVideo',
      collapsed: 'menu-unfold',
      videoSecs: [],
      videoitems: [],
      hlsinstances: {},
      visiblefuvi: false,
      vfullpRefname: 'vifullPlayer',
      vfulltitle: '',
      category: [],
      scenic_cate_searchname: '',
      scenic_cate_search_lock: false,
      menu_tree_acindex: [],
      l_menu_data: [],
      ol_menu_data: [],
      //
      appKey: '',
      ezvizToken: '',
      deviceSerial: 'G32212151',
      channelNo: '3',
      deviceClass: '0',
      platformId: '',
      startTime: '',
      endTime: '',
      layout: 25,
      initModel: 5,
      switchVideo: 5, // 0实时预览 1录像回放
      callbackMessage: '', //显示回调信息
      tips: '调用成功',
      successTip: false,
      tipsShow: false,
      filter_l_menu_data: [],
    };
  },
  watch: {
    scenic_cate_searchname(nv, oldValue) {
      if (!nv) {
        this.filter_l_menu_data = JSON.parse(JSON.stringify(this.ol_menu_data));
      }
      let re = [];
      let l_menu_data = JSON.parse(JSON.stringify(this.ol_menu_data));
      l_menu_data.forEach((element) => {
        let temp = element.node.filter((item) => {
          return item.name.indexOf(nv) > -1;
        });
        if (temp.length > 0) {
          element.node = temp;
          this.$set(element, 'expand', true);
          re.push(element);
        }
      });
      this.filter_l_menu_data = re;
    },
  },
  async mounted() {
    videoComponent = this;
    this.l_menu_data = result;
    this.filter_l_menu_data = result;
    this.ol_menu_data = JSON.parse(JSON.stringify(result));
    await this.loadlivelist();
    await this.loadHivideoAccount();
    window.initVideo = this.initVideo;
    try {
      if (this.l_menu_data.length > 0) {
        let {
          name: channelName,
          deviceSerial,
          channelNo,
          channelStatus: channelCode,
        } = this.l_menu_data[0].node[0];
        oWebControl = WebControlInit(
          'playWnd',
          function () {
            cbConnectSuccess({
              channelName,
              deviceSerial,
              channelNo,
              channelCode,
            });
          },
          cbConnectError,
          cbConnectClose
        );
      }
    } catch (e) {
      console.log(e);
    }
    // videoComponent.GetLayout();
  },
  beforeUnmount() {
    console.log(`live index beforeUnmount`);
    try {
      oWebControl.JS_HideWnd();
      WebControlDistory();
    } catch (error) {
      console.error(error);
    }
  },
  beforeDestroy() {
    try {
      oWebControl.JS_HideWnd();
      WebControlDistory();
    } catch (error) {
      console.error(error);
    }
    //销毁前销毁掉hls实例 不然会一直请求
    for (const key in this.hlsinstances) {
      if (Object.hasOwnProperty.call(this.hlsinstances, key)) {
        this.videoPause(key);
      }
    }
  },
  methods: {
    async loadlivelist() {
      try {
        let res = await videoApi.getlivelist();
        this.l_menu_data = res;
        this.filter_l_menu_data = res;
        this.ol_menu_data = JSON.parse(JSON.stringify(res));
      } catch (error) {}
    },
    async loadHivideoAccount() {
      try {
        let res = await videoApi.ezvizaccount();
        let { appKey, token: ezvizToken } = res;
        this.appKey = appKey;
        this.ezvizToken = ezvizToken;
      } catch (error) {}
    },
    async leftMenuSelComplete(item) {
      // console.log(`leftMenuSelComplete`);
      // console.log(item);
      // // 调用视频控件预览方法
      // try {
      //   let res = await oWebControl
      //     .JS_RequestInterface({
      //       funcName: 'stopAllPreview',
      //     });
      //   console.log(res);
      // } catch (e) {
      //   console.log(e);
      // }
      //
      // console.log(`switch video stopAllPreview ok`);
      let {
        name: channelName,
        deviceSerial,
        channelNo,
        channelStatus: channelCode,
      } = item;
      await this.$nextTick();
      let responseItems = {
        channelName,
        deviceSerial,
        channelNo,
        channelCode,
      };
      //console.log(responseItems);
      let self = this;
      // 预览入参
      let argumentsPram = {
        response: {
          code: 0,
          message: null,
          data: {
            deviceSerial: self.deviceSerial,
            channelCode: self.channelCode,
            channelNo: +self.channelNo,
            codeIsEncrypt: 0,
            validateCode: '',
            deviceClass: self.deviceClass,
            deviceType: '10242',
            //deviceType:"10240",
            channelId: 'c55d06ffbded46baa4ca84fbc106de20',
            channelName: 'channelNameTest',
            storeName: '通道名称',
            storeId: 'storeid',
            ...responseItems,
            //startTime:'2021-03-21 01:00:00',
            //endTime:'2021-03-21 01:02:00',
            //Limited_start:limitStart,
            //Limited_end:limitEnd,
          },
        },
      };
      //
      // 调用视频控件预览方法
      oWebControl
        .JS_RequestInterface({
          funcName: 'StartPreview',
          arguments: encodeURI(JSON.stringify(argumentsPram)),
        })
        .then(function (oData) {
          // console.log('预览/log00', oData.responseMsg);
          // self.showCBInfo(oData.responseMsg);
          self.showTips(true, '预览/回放成功！');
        });
    },
    firstVideosPlay(item) {
      let {
        name: channelName,
        deviceSerial,
        channelNo,
        channelStatus: channelCode,
      } = item;
      let responseItems = {
        channelName,
        deviceSerial,
        channelNo,
        channelCode,
      };
      //console.log(responseItems);
      let self = this;
      // 预览入参
      let argumentsPram = {
        deviceSerial: self.deviceSerial,
        channelCode: self.channelCode,
        channelNo: +self.channelNo,
        codeIsEncrypt: 0,
        validateCode: '',
        deviceClass: self.deviceClass,
        deviceType: '10242',
        //deviceType:"10240",
        channelId: 'c55d06ffbded46baa4ca84fbc106de20',
        channelName: 'channelNameTest',
        storeName: '通道名称',
        storeId: 'storeid',
        ...responseItems,
        //startTime:'2021-03-21 01:00:00',
        //endTime:'2021-03-21 01:02:00',
        //Limited_start:limitStart,
        //Limited_end:limitEnd,
      };
      //
      return argumentsPram;
    },
    ChangeModel5() {
      if (oWebControl) {
        oWebControl.JS_RequestInterface({
          funcName: 'ChangeModel',
          arguments: encodeURI(
            JSON.stringify({
              model: 5,
            })
          ),
        });
        // .then(function (oData) {
        //   self.playVideo([]);
        // });
      }
    },
    handleSearchInputCompositionstart() {
      this.scenic_cate_search_lock = true;
    },
    handleSearchInputCompositionend(e) {
      this.scenic_cate_searchname = e.data;
      this.scenic_cate_search_lock = false;
    },
    //处理景区分类的搜索内容
    handleSearchInput(e) {
      if (!this.scenic_cate_search_lock) {
        this.scenic_cate_searchname = e.target.value;
        console.log(e.target.value);
      }
    },
    async handleClickCategory(item) {
      for (let i = 0; i < this.category.length; i++) {
        let item = this.category[i];
        item.active = false;
      }
      item.active = true;
      try {
        this.$loading.show();
        let res = await videoApi.getCameraInfo({
          title: item.value,
        });
        this.$loading.hide();
        res.forEach((element) => {
          element.showv = false;
          //测试
          // element.hLsUrl = 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8';
        });
        this.videoAllPause();
        this.videoitems = res;
        let pageNum = this.getCaVideoNum();
        this.loadViSecsWithPageNum(pageNum, res);
      } catch (e) {
        this.$loading.hide();
        console.warn(e);
      }
    },
    /**
     * 设置视频初始化参数
     */
    initVideo: function (responseItems) {
      let self = this;
      if (!self.appKey || !self.ezvizToken) {
        self.showTips(false, '请输入appkey和token');
        return;
      }
      // 初始化入参
      let argumentsPram = {
        layout: 25,
        userName: '',
        waterMark: '',
        intelligenceEnable: 1,
        isRecord: 1,
        isSetPos: 1,
        initModel: 5,
        motionDetectEnable: 0,
        playBackAlarmOverlyingEnable: 0,
        response: {
          code: 0,
          message: null,
          data: {
            appKey: self.appKey,
            ezvizToken: self.ezvizToken,
            videoLevel: 2,
            iWndType: 0,
            showMainTool: 1,
            showSubTool: 1,
            waterMark: '1',
            userName: '中国铁建',
            platformId: self.platformId,
          },
        },
      };
      // 调用视频控件初始化方法
      //self.ChangeModel5();
      oWebControl
        .JS_RequestInterface({
          funcName: 'Init',
          arguments: encodeURI(JSON.stringify(argumentsPram)),
        })
        .then(function (oData) {
          // self.showCBInfo(oData.responseMsg);
          // self.showTips(true, '视频初始化成功！');
          console.log('视频初始化成功');
          //self.GetLayout();
          setTimeout(function () {
            let alls = [];
            self.ol_menu_data.forEach((element) => {
              alls = alls.concat(element.node);
            });
            //过滤出在线的数据
            alls = alls.filter((item) => {
              return item.channelStatus == 1;
            });
            let first16 = alls.slice(0, Math.min(25, alls.length));
            let pitems = first16.map((item) => {
              return self.firstVideosPlay(item);
            });
            let argumentsPram = {
              FuncName: 'MultiPreview',
              response: {
                data: {
                  Cameras: pitems,
                },
              },
            };
            // 调用视频控件预览方法
            oWebControl
              .JS_RequestInterface({
                funcName: 'MultiPreview',
                arguments: encodeURI(JSON.stringify(argumentsPram)),
              })
              .then(function (oData) {
                // console.log('预览/log00', oData.responseMsg);
                // self.showCBInfo(oData.responseMsg);
                self.showTips(true, '预览/回放成功！');
              });
          }, 1500);
        });
    },

    /**
     * 播放门店视频
     */
    playVideo: function (responseItems) {
      let self = this;
      // 预览入参
      let argumentsPram = {
        response: {
          code: 0,
          message: null,
          data: {
            deviceSerial: self.deviceSerial,
            channelCode: self.channelCode,
            channelNo: +self.channelNo,
            codeIsEncrypt: 0,
            validateCode: '',
            deviceClass: self.deviceClass,
            deviceType: '10242',
            //deviceType:"10240",
            channelId: 'c55d06ffbded46baa4ca84fbc106de20',
            channelName: 'channelNameTest',
            storeName: '通道名称',
            storeId: 'storeid',
            ...responseItems,
            //startTime:'2021-03-21 01:00:00',
            //endTime:'2021-03-21 01:02:00',
            //Limited_start:limitStart,
            //Limited_end:limitEnd,
          },
        },
      };
      // 调用视频控件预览方法
      oWebControl
        .JS_RequestInterface({
          funcName: 'StartPreview',
          arguments: encodeURI(JSON.stringify(argumentsPram)),
        })
        .then(function (oData) {
          console.log('预览/log111', oData.responseMsg);
          self.showCBInfo(oData.responseMsg);
          self.showTips(true, '预览/回放成功！');
        });
      this.ChangeModel5();
    },
    /**
     * 设置事件回调信息
     */
    showCBInfo: function (message) {
      //console.log('showcbinfo message is ', message);
      this.callbackMessage =
        this.callbackMessage + JSON.stringify(message) + '\n\n';
    },
    showTips: function (status, message) {
      let self = this;
      this.successTip = status;
      this.tips = message;
      this.tipsShow = true;
      setTimeout(function () {
        self.tipsShow = false;
      }, 1000);
    },
    /**
     * 隐藏视频
     */
    hideVideo: function () {
      oWebControl.JS_HideWnd();
    },
    /**
     * 显示视频
     */
    showVideo: function () {
      oWebControl.JS_ShowWnd();
    },
    /**
     * 获取窗口数
     */
    GetLayout: function () {
      let data = null;
      let self = this;

      oWebControl
        .JS_RequestInterface({
          funcName: 'GetLayout',
          arguments: encodeURI(
            JSON.stringify({
              response: {
                code: 0,
                message: null,
                data: data,
              },
            })
          ),
        })
        .then(function (oData) {
          //console.log(JSON.stringify(oData.responseMsg));
          //self.showCBInfo(oData.responseMsg);
          console.log(123);
          console.log(JSON.stringify(oData));
        });
    },
    /**
     * 抓图
     */
    CaptureJPGEx: function () {
      let data = null;
      let self = this;

      oWebControl
        .JS_RequestInterface({
          funcName: 'CaptureJPGEx',
          arguments: encodeURI(
            JSON.stringify({
              response: {
                code: 0,
                message: null,
                data: data,
              },
            })
          ),
        })
        .then(function (oData) {
          console.log(JSON.stringify(oData.responseMsg));
          self.showCBInfo(oData.responseMsg);
        });
    },
    /** 加载视频sec数据 */
    loadViSecsWithPageNum(pageNum, res) {
      // let secs = Math.ceil(res.length / pageNum);
      let temp = [];
      for (var i = 0, len = res.length; i < len; i += pageNum) {
        temp.push(res.slice(i, i + pageNum));
      }
      this.videoSecs = temp;
    },
    /** 获取slide一页分配的视频个数 */
    getCaVideoNum() {
      switch (this.videoBoxId) {
        case 'sixVideo':
          return 6;
        case 'tweVideo':
          return 12;
        default:
          return 1;
      }
    },
    /** 重置所有的video播放 */
    videoAllPause() {
      for (const key in this.hlsinstances) {
        if (Object.hasOwnProperty.call(this.hlsinstances, key)) {
          let strs = key.split('-');
          if (strs[0] == `videoitem`) {
            let sec = strs[1];
            let row = strs[2];
            this.videoSecs[sec][row].showv = false;
          }
          this.videoPause(key);
        }
      }
    },
    handleClickViIconPlay(item, refname) {
      this.videoAllPause();
      let url = item.hLsUrl;
      let videoSrc = url;
      item.showv = true;
      this.$nextTick(() => {
        let video = this.$refs[refname][0];
        let hls = new Hls();
        hls.loadSource(videoSrc);
        hls.attachMedia(video);
        //监听事件
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          video.play().catch((err) => {
            console.warn(err);
          });
        });
        hls.on(Hls.Events.ERROR, function (event, data) {});
        this.hlsinstances[refname] = hls;
      });
    },
    handleFullViOk() {
      this.videoPause(this.vfullpRefname);
    },
    handleFullViCancel() {
      this.videoPause(this.vfullpRefname);
      WebControlDistory();
    },
    videoPause(refname) {
      let hls = this.hlsinstances[refname];
      if (hls) {
        let video;
        if (this.$refs[refname].constructor == Array) {
          video = this.$refs[refname][0];
        } else {
          video = this.$refs[refname];
        }
        video.pause();
        hls.destroy();
        delete this.hlsinstances[refname];
      }
    },
    handleVideoRouteChange(value) {
      this.videoAllPause();
      this.videoBoxId = value;
      let pageNum = this.getCaVideoNum();
      this.loadViSecsWithPageNum(pageNum, this.videoitems);
    },
    handleClickListVideo(item, fromRefname) {
      item.showv = false;
      this.videoPause(fromRefname);
    },
    showVfullPlayer(item, fromRefname) {
      let vfullpRefname = this.vfullpRefname;
      item.showv = false;
      this.videoPause(fromRefname);
      this.visiblefuvi = true;
      let { hLsUrl, deviceName, channelNo, device_serial, status } = item;
      let url = hLsUrl;
      let name = `【${status}】${deviceName}`;
      this.vfulltitle = name;
      this.$nextTick(() => {
        let videoSrc = url;
        //
        oWebControl = WebControlInit(
          'playWnd',
          function () {
            cbConnectSuccess({
              channelName: deviceName,
              channelId: device_serial,
              channelNo: +channelNo,
              channelCode: channelCode,
            });
          },
          cbConnectError,
          cbConnectClose
        );
        window.initVideo = this.initVideo;
        // let video = this.$refs[vfullpRefname];
        // let hls = new Hls();
        // hls.loadSource(videoSrc);
        // hls.attachMedia(video);
        // //监听事件
        // hls.on(Hls.Events.MANIFEST_PARSED, function(event, data) {
        //   video.play().catch((err) => {
        //     console.warn(err);
        //   });
        // });
        // hls.on(Hls.Events.ERROR, function(event, data) {});
        // this.hlsinstances[vfullpRefname] = hls;
      });
    },
    toggle: function () {
      this.collapsed = !this.collapsed;
    },
  },
};
