<template>
  <div class="nav-con">
    <div class="nav-info">
      <div class="nright-con">
        <div class="rightmenu-con">
          <div
            v-for="item in rightList"
            :key="item.id"
            :class="item.active ? `item-con active` : `item-con`"
            :style="{ pointerEvents: item.fillempty ? `none` : `unset` }"
            @click="handleNavitem(item)"
          >
            <div class="icon">
              <div
                class="icon"
                :style="{
                  background: `url(${require(`@/assets/images/home/${item.icon}`)})no-repeat center`,
                }"
              />
            </div>
            <p class="title">{{ item.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <!--suppress SpellCheckingInspection -->
  <script>
import { mapGetters } from "vuex";
import MutationType from "@/store/mutation-type.js";

export default {
  name: "BottomTabs",
  props: {
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  watch: {
    list(newValue, oldValue) {
      console.log(`list watched`);
      this.convertList(newValue);
    },
  },
  data() {
    return {
      leftList: [],
      rightList: [],
      quitPopShow: false,
    };
  },
  computed: {
    ...mapGetters(["systitle"]),
  },
  mounted() {
    this.convertList(this.list);
  },
  methods: {
    handleQuitCancel() {
      this.quitPopShow = false;
    },
    /** 确定退出 */
    handleQuitOk() {
      this.quitPopShow = false;
      this.$store.commit(MutationType.SET_TOKEN_MUTATION, null);
      this.$router.push({
        name: "Login",
      });
    },
    handleQuit() {
      this.quitPopShow = true;
    },
    /** 首字母大写s */
    titleCase(str) {
      let newStr = str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
      return newStr;
    },
    convertList(list) {
      if (list.length == 0) {
        return;
      }
      let rightlist = JSON.parse(JSON.stringify(list));
      rightlist.forEach((element) => {
        element.router.name = this.titleCase(element.router.name);
        element.active = false;
      });
      this.rightList = rightlist;
      return;
      this.$toast.fail("菜单栏配置有误,请联系管理员");
    },
    handleFullscreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        return;
      }
      let element = document.getElementsByTagName("body")[0];
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else {
        element.msRequestFullscreen();
      }
    },
    handleNavitem(item) {
      let { leftList, rightList } = this;
      this.resetListAllItemActive(leftList);
      this.resetListAllItemActive(rightList);
      item.active = true;
      this.$emit("change", item);
    },
    resetListAllItemActive(list) {
      for (let index = 0; index < list.length; index++) {
        let element = list[index];
        element.active = false;
      }
    },
  },
};
</script>

  <style lang="scss" scoped>
.nav-con {
  position: relative;

  .nav-info {
    position: relative;
    // background: url(~@/assets/images/main/nav-bg.png);
    // background-size: cover;
    background-color: #001635;
    height: 70px;
    @include flrowjusali(center, center);
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;
    .cicon {
      width: 60px;
      margin-right: 20px;
    }

    .nleft-con {
      position: relative;
      margin-left: 75px;
      box-sizing: border-box;

      .leftmenu-con {
        position: relative;
        @include flrowjusali(flex-start, flex-start);

        .item-con {
          cursor: pointer;
          width: 110.5px;
          height: 70px;
          margin-top: 2px;
          color: #308eda;
          line-height: 65px;
          text-align: center;
          font-size: 18px;
          @include flrowjusali(center, flex-start);

          .title {
            font-size: 18px;
          }

          &.active {
            background: url(~@/assets/images/main/nav-item-active.png) 0 0
              no-repeat;
            background-size: 100% 100%;
            color: #01c0d2;
          }
        }
      }
    }

    .nright-con {
      position: relative;
      margin-right: 75px;
      box-sizing: border-box;
      .rightmenu-con {
        position: relative;
        @include flrowjusali(flex-start, flex-start);

        .item-con {
          cursor: pointer;
          width: 100px;
          height: 70px;
          margin-top: 2px;
          color: #fff;
          text-align: center;
          font-size: 18px;
          @include flcoljusali(center);

          .icon {
            margin-top: 5px;
            margin-bottom: 5px;
            width: 64px;
            height: 32px;
            overflow: hidden;
            .icon {
              position: relative;
              left: 18px;
              border-right: 32px solid transparent; /*必不可少*/
              -webkit-filter: drop-shadow(64px 0 0 #308eda);
              filter: drop-shadow(64px 0 0 #308eda);

              // background-size: 100% 100%;
            }
          }
          .title {
            font-size: 14px;
            font-weight: 100;
          }

          &.active {
            color: #308eda;
            .icon {
              .icon {
                position: relative;
                left: -46px;
                border-right: 32px solid transparent; /*必不可少*/
                -webkit-filter: drop-shadow(64px 0 0 #308eda);
                filter: drop-shadow(64px 0 0 #308eda);
                // background-size: 100% 100%;
              }
            }
          }
        }
      }
    }

    .ncenter-con {
      position: relative;
      background: linear-gradient(0deg, #308eda 0.14648438%, #c4e3fa);
      font-size: 32px;
      line-height: 32px;
      height: 32px;
      font-weight: 800;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .fsicon {
      width: 25px;
      height: 25px;
      position: absolute;
      right: 20px;
      bottom: 10px;
      cursor: pointer;
    }
  }

  .exit-con {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px 20px;

    .icon {
      width: 16px;
    }
  }
}
</style>
