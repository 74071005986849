var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sincard-con"},[(_vm.showTitle)?_c('div',{staticClass:"tbar-con",style:({
      background:
        _vm.tibgType == 0
          ? `url(${require('@/assets/images/home/bt01.gif')})`
          : `url(${require('@/assets/images/home/bt02.gif')})`,
      backgroundSize: 'cover',
    })},[_c('p',{staticClass:"ti"},[_vm._v(_vm._s(_vm.title))])]):_vm._e(),_c('div',{staticClass:"box"},[_c('div',{staticClass:"boxItem"}),_c('div',{class:{ boxInner: true, h100per: true }},[_vm._t("default"),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEmpty),expression:"showEmpty"}],staticClass:"empty-con"},[_c('p',[_vm._v("暂无数据")])])],2)]),_vm._t("end")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }