import http from '../http.js';

const api = {
  /** 环境饼图 */
  gethuanjingcharts: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/huanjing/gethuanjingcharts`,
      data: params,
    });
  },
  //安全教育列表
  getSafaList: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'post',
      url: `/datav/cg/getSafaList`,
      data: params,
    });
  },
  //安全教育详情
  getSafaContent: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/cg/getSafaContent`,
      data: params,
    });
  },

};

export default api;
