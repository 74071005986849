<template>
  <div class="safely datav-box">
    <div class="left-con">
      <div class="fi-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">检查人排行榜</span>
            <span class="title2">Checker leaderboard</span>
          </div>
        </div>
        <div class="cheperchart-con">
          <div id="cheperchart" class="cheperchart"></div>
        </div>
      </div>
      <div class="se-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">安全类型数量</span>
            <span class="title2">Security Type</span>
          </div>
        </div>
        <div class="seqchart-con">
          <div class="seqchart" id="seqchart"></div>
        </div>
      </div>
    </div>
    <div class="right-con">
      <div class="fi-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">质量检查</span>
            <span class="title2">Quality Inspection</span>
          </div>
        </div>
        <div class="tab-con">
          <div class="item-con active">今日检查</div>
          <div class="item-con">七日检查</div>
        </div>
        <div class="inplist-con">
          <div class="item-con" style="background: rgb(20, 181, 178)">
            <div class="title">已完成</div>
            <div class="content">3</div>
          </div>
          <div class="item-con" style="background: rgb(85, 112, 181)">
            <div class="title">待整改</div>
            <div class="content">3</div>
          </div>
          <div class="item-con" style="background: rgb(255, 149, 117)">
            <div class="title">待审核</div>
            <div class="content">3</div>
          </div>
        </div>
      </div>
      <div class="se-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">最新动态</span>
            <span class="title2">Latest News</span>
          </div>
        </div>
        <div class="saprchart-con">
          <div id="saprchart" class="saprchart"></div>
        </div>
      </div>
      <div class="five-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">最新动态</span>
            <span class="title2">Latest News</span>
          </div>
        </div>
        <div class="trend-con">
          <div class="table-con">
            <div class="title-con">
              <div class="item-con">
                <p>检查名称</p>
              </div>
              <div class="item-con">
                <p>检查类型</p>
              </div>
              <div class="item-con">
                <p>创建人</p>
              </div>
              <div class="item-con">
                <p>检查人</p>
              </div>
              <div class="item-con">
                <p>状态</p>
              </div>
              <div class="item-con">
                <p>创建时间</p>
              </div>
            </div>
            <div class="scr-con">
              <swiper
                :options="recSwiperOption"
                :not-next-tick="notNextTickOfRecSwiper"
                ref="recSwiper"
              >
                <swiper-slide
                  v-for="(item, index) in [
                    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12,
                  ]"
                  :key="index"
                >
                  <div class="tr">
                    <div class="td">
                      <span class="">施工机具</span>
                    </div>
                    <div class="td">
                      <span class="">安全</span>
                    </div>
                    <div class="td">
                      <span class="">高殿泽</span>
                    </div>
                    <div class="td">
                      <span class=""> 高殿泽 </span>
                    </div>
                    <div class="td">
                      <span class=""> 是否合格 </span>
                    </div>
                    <div class="td">
                      <span class=""> 07-27 09:30 </span>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Index from "./index.js";
export default Index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>