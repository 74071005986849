import http from '../http.js';
import constant from '@/config/constant.js';

const videoApi = {
  /** 视频列表 */
  getCameraInfo: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/video/getCameraInfo`,
      data: params,
    });
  },
  /** 视频分类列表 */
  getCameraClass: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/video/getCameraClass`,
      data: params,
    });
  },
  /** 获取认证 */
  ezvizaccount: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/live/ezvizaccount`,
      //url: `/hik/ezvizaccount`,
      data: params,
    });
  },
  /** 获取视频多个 */
  gethikcameraList: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/live/live`,
      data: params,
    });
  },
  /** 获取视频多个 */
  getlivelist: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/live/livelist`,
      data: params,
    });
  },
};

export default videoApi;
