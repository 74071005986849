// noinspection SpellCheckingInspection

import http from '../http.js';

const api = {
  /** 塔吊在线统计 */
  getonline: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/tadiao/getonline`,
      data: params,
    });
  },
  /** 塔吊实时统计 */
  getrealtime: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/tadiao/getrealtime`,
      data: params,
    });
  },
  /** 塔吊报警 */
  getwarning: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/tadiao/getwarning`,
      data: params,
    });
  },
  /** 报警统计 */
  getwarningtotal: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/tadiao/getwarningtotal`,
      data: params,
    });
  },
  /** 报警统计按天 */
  getwarningday: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/tadiao/getwarningday`,
      data: params,
    });
  },
};

export default api;
