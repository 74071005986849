<template>
  <div class="overlay datav-box">
    <div class="content-con">
      <div class="left-con">
        <div class="top-con dv-box-border">
          <div class="dv-board-title nptrem">
            <div class="title-con">
              <span class="title">项目概况</span>
            </div>
          </div>
          <div class="project-con">
            <div class="projcontent-con nptrem">
              <div class="item-con" v-for="item in projectInfo">
                <p class="title">{{ item.name }}：</p>
                <p class="des" v-plhovalue="item.value"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-con dv-box-border">
          <div class="dv-board-title nptrem">
            <div class="title-con">
              <span class="title">安全教育</span>
            </div>
          </div>
          <div class="anl-con">
            <div class="item-con">
              <div class="ti-con">
                <a-icon type="profile" :style="{color:'#fff'}"/>
                <p class="ti">教育培训</p>
              </div>
              <p class="counti">{{ safeInfo.jypx }}<span>次</span></p>
            </div>
            <div class="item-con">
              <div class="ti-con">
                <a-icon type="profile" :style="{color:'#fff'}"/>
                <p class="ti">培训人次</p>
              </div>
              <p class="counti">{{ safeInfo.pxrc }}<span>人</span></p>
            </div>

          </div>
          <div class="preview-con">
            <swiper
                v-if="safes && safes.length > 0"
                :options="safeswiperOption"
                :not-next-tick="safenotNextTick"
                ref="mySwiper"
            >
              <swiper-slide v-for="(item, index) in safes" :key="index">
                <img class="thumb" :src="item.img" alt=""/>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
      <div class="middlef-con">
        <div class="top-con">
          <swiper
              v-if="projectImages && projectImages.length > 0"
              :options="swiperOption"
              :not-next-tick="notNextTick"
              ref="mySwiper"
          >
            <swiper-slide v-for="(item, index) in projectImages" :key="index">
              <img class="thumb" :src="item" alt=""/>
            </swiper-slide>
          </swiper>
        </div>
        <div class="bottom-con">
          <div class="pranl-con dv-box-border">
            <div class="dv-board-title nptrem">
              <div class="title-con">
                <span class="title">工程汇总</span>
              </div>
            </div>
            <div class="prbt-con">
              <div class="l-con">
                <p class="ti">总工程量:</p>
                <p class="va">{{ proAnl.allTotal }}<span>㎡</span></p>
                <p class="ti">已开挖量:</p>
                <p class="va">{{ proAnl.kwl }}<span>㎡</span></p>
                <div class="progress-con">
                  <a-progress
                      type="circle"
                      :width="170"
                      :stroke-color="{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }"
                      :percent="proAnl.ratio"
                      :strokeWidth="10"
                  >
                    <template #format="percent">
                      <div class="cirperti-con">
                        <p class="va">{{ percent }}%</p>
                        <p class="done">完成率</p>
                      </div>
                    </template>
                  </a-progress>
                </div>

              </div>
              <div class="r-con">
                <p>各污染土开挖工程量</p>
                <div class="list-con">
                  <div class="item-con" v-for="item in proAlAmount">
                    <p class="ti">{{ item.name }}</p>
                    <div class="prog-con">
                      <div class="b-con"/>
                      <div class="show-con" :style="{width:`${item.ratio}%`}"/>
                    </div>
                    <p class="ut">{{ item.kw }}㎡/{{ item.total }}㎡</p>
                    <p class="radios">{{ item.ratio }}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="env-con dv-box-border">
            <div class="dv-board-title nptrem">
              <div class="title-con">
                <span class="title">环境监测</span>
              </div>
            </div>
            <div class="list-con">
              <div class="item-con" v-for="item in qiData">
                <img class="icon" :src="require('@/assets/images/home/reuse-env.png')" alt="">
                <p class="ti">{{ item.bname }}</p>
                <p class="va">{{ item.bvalue }}{{ item.unit }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Index from "./index";

export default Index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
<style lang="scss">
.dvs-con {
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.dvsma-facestcon {
  @include flrowjusali();
}
</style>