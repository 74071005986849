import axios from 'axios';
import { apiDomains } from '@/config/index.js';
import vueapp from '@/main.js';
import store from '@/store';
import router from '@/router';
import MutationType from '@/store/mutation-type.js';
import storage from '@/storage';

axios.interceptors.request.use((config) => {
  config.cancelToken = new axios.CancelToken(function (cancel) {
    store.commit(MutationType.PUSH_REQ_TOKEN, { cancelToken: cancel });
  });
  return config;
});

const http = function (options) {
  let token = store.state.token;
  let projectcode = store.state.projectCode;
  let userName = store.state.userName;
  let pwd = store.state.pwd;
  let showErrToast = options.showErrToast !== false; // 是否显示错误提示
  let headers = options.headers ? options.headers : {}; // header
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (projectcode) {
    if (!options.pureparam) {
      //pureparam 代表纯参数 不进行参数扩展
      options.data.projectcode = projectcode;
    }
  }
  if (options.method === 'get') {
    options.params = options.data;
    if (projectcode) {
      if (!options.pureparam) {
        options.params.projectcode = projectcode;
      }
    }
  } else {
    if (options.query) {
      options.params = options.data;
    } else if (options.formData === true) {
      let formData = new FormData();
      for (let i in options.data) {
        formData.append(i, options.data[i]);
      }
      if (projectcode) {
        if (!options.pureparam) {
          formData.append(`projectcode`, projectcode);
        }
      }
      options.data = formData;
      headers['Content-Type'] = 'multipart/form-data';
    }
  }
  axios.defaults.baseURL = apiDomains[options.apiDomainIndex];
  axios.defaults.headers = headers;
  return new Promise((resolve, reject) => {
    axios(options)
      .then(async (res) => {
        const { code, data: result, msg: message } = res.data;
        if (code == 200) {
          if (options.resdata) {
            resolve(res.data);
          } else {
            resolve(result);
          }
        } else {
          if (code == 401) {
            //可以做重新登录
            //这里如果过期了先暂停当前业务的请求
            //重新获取token后再进行业务的请求
            if (token && userName && pwd) {
              //如果有token进行重新获取新的token
              let inloginSuccess = true;
              await innerlogin({
                username: userName,
                password: pwd,
              }).catch((err) => {
                inloginSuccess = false;
                vueapp.$message.error('重新获取token异常');
                console.log(`重新获取token异常 准备跳转login`);
                router.push({ name: 'Login' });
                reject(err);
                return;
              });
              if (inloginSuccess) {
                //获取成功后 进行原来的业务请求
                try {
                  let represult = await http(options);
                  resolve(represult);
                } catch (error) {
                  reject(error);
                }
              }
              return;
            }
            console.log(`准备跳转login`);
            router.push({ name: 'Login' });
          } else {
            vueapp.$global.loading.hide();
            vueapp.$message.error(message || '异常错误');
            reject(res);
          }
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          // 取消了请求后的操作
          console.log(`取消了请求`);
          reject(err);
        } else {
          if (err.response) {
            const { response } = err;
            if (showErrToast) {
              vueapp.$toast.fail('请检查您的网络');
            }
          } else {
            vueapp.$toast.fail('请检查您的网络');
          }
          console.log(err);
          reject(err.response);
        }
      });
  });
};
//内部登录
const innerlogin = (data) => {
  let { username, password } = data;
  if (!username || !password) {
    reject({
      code: 400,
      message: `账号密码不能为空`,
    });
    return;
  }
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `/api/login?username=${username}&password=${password}`,
      data,
    })
      .then((res) => {
        const { code, data: result } = res.data;
        if (code == 0) {
          let { access_token: token } = result;
          store.commit(MutationType.SET_TOKEN_MUTATION, token);
          storage.setToken(token);
          resolve(result);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          // 取消了请求后的操作
          reject(err);
          console.log(`取消了请求 innerlogin`);
        } else {
          reject(err);
        }
      });
  });
};

export default http;
