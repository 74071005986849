<template>
  <div class="sincard-con">
    <div
      v-if="showTitle"
      class="tbar-con"
      :style="{
        background:
          tibgType == 0
            ? `url(${require('@/assets/images/home/bt01.gif')})`
            : `url(${require('@/assets/images/home/bt02.gif')})`,
        backgroundSize: 'cover',
      }"
    >
      <p class="ti">{{ title }}</p>
    </div>
    <div class="box">
      <div class="boxItem" />
      <div :class="{ boxInner: true, h100per: true }">
        <slot />
        <div class="empty-con" v-show="showEmpty">
          <p>暂无数据</p>
        </div>
      </div>
    </div>
    <slot name="end" />
  </div>
</template>

<script>
export default {
  name: "Sincard",
  props: {
    title: {
      type: String,
      default: "-",
    },
    tibgType: {
      type: Number,
      default: 0,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showEmpty: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-con {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10px;
  @include flrowjusali();
  p {
    font-size: 20px;
    color: #daf2f9;
  }
}
.sincard-con {
  position: relative;
  width: 100%;
  .tbar-con {
    width: 100%;
    height: 35px;
    position: relative;
    // background: url(~@/assets/images/home/bt01.gif);
    // background-size: cover;
    margin-bottom: 15px;
    padding: 0 3px;
    box-sizing: border-box;
    .ti {
      font-size: 20px;
      color: #fff;
      line-height: 35px;
      font-weight: bold;
    }
  }
  @include flcoljusali(flex-start, flex-start);
}
.box {
  width: 100%;
  box-shadow: 0px 0px 12px 6px #1451c9 inset;
  color: #ffffff;
  position: relative;
  background-color: #021d49;
  flex: 1;
  height: 0;
}
/* 通过before after 两个伪类制作 上方的装饰*/
.box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-left: 2px solid #9bb9e5;
  border-top: 2px solid #9bb9e5;
  width: 15px;
  height: 10px;
}
.box::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  border-right: 3px solid #9bb9e5;
  border-top: 3px solid #9bb9e5;
  width: 15px;
  height: 10px;
}
.boxItem {
  position: absolute;
  bottom: 0px;
  width: 100%;
  /* border: 1px solid red; */
}
.boxItem::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  border-left: 3px solid #9bb9e5;
  border-bottom: 3px solid #9bb9e5;
  width: 15px;
  height: 10px;
}
.boxItem::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  border-right: 3px solid #9bb9e5;
  border-bottom: 3px solid #9bb9e5;
  width: 15px;
  height: 10px;
}
.boxInner {
  width: 100%;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
}
.h100per {
  height: 100%;
}
</style>
