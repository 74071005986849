// 公用常量
const constant = {
  // 接口地址
  API_DOMAIN: {
    // 接口地址
    0: {
      // DEV: 'http://zhgd.xunmaidianzi.com', // 开发接口地址
      DEV: '/api', // 开发接口地址

      TEST: 'https://bagcloud-app-test-manage.1000fun.com', // 测试接口地址
      PRODUCT: 'https://cqapi.xunmaidianzi.com', // 线上接口地址
    },
    // 大数据
    1: {
      DEV: 'https://travel-group-database-test-api-java.1000fun.com/', // 开发接口地址
      TEST: 'https://travel-group-database-test-api-java.1000fun.com/', // 测试接口地址
      PRODUCT: '', // 线上接口地址
    },
    // 视频统计接口
    2: {
      DEV: 'https://bagcloud-test-api.1000fun.com', // 开发接口地址
      TEST: 'https://bagcloud-api.cqlyy.com', // 测试接口地址
      PRODUCT: 'https://bagcloud-api.cqlyy.com', // 线上接口地址
    },
  },
};

export default constant;
