import Vue from 'vue';
import Vuex from 'vuex';
import MutationType from './mutation-type.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    systitle: '', //标题
    userId: null, // userId
    token: null, // 请求token
    imgHomeUrl: null, //图片请求的域名前缀地址
    aboutMe: '', //个人信息aboutme内容
    aboutMatch: '', //个人信息aboutmatch内容
    cachePage: [], //缓存的页面
    projectCode: null, //项目代码
    userName: null, //用户名
    pwd: null, //密码
    cancelReqTokenArr: [], // 取消请求token数组
    spinning: false,
    showBtabs: true,
  },
  getters: {
    userId: (state) => {
      return state.userId;
    },
    token: (state) => {
      return state.token;
    },
    imgHomeUrl: (state) => {
      return state.imgHomeUrl;
    },
    aboutMe: (state) => {
      return state.aboutMe;
    },
    aboutMatch: (state) => {
      return state.aboutMatch;
    },
    projectCode: (state) => {
      return state.projectCode;
    },
    userName: (state) => {
      return state.userName;
    },
    pwd: (state) => {
      return state.pwd;
    },
    systitle: (state) => {
      return state.systitle;
    },
    spinning: (state) => {
      return state.spinning;
    },
    showBtabs: (state) => {
      return state.showBtabs;
    },
  },
  mutations: {
    //设置userId
    setUserId(state, data) {
      state.userId = data;
    },
    //设置token
    [MutationType.SET_TOKEN_MUTATION](state, data) {
      state.token = data;
    },
    //设置 systitle
    [MutationType.SET_SYSTITLE](state, data) {
      state.systitle = data;
    },
    //设置username
    [MutationType.SET_USERNAME_MUTATION](state, data) {
      state.userName = data;
    },
    //设置pwd
    [MutationType.SET_PWD_MUTATION](state, data) {
      state.pwd = data;
    },
    [MutationType.SET_SPIN_MUTATION](state, data) {
      state.spinning = data;
    },
    //设置imgHomeUrl
    setImgHomeUrl(state, data) {
      state.imgHomeUrl = data;
    },
    //设置aboutme
    setAboutMe(state, data) {
      state.aboutMe = data;
    },
    //设置aboutmatch
    setAboutMatch(state, data) {
      state.aboutMatch = data;
    },
    //设置项目代码
    setProjectCode(state, data) {
      state.aboutMatch = data;
    },
    setShowBtabs(state, data) {
      state.showBtabs = data;
    },
    //添加取消请求token
    [MutationType.PUSH_REQ_TOKEN](state, payload) {
      state.cancelReqTokenArr.push(payload.cancelToken);
    },
    //取消请求
    [MutationType.CLEAR_REQ_TOKEN](state) {
      let { cancelReqTokenArr } = state;
      cancelReqTokenArr.forEach((item) => {
        item('router cancel request');
      });
      state.cancelReqTokenArr = [];
    },
  },
  actions: {},
  modules: {},
});
