import http from '../http.js';

const userApi = {
  /** 登录 */
  login: function (params = { username: '', password: '' }) {
    let { username, password } = params;
    return http({
      apiDomainIndex: 0,
      method: 'post',
      url: `/api/login?username=${username}&password=${password}`,
      data: params,
      // pureparam: true,
    });
  },
};

export default userApi;
