let tokenkey = "bbdtoken";
let usernamekey = "bbdusername";
let pwdkey = "bbdpwd";

export default {
  setToken: function(value) {
    localStorage.setItem(tokenkey, value);
  },
  getToken: function() {
    return localStorage.getItem(tokenkey);
  },
  setUserName: function(value) {
    localStorage.setItem(usernamekey, value);
  },
  getUserName: function() {
    return localStorage.getItem(usernamekey);
  },
  setPwd: function(value) {
    localStorage.setItem(pwdkey, value);
  },
  getPwd: function() {
    return localStorage.getItem(pwdkey);
  },
};
