import http from '../http.js';

const api = {
  /** 项目信息 */
  getProjectInfo: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/index/getProjectInfo`,
      data: params,
    });
  },
  /** 设备信息 */
  getProjectDeviceInfo: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/index/getProjectDeviceInfo`,
      data: params,
    });
  },
  /** 环境监控 */
  getProjectHcInfo: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/index/getProjectHcInfo`,
      data: params,
    });
  },
  getAllFacedevice: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/index/getAllFacedevice`,
      data: params,
    });
  },
  /** 设备日志 */
  getWarnCenter: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/index/getWarnCenter`,
      data: params,
    });
  },
  /** 环境质量趋势 */
  getHcDeviceInfo: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/index/getHcDeviceInfo`,
      data: params,
    });
  },
  /** 出勤管理 */
  getAttachment: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/index/getAttachment`,
      data: params,
    });
  },
  /** 考勤实时动态 */
  getnewkaoqin: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/index/getnewkaoqin`,
      data: params,
    });
  },
  /** 设备连接率 */
  getProjectZ: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/index/getProjectZ`,
      data: params,
    });
  },
  /** 项目图片 */
  getprojectimage: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/index/getprojectimage`,
      data: params,
    });
  },
  /** 实时搜索 - 动态 */
  getseachworkerenter: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'post',
      url: `/datav/worker/getseachworkerenter`,
      data: params,
    });
  },
  //cg
  getIndexProjectInfo: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/cg/getIndexProjectInfo`,
      data: params,
    });
  },
  getQuantitiesTotal: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'post',
      url: `/datav/cg/getQuantitiesTotal`,
      data: params,
    });
  },
  getProgress: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'post',
      url: `/datav/cg/getProgress`,
      data: params,
    });
  },
  //安全教育
  getIndexSafa: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'post',
      url: `/datav/cg/getIndexSafa`,
      data: params,
    });
  },


};

export default api;
