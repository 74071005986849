<template>
  <div class="progress datav-box">
    <div class="content-con">
      <div class="left-con">
        <div class="top-con dv-box-border">
          <div class="dv-board-title nptrem">
            <div class="title-con">
              <span class="title">工程量汇总</span>
            </div>
          </div>
          <div class="project-con">
            <div class="item-con">
              <p class="ti">总工程量:</p>
              <p class="va">{{proAnl.allTotal}}<span>㎡</span></p>
              <p class="ti">已开挖量:</p>
              <p class="va">{{proAnl.kwl}}<span>㎡</span></p>
              <div class="progress-con">
                <a-progress
                    type="circle"
                    :width="170"
                    :stroke-color="{
                  '0%': '#0D0DE6',
                  '100%': '#87F2FC',
                }"
                    :percent="proAnl.ratio"
                    :strokeWidth="10"
                >
                  <template #format="percent">
                    <div class="cirperti-con">
                      <p class="va">{{percent}}%</p>
                      <p class="done">完成率</p>
                    </div>
                  </template>
                </a-progress>
              </div>
            </div>
            <div class="item-con">
              <p class="ti">总基坑数量:</p>
              <p class="va">{{jkAnl.allTotal}}<span>个</span></p>
              <p class="ti">已开挖数量:</p>
              <p class="va">{{jkAnl.kwl}}<span>个</span></p>
              <div class="progress-con">
                <a-progress
                    type="circle"
                    :width="170"
                    :stroke-color="{
                  '0%': '#E2EC73',
                  '100%': '#A1FD62',
                }"
                    :percent="jkAnl.ratio"
                    :strokeWidth="10"
                >
                  <template #format="percent">
                    <div class="cirperti-con">
                      <p class="va">{{percent}}%</p>
                      <p class="done">完成率</p>
                    </div>
                  </template>
                </a-progress>
              </div>
            </div>
            <div class="item-con">
              <p class="ti">已开挖量:</p>
              <p class="va">{{kxAnl.allTotal}}<span>㎡</span></p>
              <p class="ti">已修复量:</p>
              <p class="va">{{kxAnl.xfl}}<span>㎡</span></p>
              <div class="progress-con">
                <a-progress
                    type="circle"
                    :width="170"
                    :stroke-color="{
                  '0%': '#E1B846',
                  '100%': '#57B6F9',
                }"
                    :percent="kxAnl.ratio"
                    :strokeWidth="10"
                >
                  <template #format="percent">
                    <div class="cirperti-con">
                      <p class="va">{{percent}}%</p>
                      <p class="done">完成率</p>
                    </div>
                  </template>
                </a-progress>
              </div>
            </div>

          </div>
        </div>
        <div class="bottom-con dv-box-border">
          <div class="dv-board-title nptrem">
            <div class="title-con">
              <span class="title">开挖工程量</span>
            </div>
          </div>
          <div class="list-con">
            <div class="item-con" v-for="item in proAlAmount">
              <p class="ti">{{item.name}}</p>
              <div class="prog-con">
                <div class="b-con"/>
                <div class="show-con" :style="{width:`${item.ratio}%`}"/>
              </div>
              <p class="ut">{{item.kw}}㎡/{{item.total}}㎡</p>
              <p class="radios">{{item.ratio}}%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="middlef-con">
        <div class="top-con dv-box-border">
          <div class="dv-board-title nptrem">
            <div class="title-con">
              <span class="title">累计修复,验收方量㎡</span>
            </div>
          </div>
          <div class="num-con">
            <div class="item-con">
              <div class="c-con">
                <p class="ti">累计修复方量㎡</p>
                <num-count :value="xfysAnl.xfTotal" :time="1"/>
              </div>
            </div>
            <div class="item-con">
              <div class="c-con">
                <p class="ti">累计验收方量㎡</p>
                <num-count :ot-style="{background:'#138153',border: '1px solid #41C291'}"
                           :commaut-style="{color:'#138153'}"
                           :value="xfysAnl.ysTotal" :time="1"/>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-con">
          <div class="listcs-con dv-box-border">
            <div class="dv-board-title nptrem">
              <div class="title-con">
                <span class="title">基坑修复状态</span>
              </div>
            </div>
            <div class="list-con">
              <div class="table-con">
                <div class="title-con">
                  <div class="item-con">
                    <p>编号</p>
                  </div>
                  <div class="item-con">
                    <p>状态</p>
                  </div>
                  <div class="item-con">
                    <p>总方量㎡</p>
                  </div>
                  <div class="item-con">
                    <p>已开坑方量㎡</p>
                  </div>
                  <div class="item-con">
                    <p>完成进度</p>
                  </div>
                </div>
                <div class="scr-con">
                  <swiper
                      v-if="jkxfAnl && jkxfAnl.length > 0"
                      :options="logSwiperOption"
                      :not-next-tick="notNextTickOfLogSwiper"
                      ref="logSwiper"
                      :key="logskey"
                  >
                    <swiper-slide v-for="(item, index) in jkxfAnl" :key="item.id">
                      <div class="tr">
                        <div class="td">{{ item.name }}</div>
                        <div class="td">
                          <a-tooltip>
                            <template slot="title">
                              <span> {{ item.status }} </span>
                            </template>
                            <span class=""> {{ item.status }} </span>
                          </a-tooltip>
                        </div>
                        <div class="td">
                          <span class=""> {{ item.total }} </span>
                        </div>
                        <div class="td rule-name">
                        <span class="">
                          {{ item.kw }}
                        </span>
                        </div>
                        <div class="td rule-name">
                        <span class="">
                          {{ item.ratio }}%
                        </span>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
          <div class="listcs-con dv-box-border">
            <div class="dv-board-title nptrem">
              <div class="title-con">
                <span class="title">各类污染土工艺</span>
              </div>
            </div>
            <div class="list-con">
              <div class="table-con">
                <div class="title-con">
                  <div class="item-con">
                    <p>工艺名称</p>
                  </div>
                  <div class="item-con">
                    <p>总方量㎡</p>
                  </div>
                  <div class="item-con">
                    <p>已开坑方量㎡</p>
                  </div>
                  <div class="item-con">
                    <p>完成进度</p>
                  </div>
                </div>
                <div class="scr-con">
                  <swiper
                      v-if="wrtgyAnl && wrtgyAnl.length > 0"
                      :options="tecSwiperOption"
                      :not-next-tick="notNextTickOfTecSwiper"
                      ref="tecSwiper"
                      :key="tecskey"
                  >
                    <swiper-slide v-for="(item, index) in wrtgyAnl" :key="item.id">
                      <div class="tr">
                        <div class="td">
                          <a-tooltip>
                            <template slot="title">
                              <span> {{ item.name }} </span>
                            </template>
                            <span class=""> {{ item.name }} </span>
                          </a-tooltip>
                        </div>
                        <div class="td">
                          <span class=""> {{ item.total }} </span>
                        </div>
                        <div class="td rule-name">
                        <span class="">
                          {{ item.kw }}
                        </span>
                        </div>
                        <div class="td rule-name">
                        <span class="">
                          {{ item.ratio }}
                        </span>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Index from "./index";

export default Index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
<style lang="scss">
.dvs-con {
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.dvsma-facestcon {
  @include flrowjusali();
}
</style>
