import http from '../http.js';

const api = {
  index: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/deviceManage/index`,
      data: params,
      resdata: true,
    });
  },
  getDeviceImages: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'post',
      url: `/datav/cg/getDeviceImages`,
      data: params,
    });
  },
  getRedioMap: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/deviceManage/getRedioMap`,
      data: params,
    });
  },
  getWorkerMap: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/deviceManage/getWorkerMap`,
      data: params,
    });
  },
  getCarMap: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/deviceManage/getCarMap`,
      data: params,
    });
  },
};

export default api;
