<template>
  <div class="main-con nptrem">
    <div class="left-con">
      <sincard :show-empty="!showBzry" title="班组人员">
        <div v-show="showBzry" class="list-con">
          <div class="ct-con" v-for="(item, index) in emLabList" :key="index">
            <div class="title-con">
              <p class="title">{{ item.teamName }}</p>
            </div>
            <div class="data-con">
              <p class="title">人数{{ item.num }}</p>
              <div class="dc-con">
                <div class="line-con" :style="{ width: `${item.per}%` }"></div>
                <p class="dcline-title">{{ item.per }}%</p>
              </div>
            </div>
          </div>
        </div>
      </sincard>
      <sincard title="工种在线分析">
        <div id="anchart" class="anchart" />
      </sincard>
    </div>
    <div class="center-con">
      <div class="bsw-con">
        <template v-for="(item, index) in mtopvaInfo">
          <sincard
            :title="item.name"
            :tibg-type="1"
            :key="`mtopsincard-${index}`"
          >
            <div class="item-con">
              <p class="value">{{ item.value }}</p>
            </div>
          </sincard>
          <div v-if="index != mtopvaInfo.length - 1" class="w30" />
        </template>
      </div>
      <sincard title="出勤率趋势" :tibg-type="1" class="cqlqz">
        <div class="man-con">
          <div class="item-con">
            <div class="tag-con">
              <p>劳务人员</p>
            </div>
            <div class="num-con">
              <div class="item-con">
                <p v-plhovalue="atcaselabInfo.sumcount"></p>
                <p>在册</p>
              </div>
              <div class="item-con">
                <p v-plhovalue="atcaselabInfo.cqcount"></p>
                <p>出勤</p>
              </div>
              <div class="rate-con">
                <p class="title">出勤率</p>
                <div class="inner-con">
                  <p><span v-plhovalue="atcaselabInfo.attendance"></span>%</p>
                </div>
              </div>
            </div>
            <div class="msechart-con">
              <div id="mselachart" class="chart"></div>
            </div>
          </div>
          <div class="item-con">
            <div class="tag-con">
              <p>管理人员</p>
            </div>
            <div class="num-con">
              <div class="item-con">
                <p v-plhovalue="atcasemanInfo.sumcount"></p>
                <p>在册</p>
              </div>
              <div class="item-con">
                <p v-plhovalue="atcasemanInfo.cqcount"></p>
                <p>出勤</p>
              </div>
              <div class="rate-con">
                <p class="title">出勤率</p>
                <div class="inner-con">
                  <p><span v-plhovalue="atcasemanInfo.attendance"></span>%</p>
                </div>
              </div>
            </div>
            <div class="msechart-con">
              <div id="msemanagechart" class="chart"></div>
            </div>
          </div>
        </div>
      </sincard>
      <sincard title="总出勤率趋势" :tibg-type="1" class="zcqlqz">
        <div class="chart" id="mthrchart"></div>
      </sincard>
    </div>
    <div class="right-con">
      <sincard title="考勤记录" class="kqjl">
        <div class="list-con">
          <div class="table-con">
            <div class="title-con">
              <div class="item-con">
                <p>人脸信息</p>
              </div>
              <div class="item-con">
                <p>姓名</p>
              </div>

              <div class="item-con">
                <p>时间</p>
              </div>
              <div class="item-con">
                <p>进出状态</p>
              </div>
            </div>
            <div class="scr-con">
              <swiper
                v-if="reals && reals.length > 0"
                :options="realfSwiperOption"
                :not-next-tick="notNextTickOfrealfSwiper"
                ref="realfSwiper"
                :key="logskey"
              >
                <swiper-slide v-for="(item, index) in reals" :key="index">
                  <div class="tr">
                    <div class="td">
                      <img class="avatar" :src="item.FaceData" alt="" />
                    </div>
                    <div class="td">
                      <span class="">{{ item.PersonName }}</span>
                    </div>

                    <div class="td">
                      <span class=""> {{ item.addtime }} </span>
                    </div>
                    <div class="td rule-name">
                      <span class=""> {{ item.entry }} </span>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </sincard>
    </div>
  </div>
</template>

<script>
import labour from "@/request/api/labour";
import BYMixins from "@/mixins/index/index.js";
import "swiper/dist/css/swiper.css";

export default {
  name: "Employ",
  mixins: [BYMixins],
  data() {
    return {
      emLabList: [],
      /** 工种在册分析数据 */
      anChartData: [],
      /** 出勤各情况数据 */
      mtopvaInfo: [
        {
          name: "总人数",
          value: "-",
          unit: "人",
        },
        {
          name: "管理人员",
          value: "-",
          unit: "人",
        },
        {
          name: "作业工人",
          value: "-",
          unit: "%",
        },
      ],
      atcaselabInfo: {},
      /** 管理人员各项信息 */
      atcasemanInfo: {},
      /** 出勤率趋势劳务人员数据 */
      mselaChartData: [],
      /** 出勤率趋势管理人员数据 */
      msemanChartData: [],
      /** 出勤率总趋势数据 */
      mthrChartData: [],
      /** 劳务实名列表-劳务 */
      emLabList: [],
      /** 劳务实名列表-管理 */
      emManaList: [],
      /** 实时动态 */
      reals: [],
      notNextTickOfrealfSwiper: true,
      logskey: 1001,
    };
  },
  computed: {
    realfSwiperOption() {
      let _this = this;
      let { reals } = this;
      let slidesPerView = 11; //这个会控制swiper-slide的高度 最好不要去强制修改css
      this.logskey += 1;
      return {
        speed: 500,
        loop: reals.length > slidesPerView,
        autoplay: { disableOnInteraction: false, delay: 3000 },
        direction: "vertical",
        autoHeight: false,
        initialSlide: 0,
        //这个属性为一页显示的数量 一定要准确对照页面上
        //能够显示的个数填写 不然会出现问题
        slidesPerView,
        on: {
          click: function () {
            // const realIndex = parseInt(this.clickedSlide.dataset.index);
            // let item = reals[realIndex];
            // _this.handleClickSafeItem(item);
          },
        },
      };
    },
    showBzry() {
      return this.emLabList.length > 0;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadAnchart() {
      if (!this.anChartData) {
        return;
      }
      let result = this.anChartData.map((item, index) => {
        return {
          value: item.count,
          name: item.name,
        };
      });
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        color: [
          "#01B4FF",
          "#91cc75",
          "#fac858",
          "#ee6666",
          "#73c0de",
          "#3ba272",
          "#fc8452",
          "#9a60b4",
          "#ea7ccc",
        ],
        series: [
          {
            type: "pie",
            radius: "42%",
            center: ["50%", "50%"],
            selectedMode: "single",
            data: result,
          },
        ],
      };
      await this.loadChart(`anchart`, option);
    },
    async loadData() {
      try {
        await Promise.all([
          this._loadEmdb(),
          this._loadAn(),
          this._loadAttach(),
          this._loadKaoqin(),
        ]);
      } catch (error) {
        console.log(error);
      }
    },
    async _loadEmdb() {
      try {
        let res = await labour.getworkerenter();
        this.emLabList = res;
      } catch (error) {
        console.log(error);
      }
    },
    async _loadAn() {
      try {
        let res = await labour.getworkertype();
        this.anChartData = res.list;
        this.loadAnchart();
      } catch (error) {
        console.log(error);
      }
    },
    async _loadAttach() {
      try {
        let res = await labour.getAttachment();
        let {
          attachment,
          allAttlist_lw,
          allAttlist_gl,
          allAttlist,
          attachment_lw,
          attachment_gl,
        } = res;
        this.mtopvaInfo[0].value = attachment.sumcount;
        this.mtopvaInfo[1].value = attachment_gl.sumcount;
        this.mtopvaInfo[2].value = attachment_lw.sumcount;
        this.atcaselabInfo = attachment_lw;
        this.atcasemanInfo = attachment_gl;
        this.mselaChartData = allAttlist_lw;
        this.msemanChartData = allAttlist_gl;
        this.mthrChartData = allAttlist;
        this.loadAttendanceChart();
        this.loadAttManageChart();
        this.loadAttenTotalChart();
      } catch (error) {
        console.log(error);
      }
    },
    async _loadKaoqin() {
      try {
        let res = await labour.getnewkaoqin();
        this.reals = res;
      } catch (error) {
        console.log(error);
      }
    },
    // 出勤率趋势图
    async loadAttendanceChart() {
      if (!this.mselaChartData) {
        return;
      }
      let times = this.mselaChartData.map((item) => {
        return item.create_date;
      });
      let sumcounts = this.mselaChartData.map((item) => {
        return item.sumcount;
      });
      let atcounts = this.mselaChartData.map((item) => {
        return item.attcount;
      });
      let option = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: "8%",
          left: "3%",
          right: "4%",
          bottom: "12%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: times,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#0693D0",
            },
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "#0693D0",
            },
          },
          axisLabel: {
            color: "#0693D0",
            fontSize: 12,
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#303030",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#0693D0",
            },
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "#0693D0",
            },
          },
          axisLabel: {
            color: "#0693D0",
          },
        },
        series: [
          {
            type: "line",
            name: "出勤人数",
            data: atcounts,
            lineStyle: {
              color: "#3C78EF",
            },
            itemStyle: {
              color: "#3C78EF",
            },
          },
          {
            type: "line",
            name: "在册人数",
            data: sumcounts,
            lineStyle: {
              color: "#15BBB6",
            },
            itemStyle: {
              color: "#15BBB6",
            },
          },
        ],
      };
      await this.loadChart(`mselachart`, option);
    },
    // 出勤率管理人员图
    async loadAttManageChart() {
      if (!this.msemanChartData) {
        return;
      }
      let times = this.msemanChartData.map((item) => {
        return item.create_date;
      });
      let sumcounts = this.msemanChartData.map((item) => {
        return item.sumcount;
      });
      let atcounts = this.msemanChartData.map((item) => {
        return item.attcount;
      });
      let option = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: "8%",
          left: "3%",
          right: "4%",
          bottom: "12%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: times,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#0693D0",
            },
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "#0693D0",
            },
          },
          axisLabel: {
            color: "#0693D0",
            fontSize: 12,
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#303030",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#0693D0",
            },
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "#0693D0",
            },
          },
          axisLabel: {
            color: "#0693D0",
          },
        },
        series: [
          {
            type: "line",
            name: "出勤人数",
            data: atcounts,
            lineStyle: {
              color: "#3C78EF",
            },
            itemStyle: {
              color: "#3C78EF",
            },
          },
          {
            type: "line",
            name: "在册人数",
            data: sumcounts,
            lineStyle: {
              color: "#15BBB6",
            },
            itemStyle: {
              color: "#15BBB6",
            },
          },
        ],
      };
      await this.loadChart(`msemanagechart`, option);
    },
    //总出勤率
    async loadAttenTotalChart() {
      if (!this.mthrChartData) {
        return;
      }
      let times = this.mthrChartData.map((item) => {
        return item.create_date;
      });
      let sumcounts = this.mthrChartData.map((item) => {
        return item.sumcount;
      });
      let atcounts = this.mthrChartData.map((item) => {
        return item.attcount;
      });
      let option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          show: true,
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          top: "18%",
          left: "8%",
          right: "9%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: times,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#0693D0",
            },
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "#0693D0",
            },
          },
          axisLabel: {
            color: "#0693D0",
            fontSize: 12,
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#303030",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#0693D0",
            },
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "#0693D0",
            },
          },
          axisLabel: {
            color: "#0693D0",
          },
        },
        series: [
          {
            type: "line",
            name: "出勤",
            data: atcounts,
            lineStyle: {
              color: "#3C78EF",
            },
            itemStyle: {
              color: "#3C78EF",
            },
          },
          {
            type: "line",
            name: "在册",
            data: sumcounts,
            lineStyle: {
              color: "#15BBB6",
            },
            itemStyle: {
              color: "#15BBB6",
            },
          },
        ],
      };
      await this.loadChart(`mthrchart`, option);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-con {
  position: relative;
  box-sizing: border-box;
  padding: 10px 20px;
  height: 100% !important;
  @include flrowjusali(space-between);
  .chart {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .left-con {
    position: relative;
    box-sizing: border-box;
    width: 28%;
    height: 100%;
    @include flcoljusali(space-between);
    .sincard-con:nth-child(1) {
      height: 60%;
      ::v-deep .boxInner {
        @include flcoljusali(flex-start);
      }
    }
    .sincard-con:nth-child(2) {
      height: 38%;
    }
    .list-con {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      flex: 1;
      height: 0;
      overflow-y: scroll;
      .ct-con {
        position: relative;
        width: 100%;
        .title-con {
          .title {
            font-size: 16px;
            font-weight: bold;
            color: rgb(255, 255, 255);
            line-height: 40px;
            &::before {
              display: inline-block;
              content: "";
              width: 5px;
              height: 16px;
              background: rgb(0, 180, 255);
              margin-right: 5px;
            }
          }
        }
        .data-con {
          box-sizing: border-box;
          position: relative;
          width: calc(100% - 20px);
          margin: 0px auto 10px;
          .title {
            font-size: 14px;
            line-height: 24px;
            font-weight: 800;
            color: rgb(218, 242, 249);
          }
          .dc-con {
            &::before {
              display: block;
              content: "";
              width: 100%;
              height: 16px;
              position: absolute;
              left: 0px;
              top: 0px;
              background: rgb(58, 58, 58);
              border-radius: 9px;
            }
            width: calc(100% - 60px);
            height: 16px;
            display: flex;
            box-sizing: border-box;
            position: relative;
            .line-con {
              position: absolute;
              left: 0px;
              top: 0px;
              height: 16px;
              background: rgb(0, 180, 255);
              border-radius: 9px;
            }
            .dcline-title {
              font-size: 16px;
              line-height: 16px;
              font-weight: 800;
              color: rgb(218, 242, 249);
              position: absolute;
              right: 0px;
              top: 0px;
              margin-right: -70px;
            }
          }
        }
      }
    }
  }
  .center-con {
    width: 42%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    @include flcoljusali(space-between);
    .bsw-con {
      position: relative;
      height: 15%;
      width: 100%;
      box-sizing: border-box;
      @include flrowjusali(flex-start, flex-start);
      .item-con {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        @include flrowjusali();
        .title {
          width: 100%;
          height: 21px;
          line-height: 21px;
          font-size: 21px;
          font-weight: bold;
          color: #fff;
          margin-bottom: 5px;
        }
        .value {
          width: 100%;
          font-size: 40px;
          font-family: Impact;
          font-weight: bold;
          color: #3dabff;
          text-align: center;
          line-height: 40px;
          .unit {
            font-size: 15px;
            font-family: Impact;
            font-weight: bold;
            color: #3dabff;
          }
        }
      }
      .sincard-con {
        height: 100%;
      }
    }
    .cqlqz {
      height: 40%;
      .man-con {
        position: relative;
        flex: 1;
        height: 100%;
        box-sizing: border-box;
        padding: 10px 15px;
        @include flrowjusali(space-between, flex-start);
        width: 100%;
        .item-con {
          position: relative;
          width: 48%;
          height: 100%;
          @include flcoljusali(flex-start, flex-start);
          .tag-con {
            position: relative;
            width: 91px;
            height: 25px;
            line-height: 25px;
            border: 1px solid #3699ff;
            opacity: 0.77;
            font-size: 17px;
            color: #3dabff;
            text-align: center;
          }
          .num-con {
            width: 100%;
            @include flrowjusali(space-between, flex-start);
            margin: 20px 0;
            .item-con {
              width: 58px;
              height: 58px;
              box-sizing: border-box;
              padding: 8px 0;
              text-align: center;
              p {
                width: 100%;
                height: 21px;
                line-height: 21px;
                font-size: 17px;
                color: #3dabff;
                margin: 0;
                text-align: center;
              }
            }
            .rate-con {
              width: 170px;
              height: 64px;
              background: url(~@/assets/images/main/laborer-numrate-bg.png) 0 0
                no-repeat;
              background-size: 100% 100%;
              position: relative;
              .title {
                width: 36px;
                height: 12px;
                font-size: 12px;
                font-weight: bolf;
                color: #17f7ff;
                line-height: 12px;
                position: absolute;
                top: 26px;
                left: 22px;
                text-align: center;
              }
              .inner-con {
                width: 100%;
                padding: 22px 0;
                padding-left: 100px;
                p,
                span {
                  line-height: 20px;
                  font-size: 20px;
                  font-family: Impact;
                  font-weight: 400;
                  color: #17f7ff;
                  margin: 0;
                }
              }
            }
          }
          .msechart-con {
            width: 100%;
            flex: 1;
            height: 0;
            ::v-deep .chart {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .zcqlqz {
      height: 41%;
    }
  }
  .right-con {
    position: relative;
    box-sizing: border-box;
    @include flcoljusali(space-between);
    width: 28%;
    height: 100%;
    .kqjl {
      height: 100%;
      .list-con {
        position: relative;
        padding: 0 10px;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        .table-con {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          font-size: 14px;
          @include flcoljusali(flex-start, flex-start);
          .title-con {
            position: relative;
            border: 1px solid #81cdf9;
            width: 100%;
            height: 40px;
            box-sizing: border-box;
            @include flrowjusali();
            padding-right: 10px;
            &::before {
              display: block;
              content: "";
              width: 100%;
              height: 100%;
              background: linear-gradient(90deg, #0f5177, #65bcff 51%, #0e5076);
              opacity: 0.5;
              position: absolute;
              left: 0;
              top: 0;
            }
            .item-con {
              flex: 1;
              width: 0;
              text-align: center;
              color: #fff;
              position: relative;
              font-weight: bold;
              z-index: 3;
            }
          }
          .scr-con {
            position: relative;
            width: 100%;
            box-sizing: border-box;
            flex: 1;
            height: 0;
            .swiper-container {
              position: relative;
              height: 100%;
              .swiper-slide {
                .tr {
                  color: #fff;
                  height: 56px;
                  background: hsla(0, 0%, 42%, 0.3);
                  width: 100%;
                  box-sizing: border-box;
                  padding-right: 10px;
                  @include flrowjusali(flex-start);
                  white-space: nowrap;
                  .td {
                    font-weight: bold;
                    span {
                      font-weight: bold;
                    }
                    text-align: center;
                    overflow: hidden;
                    flex: 1;
                    width: 0;
                    padding: 0 5px;
                    box-sizing: border-box;
                    .avatar {
                      width: 50px;
                      height: 50px;
                      display: block;
                    }
                    &:nth-child(1) {
                      @include flrowjusali();
                    }
                  }
                }
                &:nth-child(2n) {
                  .tr {
                    background: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .anchart {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .w30 {
    width: 30px;
  }
}
</style>
