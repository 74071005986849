<!-- bb chart 外层处理 -->
<template>
  <div class="bigdatachartcon-container">
    <slot v-if="!nodata"></slot>
    <div v-else class="nopart-con">
      <no-data></no-data>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import NoData from "@/components/nodata/index/index.vue";
export default {
  name: "OnepChartContainer",
  props: {
    data: [Object, Array],
    showNodata: {},
  },
  components: {
    "no-data": NoData,
  },
  computed: {
    nodata() {
      if (this.showNodata != undefined) {
        if (this.showNodata) {
          this.disposeEchartInstanceByDom();
        }
        return this.showNodata;
      }
      if (this.data == null) {
        this.disposeEchartInstanceByDom();
        return true;
      }
      if (this.data instanceof Array) {
        if (this.data.length == 0) {
          this.disposeEchartInstanceByDom();
        }
        return this.data.length == 0;
      }
      let { values } = this.data;
      if (values == undefined || values.length == 0) {
        this.disposeEchartInstanceByDom();
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      inshowNodata: this.showNodata,
      indata: this.data,
    };
  },
  watch: {},
  mounted() {},
  methods: {
    disposeEchartInstanceByDom() {
      for (const vn of this.$slots.default) {
        try {
          let eid = vn.data.attrs.id;
          if (this.$el) {
            let el = this.$el.querySelector(`#${eid}`);
            console.log(el);
            if (el) {
              let compareChart = echarts.getInstanceByDom(el);
              if (compareChart) {
                compareChart.dispose();
              }
            }
          }
        } catch (error) {
          console.warn(error);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bigdatachartcon-container {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  .nopart-con {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    @include flcoljusali(flex-start);
    img {
      position: relative;
      width: 50%;
    }
  }
  .msg {
    font-size: 0.26rem;
    padding: 0.25rem 0;
    color: $font-color-gray;
    text-align: center;
  }
}
</style>
