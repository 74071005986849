import BYMixins from "@/mixins/index/index.js";
import getDataHcQst from "./getDataHcQst";
import greenApi from "@/request/api/green.js";
import overlayApi from "@/request/api/overlay.js";
import safes from "@/views/green/index/safes";
export default {
  name: "Green",
  mixins: [BYMixins],
  data() {
    return {
      quData: [],
      hcInfo: [],
      qiData: [
        {
          id: 1,
          name: "温度",
          value: "30.8",
          key: "temp",
          bvalue: "优",
          bname: "空气质量",
          bkey: "kqzl",
        },
        {
          id: 2,
          name: "湿度",
          value: "64.8",
          key: "rh",
          bvalue: "17.000",
          bname: "PM2.5",
          bkey: "pm25",
        },
        {
          id: 3,
          name: "风速",
          value: "0",
          unit: "(m/s)",
          key: "windSpeed",
          bvalue: "22.000",
          bname: "PM10",
          bkey: "pm10",
        },
        {
          id: 4,
          name: "气压",
          value: "0",
          key: "pressure",
          bvalue: "0",
          bname: "tsp",
          bkey: "tsp",
        },
        {
          id: 5,
          name: "风向",
          value: "北风",
          bvalue: "47.2",
          key: "windDirection",
          bname: "噪声",
          bkey: "nvh",
        },
      ],
      qutrendData: [],
      reals:[],
      notNextTickOfrealfSwiper: true,
      sade:{

      },
      sadevisible: false,
      sadeconfirmLoading: false,
      logskey:1001

    };
  },
  computed: {
    realfSwiperOption() {
      let _this = this;
      let {reals} = this;
      let slidesPerView = 5;//这个会控制swiper-slide的高度 最好不要去强制修改css
      this.logskey += 1;
      return {
        speed: 500,
        loop: reals.length > slidesPerView,
        autoplay: {disableOnInteraction: false, delay: 3000},
        direction: "vertical",
        autoHeight: false,
        initialSlide: 0,
        //这个属性为一页显示的数量 一定要准确对照页面上
        //能够显示的个数填写 不然会出现问题
        slidesPerView,
        on: {
          click: function () {
            const realIndex = parseInt(this.clickedSlide.dataset.index);
            let item = reals[realIndex];
            _this.handleClickSafeItem(item);
          }
        }
      }
    },
  },

  mounted() {
    this.loadQutrendChart();
    /** 安全教育列表 */
    (async () => {
      let res = await greenApi.getSafaList();
      this.reals = res;
    })();
    /** 空气质量 */
    (async () => {
      let res = await greenApi.gethuanjingcharts();
      this.quData = res;
      this.loadQuChart();
    })();
    /** 空气质量指标 */
    (async () => {
      let res = await overlayApi.getProjectHcInfo();
      this.hcInfo = res;
      for (let index = 0; index < this.qiData.length; index++) {
        let element = this.qiData[index];
        if (Object.hasOwnProperty.call(res, element.key)) {
          let v = res[element.key];
          element.value = v;
        }
        if (Object.hasOwnProperty.call(res, element.bkey)) {
          let v = res[element.bkey];
          element.bvalue = v;
        }
      }
    })();
    (async () => {
      let res = await overlayApi.getHcDeviceInfo();
      this.qutrendData = res.list;
      this.loadQutrendChart();
    })();
  },
  methods: {
    sadehandleOk(){
      this.sadevisible = false;
    },
    sadehandleCancel(){
      this.sadevisible = false;
    },
    async handleClickSafeItem(item) {
      console.log(item);
      let {id, title, img} = item;
      try {
        let res = await greenApi.getSafaContent({
          id
        });
        let { content } = res;
        this.sade.title = title;
        this.sade.img = img;
        this.sade.content = content;
        this.sadevisible = true;
      }catch (e) {
        console.warn(e);
      }

    },
    // 空气质量
    async loadQuChart() {
      if (!this.quData) {
        return;
      }
      let names = this.quData.map((item) => {
        return item.title;
      });
      let data = this.quData.map((item) => {
        return {
          name: item.title,
          value: item.per,
        };
      });
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          x: "left",
          data: names,
          textStyle: { fontSize: 12, color: "rgba(255, 255, 255, .7)" },
        },
        color: [
          "#00c8cc",
          "#407fff",
          "#f29961",
          "#e15d68",
          "#a081e4",
          "#03a43b",
        ],
        series: [
          {
            name: "空气质量",
            type: "pie",
            radius: [0, 90],
            center: ["65%", "50%"],
            avoidLabelOverlap: false,
            label: { normal: { show: true } },
            labelLine: { normal: { show: true } },
            data,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
              normal: {
                label: {
                  show: true,
                  position: "outer",
                  formatter: "{b}: {c}({d}%)",
                },
              },
            },
          },
        ],
      };
      await this.loadChart(`quchart`, option);
    },
    async loadQutrendChart() {
      let result = this.qutrendData;
      let list = result;
      let times = list.map((item) => {
        let { updateTime } = item;
        let time = `${updateTime.split(":")[0]}h`;
        return time;
      });
      let pm2p5 = list.map((item) => {
        return item.pm25;
      });
      let pm10 = list.map((item) => {
        return item.pm10;
      });
      let xin = parseInt(pm2p5.length / 5);
      let option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          textStyle: {
            fontSize: 12,
            color: "rgba(255, 255, 255, .7)",
          },
          data: ["PM2.5", "PM10"],
        },
        calculable: true,
        color: [
          "#00c8cc",
          "#407fff",
          "#f29961",
          "#e15d68",
          "#a081e4",
          "#03a43b",
        ],
        grid: {
          left: "3%",
          right: "3%",
          top: "8%",
          bottom: "2%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: times,
            boundaryGap: false,
            axisTick: { alignWithLabel: true },
            axisLabel: {
              interval: xin,
              textStyle: {
                color: "rgba(255, 255, 255, .7)",
                fontSize: 12,
              },
            },
            axisLine: {
              lineStyle: { color: "rgba(255, 255, 255, .3)" },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value}",
              interval: 0,
              textStyle: {
                color: "rgba(255, 255, 255, .7)",
                fontSize: 12,
              },
            },
            axisLine: {
              lineStyle: { color: "rgba(255, 255, 255, .3)" },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "solid",
                color: "rgba(255, 255, 255, .1)",
              },
            },
          },
        ],
        series: [
          {
            type: "line",
            name: "PM2.5",
            data: pm2p5,
          },
          {
            type: "line",
            name: "PM10",
            data: pm10,
          },
        ],
      };
      await this.loadChart(`qutrendchart`, option);
    },
  },
};
