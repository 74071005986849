<template>
  <div class="tile datav-box">
    <div class="content-con">
      <div class="middlef-con">
        <div id="mapc" class="c-con"></div>
      </div>
    </div>
    <a-modal
      v-model="visiblefuvi"
      :footer="null"
      width="1000px"
      :title="vfulltitle"
      :centered="true"
      @cancel="handleFullViCancel"
      @ok="handleFullViOk"
    >
      <div class="vifull-con">
        <div
          id="playWnd"
          style="width: 96%; height: 100%; margin-right: 20px"
        ></div>
      </div>
    </a-modal>
  </div>
</template>

<script>
let videoComponent = null;
var oWebControl = null;
var accessToken = "";
/**
 * video 初始化及配置
 * @param {*video挂载的dom id} id
 * @param {*连接成功的回调} cbConnectSuccess
 * @param {*连接报错的回调} cbConnectError
 * @param {*连接关闭的回调} cbConnectClose
 */
function WebControlInit(id, cbConnectSuccess, cbConnectError, cbConnectClose) {
  return new WebControl({
    szPluginContainer: id,
    iServicePortStart: 14510, // 对应 LocalServiceConfig.xml 中的ServicePortStart值
    iServicePortEnd: 14519, // 对应 LocalServiceConfig.xml 中的ServicePortEnd值
    cbConnectSuccess: cbConnectSuccess,
    cbConnectError: cbConnectError,
    cbConnectClose: cbConnectClose,
  });
}
/**
 * 离开页面时调用 断开链接的方法
 * @param {*视频插件实例} oWebControl
 */
function WebControlDistory() {
  if (oWebControl != null) {
    oWebControl.JS_DestroyWnd().then(
      function () {
        console.log("JS_DestroyWnd");
      },
      function () {}
    );
    oWebControl.JS_StopService("window").then(function () {
      oWebControl.JS_Disconnect().then(
        function () {
          console.log("JS_Disconnect");
        },
        function () {}
      );
    });
  }
}
// 监听视频控件的事件
function cbIntegrationCallBack(oData) {
  videoComponent.showCBInfo(oData);
  if (oData.responseMsg.eventName === "FireTransFunction") {
    // 如果视频控件需要请求服务端数据，这里可以处理透传逻辑
  }
}

function cbConnectSuccess(responseItems) {
  // 设置窗口控制回调
  oWebControl.JS_SetWindowControlCallback({
    cbIntegrationCallBack: cbIntegrationCallBack,
  });
  //创建视频窗口
  oWebControl
    .JS_StartService("window", {
      dllPath: "./chain/cloudTransform.dll",
    })
    .then(function () {
      let width = document.getElementById("playWnd").clientWidth;
      let height = document.getElementById("playWnd").clientHeight;
      oWebControl.JS_CreateWnd("playWnd", width, height).then(function () {
        console.log("JS_CreateWnd success");

        initVideo(responseItems);
      });
    });
}

function cbConnectError() {
  console.log("cbConnectError");
  oWebControl = null;
  console.error("确认本地进程是否已安装并开启成功！");
}

function cbConnectClose(bNormalClose) {
  // 连接异常断开：bNormalClose = false
  // JS_Disconnect正常断开：bNormalClose = true
  console.log("cbConnectClose");
  oWebControl = null;
}
window.onscroll = function () {
  if (oWebControl != null) {
    oWebControl.JS_Resize(720, 400);
  }
};
window.onresize = function () {
  if (oWebControl != null) {
    oWebControl.JS_Resize(720, 400);
  }
};

window.onunload = function () {
  try {
    oWebControl.JS_HideWnd();
    WebControlDistory();
  } catch (error) {
    console.error(error);
  }
};
window.onpagehide = function () {
  try {
    oWebControl.JS_HideWnd();
  } catch (error) {
    console.error(error);
  }
};
var limitStart = parseInt(
  new Date("2021-03-21T01:01:00").getTime() / 1000
).toString();
var limitEnd = parseInt(
  new Date("2021-03-21T01:01:30").getTime() / 1000
).toString();

import lineList from "@/views/trans/index/line-list";
import Hls from "hls.js";
import videoApi from "@/request/api/video";
import { stationNames } from "@/views/trans/index/line-list";
import devices from "@/request/api/devices";

export default {
  name: "Tile",
  data() {
    return {
      marker: null,
      markers: [],
      stationMarkers: [],
      radioMarkers: [],
      workMarkers: [],
      carMarkers: [],
      lineArr: lineList["0"],
      visiblefuvi: false,
      vfulltitle: "",
      vfullpRefname: "vifullPlayer",
      hlsinstances: {},
      hivideos: [],
      //
      appKey: "",
      ezvizToken: "",
      deviceSerial: "",
      channelNo: "3",
      deviceClass: "0",
      platformId: "",
      startTime: "",
      endTime: "",
      layout: 1,
      switchVideo: 0, // 0实时预览 1录像回放
      callbackMessage: "", //显示回调信息
      tips: "调用成功",
      successTip: false,
      tipsShow: false,
    };
  },
  beforeMount() {},
  async mounted() {
    videoComponent = this;
    this.loadHivideoAccount();
    let map = new AMap.Map("mapc", {
      mapStyle: "amap://styles/dark", //设置地图的显示样式
      resizeEnable: true,
      layers: [new AMap.TileLayer.Satellite()],
      center: [106.520834, 29.460517],
      zoom: 14,
    });
    this.map = map;
    await this.getHivideos();
    // this.loadMarkers(map);
    this.loadStationMarkers(map);
    let { lineArr, marker } = this;
    {
      let polyline = new AMap.Polyline({
        map: map,
        path: lineList["3"],
        showDir: true,
        strokeColor: "#28F", //线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6, //线宽
        lineJoin: "round",
        // strokeStyle: "dashed",
        strokeStyle: "solid", //线样式
      });
    }
    this.getRedioMap();
    this.getWorkerMap();
    this.getCarMap();
  },
  methods: {
    async getRedioMap() {
      let res = await devices.getRedioMap();
      let points = res;
      let markers = [];
      let map = this.map;
      for (let index = 0; index < points.length; index++) {
        let element = points[index];
        let { lng, lat, title, state } = element;
        let position = [lng, lat];
        let icon = require(`@/assets/images/localico/gb_${state}.png`);
        let marker = new AMap.Marker({
          map,
          position,
          icon,
          offset: new AMap.Pixel(-23, -10),
          autoRotation: true,
        });
        // marker.hivideoItem = this.hivideos[index];
        // marker.on("click", this.showVideoInfo);
        marker.setMap(map);

        // 设置鼠标划过点标记显示的文字提示
        marker.setTitle(title);

        // 设置label标签
        // label默认蓝框白底左上角显示，样式className为：amap-marker-label
        marker.setLabel({
          offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
          content: `<div class='info' style='color:#000;'>${title}</div>`, //设置文本标注内容
          direction: "top", //设置文本标注方位
        });
        markers.push(marker);
      }
      this.radioMarkers = markers;
    },
    async getWorkerMap() {
      let res = await devices.getWorkerMap();
      let points = res;
      let markers = [];
      let map = this.map;
      for (let index = 0; index < points.length; index++) {
        let element = points[index];
        let { lng, lat, title, state } = element;
        let position = [lng, lat];
        let icon = require(`@/assets/images/localico/worker_${state}.png`);
        let marker = new AMap.Marker({
          map,
          position,
          icon,
          offset: new AMap.Pixel(-23, -10),
          autoRotation: true,
        });
        // marker.hivideoItem = this.hivideos[index];
        // marker.on("click", this.showVideoInfo);
        marker.setMap(map);

        // 设置鼠标划过点标记显示的文字提示
        marker.setTitle(title);

        // 设置label标签
        // label默认蓝框白底左上角显示，样式className为：amap-marker-label
        marker.setLabel({
          offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
          content: `<div class='info' style='color:#000;'>${title}</div>`, //设置文本标注内容
          direction: "top", //设置文本标注方位
        });
        markers.push(marker);
      }
      this.workMarkers = markers;
    },
    async getCarMap() {
      let res = await devices.getCarMap();
      let points = res;
      let markers = [];
      let map = this.map;
      for (let index = 0; index < points.length; index++) {
        let element = points[index];
        let { lng, lat, title, state } = element;
        let position = [lng, lat];
        let icon = require(`@/assets/images/localico/car_${state}.png`);
        let marker = new AMap.Marker({
          map,
          position,
          icon,
          offset: new AMap.Pixel(-23, -10),
          autoRotation: true,
        });
        // marker.hivideoItem = this.hivideos[index];
        // marker.on("click", this.showVideoInfo);
        marker.setMap(map);

        // 设置鼠标划过点标记显示的文字提示
        marker.setTitle(title);

        // 设置label标签
        // label默认蓝框白底左上角显示，样式className为：amap-marker-label
        marker.setLabel({
          offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
          content: `<div class='info' style='color:#000;'>${title}</div>`, //设置文本标注内容
          direction: "top", //设置文本标注方位
        });
        markers.push(marker);
      }
      this.carMarkers = markers;
    },

    /**
     * 设置视频初始化参数
     */
    initVideo: function (responseItems) {
      let self = this;
      if (!self.appKey || !self.ezvizToken) {
        self.showTips(false, "请输入appkey和token");
        return;
      }
      // 初始化入参
      let argumentsPram = {
        layout: +self.layout,
        userName: "",
        waterMark: "",
        intelligenceEnable: 1,
        isRecord: 1,
        isSetPos: 1,
        motionDetectEnable: 0,
        playBackAlarmOverlyingEnable: 0,
        response: {
          code: 0,
          message: null,
          data: {
            appKey: self.appKey,
            ezvizToken: self.ezvizToken,
            videoLevel: 0,
            iWndType: 0,
            showMainTool: 1,
            showSubTool: 1,
            waterMark: "1",
            userName: "",
            platformId: self.platformId,
          },
        },
      };
      // 调用视频控件初始化方法
      oWebControl
        .JS_RequestInterface({
          funcName: "Init",
          arguments: encodeURI(JSON.stringify(argumentsPram)),
        })
        .then(function (oData) {
          // self.showCBInfo(oData.responseMsg);
          self.showTips(true, "视频初始化成功！");
          console.log("视频初始化成功");
          setTimeout(function () {
            {
              oWebControl
                .JS_RequestInterface({
                  funcName: "setLayout",
                  arguments: encodeURI(
                    JSON.stringify({
                      layout: "1x1",
                    })
                  ),
                })
                .then(function (res) {
                  console.log("res is ", res);
                  self.playVideo(responseItems);
                });
            }
          }, 1500);
        });
    },
    /**
     * 播放门店视频
     */
    playVideo: function (responseItems) {
      let self = this;
      // 预览入参
      let argumentsPram = {
        response: {
          code: 0,
          message: null,
          data: {
            deviceSerial: self.deviceSerial,
            channelCode: self.channelCode,
            channelNo: +self.channelNo,
            codeIsEncrypt: 0,
            validateCode: "",
            deviceClass: self.deviceClass,
            deviceType: "10242",
            //deviceType:"10240",
            channelId: "c55d06ffbded46baa4ca84fbc106de20",
            channelName: "channelNameTest",
            storeName: "storeName",
            storeId: "storeid",
            ...responseItems,
            //startTime:'2021-03-21 01:00:00',
            //endTime:'2021-03-21 01:02:00',
            //Limited_start:limitStart,
            //Limited_end:limitEnd,
          },
        },
      };
      // 调用视频控件预览方法
      oWebControl
        .JS_RequestInterface({
          funcName: "StartPreview",
          arguments: encodeURI(JSON.stringify(argumentsPram)),
        })
        .then(function (oData) {
          //console.log("预览/log11", oData.responseMsg);
          //console.log(JSON.stringify(argumentsPram));
          self.showCBInfo(oData.responseMsg);
          self.showTips(true, "预览/回放成功！");
        });
    },
    /**
     * 设置事件回调信息
     */
    showCBInfo: function (message) {
      this.callbackMessage =
        this.callbackMessage + JSON.stringify(message) + "\n\n";
    },
    showTips: function (status, message) {
      let self = this;
      this.successTip = status;
      this.tips = message;
      this.tipsShow = true;
      setTimeout(function () {
        self.tipsShow = false;
      }, 1000);
    },
    /**
     * 隐藏视频
     */
    hideVideo: function () {
      oWebControl.JS_HideWnd();
    },
    /**
     * 显示视频
     */
    showVideo: function () {
      oWebControl.JS_ShowWnd();
    },
    /**
     * 获取窗口数
     */
    GetLayout: function () {
      let data = null;
      let self = this;

      oWebControl
        .JS_RequestInterface({
          funcName: "GetLayout",
          arguments: encodeURI(
            JSON.stringify({
              response: {
                code: 0,
                message: null,
                data: data,
              },
            })
          ),
        })
        .then(function (oData) {
          console.log(JSON.stringify(oData.responseMsg));
          self.showCBInfo(oData.responseMsg);
        });
    },
    /**
     * 抓图
     */
    CaptureJPGEx: function () {
      let data = null;
      let self = this;

      oWebControl
        .JS_RequestInterface({
          funcName: "CaptureJPGEx",
          arguments: encodeURI(
            JSON.stringify({
              response: {
                code: 0,
                message: null,
                data: data,
              },
            })
          ),
        })
        .then(function (oData) {
          console.log(JSON.stringify(oData.responseMsg));
          self.showCBInfo(oData.responseMsg);
        });
    },
    async getHivideos() {
      try {
        let res = await videoApi.gethikcameraList();
        this.hivideos = res;
        let markers = [];
        for (let index = 0; index < res.length; index++) {
          let element = res[index];
          let position = [element.lng, element.lat];
          let marker = new AMap.Marker({
            map: this.map,
            position,
            icon: require(`@/assets/images/localico/live_${element.channelStatus}.png`),
            //icon:sxtIcon,
            offset: new AMap.Pixel(-26, -65),
            autoRotation: true,
          });

          marker.hivideoItem = this.hivideos[index];
          marker.on("click", this.showVideoInfo);
          markers.push(marker);
          //console.log(points);
          // 设置鼠标划过点标记显示的文字提示
          marker.setTitle(element.channelName);
        }
        this.markers = markers;
      } catch (error) {}
    },
    async loadHivideoAccount() {
      try {
        let res = await videoApi.ezvizaccount();
        let { appKey, token: ezvizToken } = res;
        this.appKey = appKey;
        this.ezvizToken = ezvizToken;
      } catch (error) {}
    },
    loadMarkers(map) {
      let temp = [];
      let points = temp.concat(lineList["3"]);
      if (points.length > this.hivideos.length) {
        points = points.slice(0, this.hivideos.length);
      }
      let markers = [];
      for (let index = 0; index < points.length; index++) {
        let element = points[index];
        let marker = new AMap.Marker({
          map: map,
          position: element,
          icon: require("@/assets/images/video/cctv-camera.png"),
          offset: new AMap.Pixel(-86, -65),
          autoRotation: true,
        });

        marker.hivideoItem = this.hivideos[index];
        marker.on("click", this.showVideoInfo);
        markers.push(marker);
        //console.log(points);
        // 设置鼠标划过点标记显示的文字提示
        //marker.setTitle(staname);
      }
      this.markers = markers;
    },
    loadStationMarkers(map) {
      let points = lineList["3"];
      let markers = [];
      for (let index = 0; index < points.length; index++) {
        let element = points[index];
        let staname = stationNames[index];
        let marker = new AMap.Marker({
          map: map,
          position: element,
          //icon: require("@/assets/images/trans/train.png"),
          offset: new AMap.Pixel(-23, -10),
          autoRotation: true,
        });
        // marker.hivideoItem = this.hivideos[index];
        // marker.on("click", this.showVideoInfo);
        marker.setMap(map);

        // 设置鼠标划过点标记显示的文字提示
        marker.setTitle(staname);

        // 设置label标签
        // label默认蓝框白底左上角显示，样式className为：amap-marker-label
        marker.setLabel({
          offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
          content: `<div class='info' style='color:#000;'>${staname}</div>`, //设置文本标注内容
          direction: "top", //设置文本标注方位
        });
        markers.push(marker);
      }
      this.stationMarkers = markers;
    },
    videoPause(refname) {
      let hls = this.hlsinstances[refname];
      if (hls) {
        let video;
        if (this.$refs[refname].constructor == Array) {
          video = this.$refs[refname][0];
        } else {
          video = this.$refs[refname];
        }
        video.pause();
        hls.destroy();
        delete this.hlsinstances[refname];
      }
    },
    handleFullViOk() {
      // this.videoPause(this.vfullpRefname);
    },
    handleFullViCancel() {
      // this.videoPause(this.vfullpRefname);
      WebControlDistory();
    },
    async showVideoInfo(e) {
      console.log(e);
      // let text =
      //   "您在 [ " +
      //   e.lnglat.getLng() +
      //   "," +
      //   e.lnglat.getLat() +
      //   " ] 的位置点击了marker！";
      // alert(text);
      let hivideoItem = e.target.hivideoItem;
      let { channelName, deviceSerial, channelNo, channelStatus, channelId } =
        hivideoItem;
      this.visiblefuvi = true;
      await this.$nextTick();
      //
      oWebControl = WebControlInit(
        "playWnd",
        function () {
          cbConnectSuccess({
            channelName: channelName,
            deviceSerial: deviceSerial,
            channelNo: +channelNo,
            channelCode: channelStatus,
            channelId: channelId,
          });
        },
        cbConnectError,
        cbConnectClose
      );
      window.initVideo = this.initVideo;
    },
  },
  beforeDestroy() {
    //销毁前销毁掉hls实例 不然会一直请求
    for (const key in this.hlsinstances) {
      if (Object.hasOwnProperty.call(this.hlsinstances, key)) {
        this.videoPause(key);
      }
    }
    if (this.markers.length > 0) {
      this.markers.forEach((marker) => {
        marker.off("click", this.showVideoInfo);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.vifull-con {
  position: relative;
  width: 100%;
  height: 600px;
}
.tile {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: transparent;

  .content-con {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    @include flrowjusali(space-between, flex-start);

    .middlef-con {
      position: relative;
      width: 100%;
      height: 100%;
      @include flcoljusali(space-between, flex-start);
      .c-con {
        height: calc(100%);
        width: 100%;
      }
      .bottom-con {
        position: relative;
        width: 100%;
        height: 62.44%;
        @include flrowjusali(space-between);

        .listcs-con {
          position: relative;
          width: calc(50% - 10px);
          height: 100%;
          box-sizing: border-box;

          .list-con {
            position: relative;
            padding: 0 10px;
            box-sizing: border-box;
            width: 100%;
            height: calc(100% - 200px);

            .table-con {
              width: 100%;
              height: 100%;
              box-sizing: border-box;
              font-size: 14px;
              @include flcoljusali(flex-start, flex-start);

              .title-con {
                position: relative;
                border: 1px solid #81cdf9;
                width: 100%;
                height: 40px;
                box-sizing: border-box;
                @include flrowjusali();
                padding-right: 10px;

                &::before {
                  display: block;
                  content: "";
                  width: 100%;
                  height: 100%;
                  background: linear-gradient(
                    90deg,
                    #0f5177,
                    #65bcff 51%,
                    #0e5076
                  );
                  opacity: 0.5;
                  position: absolute;
                  left: 0;
                  top: 0;
                }

                .item-con {
                  flex: 1;
                  width: 0;
                  text-align: center;
                  color: #fff;
                  position: relative;
                  font-weight: bold;
                  z-index: 3;

                  &:nth-child(1) {
                    flex: 0.5;
                  }
                }
              }

              .scr-con {
                position: relative;
                width: 100%;
                box-sizing: border-box;
                flex: 1;
                height: 0;

                .swiper-container {
                  position: relative;
                  height: 100%;

                  .swiper-slide {
                    height: auto !important;

                    .tr {
                      color: #fff;
                      height: 56px;
                      background: hsla(0, 0%, 42%, 0.3);
                      width: 100%;
                      box-sizing: border-box;
                      padding-right: 10px;
                      @include flrowjusali(flex-start);
                      white-space: nowrap;

                      .td {
                        text-overflow: bold;
                        text-align: center;
                        overflow: hidden;
                        flex: 1;
                        width: 0;
                        padding: 0 5px;
                        box-sizing: border-box;

                        &:nth-child(1) {
                          flex: 0.5;
                          width: 0;
                        }
                      }
                    }

                    &:nth-child(2n) {
                      .tr {
                        background: transparent;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
