<template>
  <div class="video dv-box-border">
    <div class="content-con">
      <div class="left-con">
        <input
          placeholder="请输入摄像头名称"
          class="search-input nptrem"
          :value="scenic_cate_searchname"
          @input="handleSearchInput"
          @compositionstart="handleSearchInputCompositionstart"
          @compositionend="handleSearchInputCompositionend"
          type="text"
        />
        <div class="me-con">
          <menu-tree
            @selectComplete="leftMenuSelComplete"
            :data="filter_l_menu_data"
            color="rgb(0, 200, 220)"
            :active-index="menu_tree_acindex"
            child-key="node"
          />
        </div>
      </div>
      <div id="playWnd" class="right-con" />
    </div>
  </div>
</template>

<script>
import Index from "./index.js";

export default Index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
<style lang="scss">
.ant-select-dropdown {
  color: hsla(0, 0%, 100%, 0.65);
  background-color: #141414;
  box-shadow: 0 2px 8px hsla(0, 0%, 100%, 0.15);
  border-radius: 4px;
}

.ant-select-dropdown-menu-item {
  color: hsla(0, 0%, 100%, 0.65);
}

.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-selected:hover {
  background-color: #141414;
  color: hsla(0, 0%, 100%, 0.65);
}

.ant-select-dropdown-menu-item:hover,
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #262626 !important;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #262626 !important;
}

.vifull-con {
  position: relative;
  width: 100%;
  height: calc(100vh - 170px);
}
</style>
