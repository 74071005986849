import http from '../http.js';

const api = {
    //获取车辆列表
    getCarList: function(params = {}) {
        return http({
            apiDomainIndex: 0,
            method: 'get',
            url: `/datav/cg/getCarList`,
            data: params,
        });
    },

};

export default api;
