import BYMixins from '@/mixins/index/index.js';
export default {
  name: 'Safely',
  mixins: [BYMixins],
  components: {},
  data() {
    return {
      cheproData: {},
      seqData: {},
      saprData: {},
      recSwiperOption: {
        initialSlide: 0,
        slidesPerView: 9,
        direction: 'vertical',
        loop: false,
        speed: 500,
        autoplay: { disableOnInteraction: false, delay: 3000 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiper: true,
    };
  },
  mounted() {
    this.loadCheperChart();
    this.loadSeqChart();
    this.loadSaprChart();
  },
  methods: {
    async loadCheperChart() {
      let { getRankingList } = this.getMockData();
      let { result } = getRankingList;
      let names = result.map((item) => {
        return item.creatorName;
      });
      let values = result.map((item) => {
        return item.peopleNum;
      });
      let option = {
        color: ['#00ffdb'],
        tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
        grid: {
          left: '3%',
          right: '3%',
          top: '4%',
          bottom: '1%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: names,
            axisTick: { alignWithLabel: true },
            axisLabel: {
              interval: 0,
              rotate: 50,
              textStyle: { color: '#FFF', fontSize: 12 },
            },
            axisLine: {
              lineStyle: { color: 'rgba(255, 255, 255, .3)' },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            minInterval: 1,
            axisLabel: {
              interval: 0,
              textStyle: { color: '#FFF', fontSize: 12 },
            },
            axisLine: {
              lineStyle: { color: 'rgba(255, 255, 255, .3)' },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgba(255, 255, 255, .1)',
              },
            },
          },
        ],
        series: [{ name: '责任人', type: 'bar', barWidth: '30', data: values }],
      };
      await this.loadChart(`cheperchart`, option);
    },
    async loadSeqChart() {
      let { getPieChart } = this.getMockData();
      let { result } = getPieChart;
      let names = result.map((item) => {
        return item.troubleTypeName;
      });
      let values = result.map((item) => {
        return { value: item.typenum, name: item.troubleTypeName };
      });
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        color: [
          '#00c8cc',
          '#407fff',
          '#f29961',
          '#e15d68',
          '#a081e4',
          '#03a43b',
        ],
        legend: {
          orient: 'vertical',
          x: 'left',
          data: names,
          textStyle: {
            fontSize: 12,
            color: 'rgba(255, 255, 255, .7)',
          },
        },
        series: [
          {
            name: '安全类型',
            type: 'pie',
            radius: [0, 70],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            label: { normal: { show: true } },
            labelLine: { normal: { show: true } },
            data: values,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
              normal: {
                label: {
                  show: true,
                  position: 'outer',
                  formatter: '{b}: {c}({d}%)',
                },
              },
            },
          },
        ],
      };
      await this.loadChart(`seqchart`, option);
    },
    async loadSaprChart() {
      let data = [0, 0, 0, 73, 61, 78, 111, 0, 0, 0, 0, 0];
      let option = {
        color: ['#305683'],
        tooltip: { trigger: 'axis' },
        grid: {
          left: '3%',
          right: '3%',
          top: '4%',
          bottom: '1%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: [
              '1月',
              '2月',
              '3月',
              '4月',
              '5月',
              '6月',
              '7月',
              '8月',
              '9月',
              '10月',
              '11月',
              '12月',
            ],
            axisTick: { alignWithLabel: true },
            axisLabel: {
              interval: 0,
              rotate: 50,
              textStyle: { color: '#fff', fontSize: 12 },
            },
            axisLine: {
              lineStyle: { color: 'rgba(255, 255, 255, .3)' },
            },
          },
        ],
        yAxis: {
          type: 'value',
          minInterval: 1,
          axisLabel: {
            interval: 0,
            textStyle: { color: '#fff', fontSize: 12 },
          },
          axisLine: {
            lineStyle: { color: 'rgba(255, 255, 255, .3)' },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: 'rgba(255, 255, 255, .1)',
            },
          },
        },
        series: [
          {
            name: '安全问题数量',
            data,
            type: 'line',
            areaStyle: {},
          },
        ],
      };
      await this.loadChart(`saprchart`, option);
    },
    getMockData() {
      let getRankingList = {
        success: true,
        message: '操作成功！',
        code: 200,
        result: [
          {
            creatorName: '高殿泽',
            peopleNum: 116,
          },
          {
            creatorName: '刘佳乐',
            peopleNum: 107,
          },
          {
            creatorName: '郭兵军',
            peopleNum: 95,
          },
          {
            creatorName: '何亮亮',
            peopleNum: 88,
          },
          {
            creatorName: '王亚洲',
            peopleNum: 68,
          },
        ],
        timestamp: 1627309705825,
      };
      let getPieChart = {
        success: true,
        message: '操作成功！',
        code: 200,
        result: [
          {
            troubleTypeName: null,
            typenum: 11,
          },
          {
            troubleTypeName: '安全',
            typenum: 2,
          },
          {
            troubleTypeName: '质量',
            typenum: 2750,
          },
        ],
        timestamp: 1627309706407,
      };
      return {
        getRankingList,
        getPieChart,
      };
    },
  },
};
