<template>
  <div class="green">
    <div class="left-con">
      <div class="fi-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">安全教育</span>
          </div>
        </div>
        <div class="list-con">
          <div class="table-con">
            <div class="title-con">
              <div class="item-con">
                <p>图片</p>
              </div>
              <div class="item-con">
                <p>标题</p>
              </div>
            </div>
            <div class="scr-con">
              <swiper
                  v-if="reals && reals.length > 0"
                  :options="realfSwiperOption"
                  :not-next-tick="notNextTickOfrealfSwiper"
                  ref="realfSwiper"
                  :key="logskey"
              >
                <swiper-slide v-for="(item, index) in reals" :key="index" :data-index="index">
                  <div class="tr">
                    <div class="td">
                      <img class="avatar" :src="item.img" alt="" />
                    </div>
                    <div class="td">
                      <span class="">{{ item.title }}</span>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>

      </div>
      <div class="se-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">空气质量</span>
          </div>
        </div>
        <div class="quchart-con">
          <bbchart-con :data="quData">
            <div id="quchart" class="quchart"></div>
          </bbchart-con>
        </div>
      </div>
    </div>
    <div class="right-con">
      <div class="top-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">空气质量</span>
          </div>
        </div>
        <div class="bottomlist-con">
          <div class="item-con" v-for="item in qiData" :key="item.id">
            <div class="top-con">
              <div class="title">{{ item.name }}</div>
              <div class="con">{{ item.value }} {{ item.unit }}</div>
            </div>
            <div class="bottom-con">
              <div class="con">{{ item.bvalue }}</div>
            </div>
            <div class="title-bottom">{{ item.bname }}</div>
          </div>
        </div>
      </div>
      <div class="bottom-con dv-box-border">
        <div class="dv-board-title nptrem">
          <div class="title-con">
            <span class="title">空气质量趋势</span>
          </div>
        </div>
        <div class="qutrend-con">
          <bbchart-con :data="qutrendData">
            <div id="qutrendchart" class="qutrendchart"></div>
          </bbchart-con>
        </div>
      </div>
    </div>
    <a-modal
        title="详情"
        :visible="sadevisible"
        :confirm-loading="sadeconfirmLoading"
        @ok="sadehandleOk"
        @cancel="sadehandleCancel"
        :width="1500"
    >
      <div class="green-sadetailmodal-con">
        <p class="ti">{{sade.title}}</p>
        <img :src="sade.img" alt="">
        <div class="c-con" v-html="sade.content" />
      </div>

    </a-modal>
  </div>
</template>

<script>
import Index from "./index.js";
export default Index;
</script>

<style lang="scss">
@import "./index.scss";
.green-sadetailmodal-con {
  position: relative;
  @include flcoljusali();
  .ti {
    font-size: 22px;
    margin: 20px 0;
  }
  img {
    width: 80%;
  }
  .c-con {
    position: relative;
    margin-top: 20px;
    p {
      font-size: 18px;
    }
  }

}
</style>

<style lang="scss" scoped>
@import "./index.scss";
</style>
