import BYMixins from "@/mixins/index/index.js";
import {Progress, Tooltip} from "ant-design-vue";
import "ant-design-vue/lib/tooltip/style/index.css"; // 或者 ant-design-vue/lib/button/style/css 加载 css 文件
import "ant-design-vue/lib/progress/style/index.css"; // 或者 ant-design-vue/lib/button/style/css 加载 css 文件
import overlayApi from "@/request/api/overlay.js";
import MutationType from "@/store/mutation-type";

export default {
    name: "Overlay",
    data() {
        return {
            /**
             notNextTick是一个组件自有属性，如果notNextTick设置为true，
             组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，
             假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
             */
            notNextTick: true,
            safenotNextTick: true,
            notNextTickOfLogSwiper: true,
            notNextTickOfrealfSwiper: true,
            swiperOption: {
                // swiper options 所有的配置同swiper官方api配置
                autoplay: {disableOnInteraction: false, delay: 3000},
                direction: "horizontal",
                // autoHeight: true,
                // 如果自行设计了插件，那么插件的一些配置相关参数，也应该出现在这个对象中，如下debugger
                // debugger: true,
                // swiper callbacks
                // swiper的各种回调函数也可以出现在这个对象中，和swiper官方一样
                onTransitionStart(swiper) {
                    console.log(swiper);
                },
                // more Swiper configs and callbacks...
                // ...
            },
            safeswiperOption: {
                // swiper options 所有的配置同swiper官方api配置
                autoplay: {disableOnInteraction: false, delay: 3000},
                direction: "horizontal",
                // autoHeight: true,
                // 如果自行设计了插件，那么插件的一些配置相关参数，也应该出现在这个对象中，如下debugger
                // debugger: true,
                // swiper callbacks
                // swiper的各种回调函数也可以出现在这个对象中，和swiper官方一样
                onTransitionStart(swiper) {
                    console.log(swiper);
                },
                // more Swiper configs and callbacks...
                // ...
            },
            logSwiperOption: {
                speed: 500,
                loop: true,
                autoplay: {disableOnInteraction: false, delay: 3000},
                direction: "vertical",
                autoHeight: false,
                initialSlide: 0,
                slidesPerView: 5,
            },
            realfSwiperOption: {
                speed: 500,
                loop: true,
                autoplay: {disableOnInteraction: false, delay: 3000},
                direction: "vertical",
                autoHeight: false,
                initialSlide: 0,
                slidesPerView: 5,
            },
            /** 项目信息 */
            projectInfo: [],
            /** 工程汇总 */
            proAnl: {},
            /** 开挖工程量 */
            proAlAmount: [],
            /* banner图 */
            projectImages: [],
            /* 安全教育banner图 */
            safes: [],
            safeInfo: {},
            hcInfo: [],
            qiData: [
                {
                    id: 4,
                    bvalue: "-",
                    bname: "温度",
                    bkey: "temp",
                    unit:"(°C)"
                },
                {
                    id: 2,
                    bvalue: "-",
                    bname: "PM2.5",
                    bkey: "pm25",
                },
                {
                    id: 3,
                    bvalue: "-",
                    bname: "PM10",
                    bkey: "pm10",
                },
                {
                    id: 5,
                    bvalue: "-",
                    bname: "风级",
                    bkey: "nvh",
                    unit: "(m/s)"
                },
            ],

        };
    },
    components: {
        "a-progress": Progress,
        "a-tooltip": Tooltip,
    },
    mixins: [BYMixins],
    mounted() {
        this.handleLoadData();
        // this.startPollingData();
    },
    methods: {
        /** 处理轮询的数据 */
        handlePollingData() {
            this.handleLoadData();
        },
        handleLoadData() {
            /** 获取项目信息 */
            (async () => {
                try {
                    let res = await overlayApi.getIndexProjectInfo();
                    if (res) {
                        this.projectInfo = res.info;
                        this.projectImages = res.indexImg;
                        // this.$store.commit(MutationType.SET_SYSTITLE, res.systitle);
                    }
                } catch (e) {
                    console.warn(e);
                }
            })();
            /** 工程汇总 */
            (async () => {
                try {
                    let res = await overlayApi.getQuantitiesTotal();
                    if (res) {
                        let {jzcl} = res;
                        this.proAnl = jzcl;
                    }
                } catch (e) {
                    console.warn(e);
                }
            })();
            /** 开挖工程量列表 */
            (async () => {
                try {
                    let res = await overlayApi.getProgress();
                    if (res) {
                        let {kwgcl} = res;
                        this.proAlAmount = kwgcl;
                    }
                } catch (e) {
                    console.warn(e);
                }
            })();
            /** 安全教育 */
            (async () => {
                try {
                    let res = await overlayApi.getIndexSafa();
                    if (res) {
                        let {list, jypx, pxrc} = res;
                        this.safes = list;
                        this.safeInfo = {
                            jypx,
                            pxrc
                        }
                    }
                } catch (e) {
                    console.warn(e);
                }
            })();
            /** 空气质量指标 */
            (async () => {
                let res = await overlayApi.getProjectHcInfo();
                this.hcInfo = res;
                for (let index = 0; index < this.qiData.length; index++) {
                    let element = this.qiData[index];
                    if (Object.hasOwnProperty.call(res, element.bkey)) {
                        let v = res[element.bkey];
                        element.bvalue = v;
                    }
                }
            })();
        },
        handleDvsmaSubmit(e) {
            e.preventDefault();
            this.form.validateFields(async (err, values) => {
                if (!err) {
                    console.log(values);
                    let params = {};
                    let {userName, time} = values;
                    if (userName) {
                        params.PersonName = userName;
                    }
                    if (time) {
                        let startTime = time[0].format("YYYY-MM-DD");
                        let endTime = time[1].format("YYYY-MM-DD");
                        params.date1 = startTime;
                        params.date2 = endTime;
                    }
                    this.dvsmasubLoading = true;
                    let result = await overlayApi
                        .getseachworkerenter(params)
                        .catch((err) => {
                            console.log(err);
                        });
                    this.dvsmadata = result;
                    this.dvsmasubLoading = false;
                }
            });
        },
        handleDvsmaReset() {
            this.form.resetFields();
            this.dvsmadata = [];
        },
        handleInoutperOk() {
        },
        handleInoutperCancel() {
        },
        /** 点击实时动态更多 */
        handleReatdMore() {
            this.vismdInoutper = true;
        },
        handleDvsOk() {
        },
        handleDvsCancel() {
        },
        handleClickDvs() {
            this.vismdDvsList = true;
        },
        /** 加载出勤管理 */
        async loadAttmData() {
            let selitem = this.attmTabs.find((item) => {
                return item.active;
            });
            let {key} = selitem;
            let res = await overlayApi
                .getAttachment({
                    type: key,
                })
                .catch((err) => {
                    console.log(err);
                });
            let {allAttlist, attachment, attlist} = res;
            this.attcount = attachment.cqcount;
            this.atsumcount = attachment.sumcount;
            this.atChartData = allAttlist;
            this.amChartData = attlist;
            this.loadAttendanceChart();
            this.loadAttmChart();
        },
        /** 出勤管理tab切换 */
        handleAttmChange(item) {
            let {attmTabs} = this;
            attmTabs.forEach((element) => {
                element.active = false;
            });
            item.active = true;
            this.loadAttmData();
        },
        async loadAQIChart() {
            if (!this.aqiChartData) {
                return;
            }
            let value = this.aqiChartData[0];
            let option = {
                series: [
                    {
                        type: "gauge",
                        splitNumber: 5,
                        axisLine: {
                            lineStyle: {
                                width: 10,
                            },
                        },
                        max: 5,
                        center: ["50%", "50%"],
                        radius: `98%`,
                        axisTick: {
                            length: 8,
                            lineStyle: {
                                color: "#fff",
                                width: 1,
                            },
                        },
                        splitLine: {
                            length: 10,
                            lineStyle: {
                                color: "#fff",
                                width: 2,
                            },
                        },
                        axisLabel: {
                            color: "auto",
                            distance: 5,
                            fontSize: 12,
                            fontWeight: "bold",
                        },
                        pointer: {
                            show: true,
                            width: 3,
                            length: "50%",
                            itemStyle: {
                                color: "auto",
                            },
                        },
                        detail: {
                            valueAnimation: true,
                            formatter: "{value} AQI",
                            fontSize: 11,
                            fontWeight: "bold",
                            padding: [40, 0, 0, 0],
                            color: "#88B9A3",
                        },
                        title: {
                            show: true,
                            color: "#B7B7B7",
                            offsetCenter: [0, -11],
                            fontWeight: "bold",
                            fontSize: 14,
                        },
                        data: [
                            {
                                name: "AQI",
                                value,
                            },
                        ],
                    },
                ],
            };
            await this.loadChart(`aqichart`, option);
        },
        async loadEnvChart() {
            if (!this.envChartData) {
                return;
            }
            let result = this.envChartData;
            let {list} = result;
            let times = list.map((item) => {
                let {updateTime} = item;
                let time = `${updateTime.split(":")[0]}h`;
                return time;
            });
            let pm2p5 = list.map((item) => {
                return item.pm25;
            });
            let pm10 = list.map((item) => {
                return item.pm10;
            });
            let option = {
                tooltip: {
                    trigger: "axis",
                },
                legend: {
                    data: ["PM2.5", "PM10"],
                    textStyle: {
                        color: "#BABABA",
                    },
                },
                grid: {
                    top: "8%",
                    left: "3%",
                    right: "4%",
                    bottom: "2%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: times,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#5E5E5E",
                        },
                    },
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: "#5E5E5E",
                        },
                    },
                    axisLabel: {
                        color: "#BABABA",
                        fontSize: 12,
                    },
                },
                yAxis: {
                    type: "value",
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#303030",
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#5E5E5E",
                        },
                    },
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: "#5E5E5E",
                        },
                    },
                    axisLabel: {
                        color: "#BABABA",
                    },
                },
                series: [
                    {
                        type: "line",
                        name: "PM2.5",
                        data: pm2p5,
                        lineStyle: {
                            color: "#FFD52B",
                        },
                        itemStyle: {
                            color: "#FFD631",
                        },
                    },
                    {
                        type: "line",
                        name: "PM10",
                        data: pm10,
                        lineStyle: {
                            color: "#15BBB6",
                        },
                        itemStyle: {
                            color: "#15BBB6",
                        },
                    },
                ],
            };
            await this.loadChart(`envchart`, option);
        },
        async loadSafeCheckChart() {
            let option = {
                tooltip: {
                    trigger: "axis",
                },
                legend: {
                    data: ["趋势"],
                },
                grid: {
                    top: "8%",
                    left: "3%",
                    right: "4%",
                    bottom: "2%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: [
                        "1月",
                        "2月",
                        "3月",
                        "4月",
                        "5月",
                        "6月",
                        "7月",
                        "8月",
                        "9月",
                        "10月",
                        "11月",
                        "12月",
                    ],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#5E5E5E",
                        },
                    },
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: "#5E5E5E",
                        },
                    },
                    axisLabel: {
                        color: "#BABABA",
                        fontSize: 12,
                        interval: 0,
                    },
                },
                yAxis: {
                    type: "value",
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#303030",
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#5E5E5E",
                        },
                    },
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: "#5E5E5E",
                        },
                    },
                    axisLabel: {
                        color: "#BABABA",
                    },
                },
                series: [
                    {
                        type: "line",
                        data: [120, 132, 101, 134, 90, 230, 210, 120, 132, 101, 134, 90],
                        lineStyle: {
                            color: "#FFD52B",
                        },
                        itemStyle: {
                            color: "#FFD631",
                        },
                    },
                ],
            };
            await this.loadChart(`safechart`, option);
        },
        // 总出勤趋势图
        async loadAttendanceChart() {
            if (!this.atChartData) {
                return;
            }
            let times = this.atChartData.map((item) => {
                return item.create_date;
            });
            /** 出勤人数 */
            let attcount = this.atChartData.map((item) => {
                return item.attcount;
            });
            /** 在册人数 */
            let sumcount = this.atChartData.map((item) => {
                return item.sumcount;
            });
            let option = {
                tooltip: {
                    trigger: "axis",
                },
                grid: {
                    top: "8%",
                    left: "3%",
                    right: "4%",
                    bottom: "2%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: times,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#5E5E5E",
                        },
                    },
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: "#5E5E5E",
                        },
                    },
                    axisLabel: {
                        color: "#BABABA",
                        fontSize: 12,
                        interval: 0,
                    },
                },
                yAxis: {
                    type: "value",
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#303030",
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#5E5E5E",
                        },
                    },
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: "#5E5E5E",
                        },
                    },
                    axisLabel: {
                        color: "#BABABA",
                    },
                },
                series: [
                    {
                        type: "line",
                        name: "出勤人数",
                        data: attcount,
                        lineStyle: {
                            color: "#FFD52B",
                        },
                        itemStyle: {
                            color: "#FFD631",
                        },
                    },
                    {
                        type: "line",
                        name: "在册人数",
                        data: sumcount,
                        lineStyle: {
                            color: "#15BBB6",
                        },
                        itemStyle: {
                            color: "#15BBB6",
                        },
                    },
                ],
            };
            await this.loadChart(`atchart`, option);
        },
        // 出勤趋势图
        async loadAttmChart() {
            if (!this.amChartData) {
                return;
            }
            let times = this.amChartData.map((item) => {
                return item.create_date;
            });
            /** 出勤人数 */
            let attcount = this.amChartData.map((item) => {
                return item.attcount;
            });
            /** 在册人数 */
            let sumcount = this.amChartData.map((item) => {
                return item.sumcount;
            });
            let option = {
                tooltip: {
                    trigger: "axis",
                },
                grid: {
                    top: "18%",
                    left: "5%",
                    right: "6%",
                    bottom: "12%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: times,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#5E5E5E",
                        },
                    },
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: "#5E5E5E",
                        },
                    },
                    axisLabel: {
                        color: "#BABABA",
                        fontSize: 12,
                        // interval: 0,
                    },
                },
                yAxis: {
                    type: "value",
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#303030",
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#5E5E5E",
                        },
                    },
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: "#5E5E5E",
                        },
                    },
                    axisLabel: {
                        color: "#BABABA",
                    },
                },
                series: [
                    {
                        type: "line",
                        name: "出勤人数",
                        data: attcount,
                        lineStyle: {
                            color: "#FFD52B",
                        },
                        itemStyle: {
                            color: "#FFD631",
                        },
                    },
                    {
                        type: "line",
                        name: "在册人数",
                        data: sumcount,
                        lineStyle: {
                            color: "#15BBB6",
                        },
                        itemStyle: {
                            color: "#15BBB6",
                        },
                    },
                ],
            };
            await this.loadChart(`attmchart`, option);
        },
    },
};
