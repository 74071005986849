<template>
  <div class="tile datav-box">
    <div class="content-con">
      <div class="middlef-con">
        <div id="mapc" class="c-con"></div>
        <div class="left-con">
          <sincard :title="`设备列表`">
            <div class="h10" />
            <div class="rowct">
              <div class="voac-con">
                <div class="bt" @click="handleListRec()">
                  <img
                    :src="require('@/assets/images/home/public-relation.png')"
                  />
                  <p>开始喊话</p>
                </div>
                <div class="w10" />
                <div class="bt" @click="handleListPlayMusic()">
                  <img :src="require('@/assets/images/home/music.png')" />
                  <p>播放音乐</p>
                </div>
              </div>
            </div>
            <div class="h10" />
            <div class="list-con">
              <a-table
                :columns="columns"
                :data-source="list"
                :pagination="pagination"
                :loading="loading"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }"
              >
                <a slot="operation" slot-scope="text">
                  <p
                    @click="
                      () => {
                        handleLoc(text);
                      }
                    "
                  >
                    定位
                  </p>
                </a>
              </a-table>
            </div>
          </sincard>
        </div>
        <sincard
          :show-title="false"
          :title="`设备详情`"
          v-show="showDeviceWindow"
          ref="deviceInfo"
        >
          <div class="device-info" id="device-info">
            <p class="topti">设备详情</p>
            <p>设备编码：{{ curSelDevice.deviceId }}</p>
            <p>设备类型：{{ curSelDevice.deviceType }}</p>
            <p>设备名称：{{ curSelDevice.deviceName }}</p>
            <p>
              设备状态：{{ getDeviceStatusName(curSelDevice.deviceStatus) }}
            </p>
            <p>
              工作状态：{{
                getDeviceWorkStatusName(curSelDevice.deviceCurrentstatus)
              }}
            </p>
            <p>设备地址：{{ curSelDevice.deviceAddress }}</p>
            <p>经度坐标：{{ curSelDevice.deviceLng }}</p>
            <p>纬度坐标：{{ curSelDevice.deviceLat }}</p>
            <div class="voac-con">
              <div class="bt" @click="handleRec()">
                <img
                  :src="require('@/assets/images/home/public-relation.png')"
                />
                <p>开始喊话</p>
              </div>
              <div class="w10" />
              <div class="bt" @click="handlePlayMusic()">
                <img :src="require('@/assets/images/home/music.png')" />
                <p>播放音乐</p>
              </div>
            </div>
          </div>
          <div class="device-close" @click="handleCloseDewindow()">
            <p>X</p>
          </div>
        </sincard>
        <div class="recac-con" v-show="showRecordPanel">
          <sincard :show-title="false">
            <div class="c-con">
              <p class="topti">实时喊话</p>
              <div class="h10" />
              <a-progress :width="160" type="circle" :percent="vol">
                <template #format="percent">
                  <span style="color: #fff; font-size: 25px; font-style: bold"
                    >{{ percent }}%</span
                  >
                </template>
              </a-progress>
              <div class="h10" />
              <a-button
                :type="recording ? 'danger' : 'primary'"
                @click="handleRecordBtn()"
              >
                {{ recording ? "停止喊话" : "开始喊话" }}
              </a-button>
              <div class="h10" />
              <p>{{ voiceDuratime }}</p>
            </div>
            <div class="device-close" @click="handleCloseRecPanel()">
              <p>X</p>
            </div>
          </sincard>
        </div>
        <input
          ref="uploadInput"
          v-show="false"
          type="file"
          @change="changeFile"
          accept="mp3/*"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Recorder from "js-audio-recorder";
import { Progress, Tooltip } from "ant-design-vue";
import "ant-design-vue/lib/tooltip/style/index.css"; // 或者 ant-design-vue/lib/button/style/css 加载 css 文件
import "ant-design-vue/lib/progress/style/index.css"; // 或者 ant-design-vue/lib/button/style/css 加载 css 文件
let component = null;

const columns = [
  {
    title: "设备编号",
    dataIndex: "deviceId",
    // width: "20%",
  },
  {
    title: "设备名称",
    dataIndex: "deviceName",
    // width: "20%",
  },
  {
    title: "设备状态",
    dataIndex: "deviceStatusName",
  },
  {
    title: "操作",
    key: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
import voiceApi from "@/request/api/voice";

export default {
  name: "Voice",
  data() {
    return {
      selectedRowKeys: [],
      list: [],
      pagination: {},
      loading: false,
      columns,
      realfSwiperOption: {
        speed: 500,
        loop: true,
        autoplay: { disableOnInteraction: false, delay: 3000 },
        direction: "vertical",
        autoHeight: false,
        initialSlide: 0,
        slidesPerView: 5,
      },
      notNextTickOfrealfSwiper: true,
      reals: [],
      showDeviceWindow: false,
      curSelDevice: {},
      markers: [],
      recorder: new Recorder({
        sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
        sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
        numChannels: 1, // 声道，支持 1 或 2， 默认是1
        compiling: true, // 是否边录边转换，默认是false (0.x版本中生效,1.x增加中)
      }),
      voiceDuratime: "00:00:00",
      vol: 0,
      recording: false,
      showRecordPanel: false,
      callId: "",
      recordIntervalId: null,
    };
  },
  components: {
    "a-progress": Progress,
    "a-tooltip": Tooltip,
  },
  beforeCreate() {
    component = this;
  },
  methods: {
    async changeFile(event) {
      let files = event.target.files[0]; //获取文件
      let list = this.list.filter((item) => {
        let idx = item.idx;
        return this.selectedRowKeys.some((o) => o == idx);
      });
      let deviceId = list.map((item) => item.deviceId);
      deviceId = deviceId.join(",");
      await voiceApi.uploadvideo({
        file: files,
        deviceId,
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      this.selectedRowKeys = selectedRowKeys;
    },
    handleListRec() {
      if (this.selectedRowKeys.length == 0) {
        this.$message.error("请勾选需要操作的设备");
        return;
      }
      this.showRecordPanel = true;
    },
    handlePlayMusic() {
      let { idx } = this.curSelDevice;
      this.selectedRowKeys = [idx];
      this.handleListPlayMusic();
    },
    handleListPlayMusic() {
      if (this.selectedRowKeys.length == 0) {
        this.$message.error("请勾选需要操作的设备");
        return;
      }
      this.$refs.uploadInput.click();
    },

    handleCloseRecPanel() {
      this.showRecordPanel = false;
    },
    async handleRec() {
      let { idx } = this.curSelDevice;
      this.selectedRowKeys = [idx];
      this.showRecordPanel = true;
    },
    async getRecCallId() {
      let list = this.list.filter((item) => {
        let idx = item.idx;
        return this.selectedRowKeys.some((o) => o == idx);
      });
      let deviceId = list.map((item) => item.deviceId);
      deviceId = deviceId.join(",");
      this.$loading.show();
      try {
        let res = await voiceApi.startshout({
          deviceId,
          type: "pcm",
        });
        this.$loading.hide();
        let { callId } = res;
        this.callId = callId;
      } catch (error) {
        console.log(error);
        this.$loading.hide();
      }
    },
    async handleRecordBtn() {
      if (!this.recording) {
        await this.getRecCallId();
        this.startRecordAudio();
      } else {
        this.stopRecordAudio();
      }
    },
    async handleCloseDewindow() {
      this.showDeviceWindow = false;
      await this.$nextTick();
      this.infoWindow.close();
    },
    //开始录音
    startRecordAudio() {
      Recorder.getPermission().then(
        () => {
          console.log("开始录音");
          this.recorder.start(); // 开始录音
          this.recording = true;
          if (this.recordIntervalId) clearInterval(this.recordIntervalId);
          this.recordIntervalId = setInterval(async () => {
            let params = {};
            try {
              let datastr = await this.getRecorderPcmbase64();
              params = {
                callId: this.callId,
                type: "pcm",
                tag: 0,
                volData: datastr,
              };
              console.log("实时语音获取成功");
            } catch (error) {
              console.log("实时语音获取失败");
              console.log(error);
            }
            try {
              let res = await voiceApi.sendshout(params);
              console.log("实时语音发送成功");
              console.log(res);
            } catch (error) {
              console.log("实时语音发送失败");
              console.log(error);
            }
          }, 600);
        },
        (error) => {
          this.$message({
            message: "请先允许该网页使用麦克风",
            type: "info",
          });
          console.log(`${error.name} : ${error.message}`);
        }
      );
    },
    async getRecorderPcmbase64() {
      return new Promise((resolve, reject) => {
        try {
          if (!this.recorder) {
            return;
          }
          let newData = this.recorder.getNextData();
          if (!newData.length) {
            return;
          }
          let byteLength = newData[0].byteLength;
          let buffer = new ArrayBuffer(newData.length * byteLength);
          let dataView = new DataView(buffer);
          // 数据合并
          for (let i = 0, iLen = newData.length; i < iLen; ++i) {
            for (let j = 0, jLen = newData[i].byteLength; j < jLen; ++j) {
              dataView.setInt8(i * byteLength + j, newData[i].getInt8(j));
            }
          }
          let fr = new FileReader();
          fr.onload = (e) => {
            //获取base64 字符串
            let str = e.target.result;
            str = str.split("base64,")[1];
            let result = encodeURIComponent(encodeURIComponent(str));
            resolve(result);
          };
          fr.readAsDataURL(new Blob([dataView]));
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    async stopRecordAudio() {
      this.recorder.stop();
      this.recording = false;
      this.vol = 0;
      this.voiceDuratime = "00:00:00";
      let params = {
        callId: this.callId,
        type: "pcm",
        tag: 1,
      };
      try {
        let res = await voiceApi.sendshout(params);
        console.log("实时语音发送停止成功");
        console.log(res);
      } catch (error) {
        console.log("实时语音发送停止失败");
        console.log(error);
      }
    },
    getDeviceStatusName(status) {
      status = parseInt(status);
      switch (status) {
        case 1:
          return "正常";
        case 2:
          return "掉线";
        case 3:
          return "禁用";
        default:
          return "未知状态";
      }
    },
    getDeviceWorkStatusName(status) {
      status = parseInt(status);
      switch (status) {
        case 1:
          return "空闲";
        case 2:
          return "喊话";
        case 3:
          return "播放音乐";
        default:
          return "未知状态";
      }
    },
    handleLoc(item) {
      console.log(item);
      let { deviceLat, deviceLng } = item;
      let map = this.map;
      if (this.markers.length > 0) {
        let exist = this.markers.some((o) => {
          return o.dataItem.id == item.id;
        });
        if (exist) {
          this.curSelDevice = item;
          map.setCenter([deviceLng, deviceLat]); //设置地图中心点
          return;
        }
      }
      //public-relation
      let element = [deviceLng, deviceLat];
      let staname = item.deviceName;
      let marker = new AMap.Marker({
        map: map,
        position: element,
        icon: require("@/assets/images/home/public-relation.png"),
        offset: new AMap.Pixel(-23, -10),
        autoRotation: true,
      });
      marker.dataItem = item;
      // marker.on("click", this.showVideoInfo);
      marker.setMap(map);

      // 设置鼠标划过点标记显示的文字提示
      marker.setTitle(staname);

      // 设置label标签
      // label默认蓝框白底左上角显示，样式className为：amap-marker-label
      marker.setLabel({
        offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
        content: `<div class='info' style='color:#000;'>${staname}</div>`, //设置文本标注内容
        direction: "top", //设置文本标注方位
      });
      //给marker添加点击事件
      marker.on("click", async (e) => {
        this.showDeviceWindow = true;
        await this.$nextTick();
        this.infoWindow.setContent(this.$refs.deviceInfo.$el);
        this.infoWindow.open(map, marker.getPosition());
      });
      this.markers.push(marker);
      this.curSelDevice = item;
      map.setCenter([deviceLng, deviceLat]); //设置地图中心点
    },
    async loadData() {
      try {
        let res = await voiceApi.devicelist();
        let { list } = res;
        this.transData(list);
        this.list = list;
      } catch (error) {
        console.log(error);
      }
    },
    transData(list) {
      list.forEach((element, idx) => {
        let { deviceStatus } = element;
        let deviceStatusName = this.getDeviceStatusName(deviceStatus);
        element.deviceStatusName = deviceStatusName;
        element.idx = idx;
      });
    },
    /**
     * @param {Number} millisecond 时间差：秒
     * @returns format as "00:00:00"
     */
    formatTime(seconds) {
      let result = [];
      let count = 2;
      while (count >= 0) {
        let current = Math.floor(seconds / 60 ** count);
        result.push(current);
        seconds -= current * 60 ** count;
        --count;
      }
      return result.map((item) => (item <= 9 ? `0${item}` : item)).join(":");
    },
  },
  mounted() {
    // {sampleBits:16,sampleRate:16e3,numChannels:1,compiling:!0}
    let _this = this;
    this.recorder.onprogress = function (params) {
      console.log("录音时长(秒)", params.duration);
      console.log("录音大小(字节)", params.fileSize);
      console.log("录音音量百分比(%)", params.vol);
      _this.vol = params.vol.toFixed(2);
      _this.voiceDuratime = _this.formatTime(params.duration);
      // console.log('当前录音的总数据([DataView, DataView...])', params.data);
    };
    this.loadData();
    let map = new AMap.Map("mapc", {
      mapStyle: "amap://styles/dark", //设置地图的显示样式
      resizeEnable: true,
      layers: [new AMap.TileLayer.Satellite()],
      center: [106.520834, 29.460517],
      zoom: 14,
    });
    this.map = map;
    //
    this.infoWindow = new AMap.InfoWindow({
      isCustom: true, //使用自定义窗体
      // closeWhenClickMap: true,   //点击地图隐藏窗体
      content: "",
      offset: new AMap.Pixel(0, -52),
    });
  },
  beforeDestroy() {
    if (this.markers.length > 0) {
      this.markers.forEach((marker) => {
        marker.off("click");
      });
    }
    this.recorder.destroy().then(() => {
      this.recorder = null;
    });
  },
};
</script>

<style lang="scss" scoped>
.rowct {
  position: relative;
  width: 100%;
  @include flrowjusali();
}
.voac-con {
  position: relative;
  @include flrowjusali();
  .bt {
    position: relative;
    width: 120px;
    @include flrowjusali(space-between);
    img {
      width: 30px;
    }
    border: 1px solid #1490f2;
    box-sizing: border-box;
    padding: 5px 10px;
    cursor: pointer;
  }
}
.w10 {
  width: 10px;
}
.h10 {
  height: 10px;
}
.device-close {
  position: absolute;
  right: 15px;
  top: 15px;
  p {
    font-size: 20px;
  }
  cursor: pointer;
}
.topti {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.recac-con {
  position: absolute;
  width: 301px;
  top: 100px;
  left: 550px;
  .c-con {
    width: 100%;
    position: relative;
    @include flcoljusali();
  }
}
.device-info {
  position: relative;
  width: 301px;
  p {
    margin-bottom: 10px;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
}
.content-con {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  @include flrowjusali(space-between, flex-start);

  .middlef-con {
    position: relative;
    width: 100%;
    height: 100%;
    @include flcoljusali(space-between, flex-start);
    .c-con {
      height: calc(100%);
      width: 100%;
    }
    .bottom-con {
      position: relative;
      width: 100%;
      height: 62.44%;
      @include flrowjusali(space-between);

      .listcs-con {
        position: relative;
        width: calc(50% - 10px);
        height: 100%;
        box-sizing: border-box;

        .list-con {
          position: relative;
          padding: 0 10px;
          box-sizing: border-box;
          width: 100%;
          height: calc(100% - 200px);

          .table-con {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            font-size: 14px;
            @include flcoljusali(flex-start, flex-start);

            .title-con {
              position: relative;
              border: 1px solid #81cdf9;
              width: 100%;
              height: 40px;
              box-sizing: border-box;
              @include flrowjusali();
              padding-right: 10px;

              &::before {
                display: block;
                content: "";
                width: 100%;
                height: 100%;
                background: linear-gradient(
                  90deg,
                  #0f5177,
                  #65bcff 51%,
                  #0e5076
                );
                opacity: 0.5;
                position: absolute;
                left: 0;
                top: 0;
              }

              .item-con {
                flex: 1;
                width: 0;
                text-align: center;
                color: #fff;
                position: relative;
                font-weight: bold;
                z-index: 3;

                &:nth-child(1) {
                  flex: 0.5;
                }
              }
            }

            .scr-con {
              position: relative;
              width: 100%;
              box-sizing: border-box;
              flex: 1;
              height: 0;

              .swiper-container {
                position: relative;
                height: 100%;

                .swiper-slide {
                  height: auto !important;

                  .tr {
                    color: #fff;
                    height: 56px;
                    background: hsla(0, 0%, 42%, 0.3);
                    width: 100%;
                    box-sizing: border-box;
                    padding-right: 10px;
                    @include flrowjusali(flex-start);
                    white-space: nowrap;

                    .td {
                      text-overflow: bold;
                      text-align: center;
                      overflow: hidden;
                      flex: 1;
                      width: 0;
                      padding: 0 5px;
                      box-sizing: border-box;

                      &:nth-child(1) {
                        flex: 0.5;
                        width: 0;
                      }
                    }
                  }

                  &:nth-child(2n) {
                    .tr {
                      background: transparent;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .left-con {
      position: absolute;
      left: 10px;
      top: 10px;
      width: 500px;
      height: calc(100% - 20px);
      .sincard-con {
        height: 100%;
        opacity: 0.9;
        ::v-deep .box {
          height: 0;
          box-sizing: border-box;
        }
        ::v-deep .boxInner {
          height: 100%;
          width: 100%;
          @include flcoljusali(flex-start, flex-start);
        }
      }
      .list-con {
        position: relative;
        padding: 0 10px;
        box-sizing: border-box;
        width: 100%;
        flex: 1;
        height: 0;
      }
    }
  }
}
</style>
