import http from '../http.js';

const api = {
  /** 工种在册分析 */
  getworkertype: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/worker/getworkertype`,
      data: params,
    });
  },
  /** 劳务实名列表(管理人员/劳务人员) */
  getworkerenter: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/worker/getworkerenter`,
      data: params,
    });
  },
  /** 在册人员/出勤率趋势 */
  getAttachment: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/worker/getAttachment`,
      data: params,
    });
  },
  /** 考勤记录 */
  getnewkaoqin: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/worker/getnewkaoqin`,
      data: params,
    });
  },
};

export default api;
