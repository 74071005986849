import overlayApi from '@/request/api/overlay.js';
import { Tooltip, Progress } from 'ant-design-vue';
export default {
  name: 'Towercr',
  data() {
    return {
      notNextTickOfLogSwiper: true,
      logSwiperOption: {
        speed: 500,
        loop: true,
        autoplay: { disableOnInteraction: false, delay: 3000 },
        direction: 'vertical',
        autoHeight: false,
        initialSlide: 0,
        slidesPerView: 6,
      },
      /** 设备日志列表 */
      warlist: [],
    };
  },
  components: {
    'a-tooltip': Tooltip,
    'a-progress': Progress,
  },
  mounted() {
    /** 设备日志信息 */
    (async () => {
      let res = await overlayApi.getWarnCenter();
      this.warlist = res;
    })();
  },
};
