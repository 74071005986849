<template>
  <div :class="slideClass">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "swiper-slide",
  data() {
    return {
      slideClass: "swiper-slide",
    };
  },
  ready() {
    this.update();
  },
  mounted() {
    this.update();
    if (
      this.$parent &&
      this.$parent.options &&
      this.$parent.options.slideClass
    ) {
      this.slideClass = this.$parent.options.slideClass;
    }
  },
  updated() {
    console.log(`slide preupdate`);
    /**3.1.3有个bug需要添加这句判断 不然会造成页面卡死 */
    if (this.$parent.options && this.$parent.options.isUpdate) {
      console.log(`slide update`);
      this.update();
    }
  },
  attached() {
    this.update();
  },
  methods: {
    update() {
      if (this.$parent && this.$parent.swiper) {
        this.$parent.update();
      }
    },
  },
};
</script>
