<template>
  <div class="chartNum">
    <div class="box-item">
      <li :class="{'number-item': !isNaN(item), 'mark-item': isNaN(item) }"
          :style="!isNaN(item) ? otStyle : null"
          v-for="(item,index) in orderNum"
          :key="index">
        <span v-if="!isNaN(item)">
         <i ref="numberItem">0123456789</i>
        </span>
        <span class="comma" :style="commautStyle" v-else>{{ item }}</span>
      </li>

    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number, // 具体数值
      default() {
        return 0;
      },
    },
    time: {
      type: Number, // 滚动要花的时间，单位秒
      default() {
        return 3;
      },
    },
    otStyle: {
      type: Object,
      default: function () {
        return {};
      }
    },
    commautStyle: {
      type: Object,
      default() {
        return {};
      },
    }
  },
  data() {
    return {
      // orderNum: ['0', '0', ',', '0', '0', '0', ',', '0', '0', '0'], // 默认订单总数
      orderNum: ['0', '0', '0', '0', '0', '0'], // 默认订单总数
    }
  },
  watch: {
    value(nv, ov) {
      this.toOrderNum(nv) // 这里输入数字即可调用
      this.increaseNumber(this.time);
    }
  },
  mounted() {
    this.toOrderNum(this.value) // 这里输入数字即可调用
    this.increaseNumber(this.time);
  },
  methods: {
    // 定时增长数字
    increaseNumber(time) {
      let self = this
      this.timer = setInterval(() => {
        self.newNumber = self.newNumber + self.getRandomNumber(1, 100)
        self.setNumberTransform()
      }, time * 1000)
    },
    // 设置文字滚动
    setNumberTransform() {
      const numberItems = this.$refs.numberItem // 拿到数字的ref，计算元素数量
      const numberArr = this.orderNum.filter(item => !isNaN(item))
      // 结合CSS 对数字字符进行滚动,显示订单数量
      for (let index = 0; index < numberItems.length; index++) {
        const elem = numberItems[index]
        elem.style.transform = `translate(-50%, -${numberArr[index] * 10}%)`
      }
    },
    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    // 处理传过来的具体值value
    toOrderNum(num) {
      num = num.toString()
      // 把具体值value变成字符串
      if (num.length < 6) {
        num = '0' + num // 如未满4位数，添加"0"补位
        this.toOrderNum(num) // 递归添加"0"补位
      } else if (num.length === 6) {
        // 具体值value中加入逗号 8位
        num = num.slice(0, 3) + ',' + num.slice(3, 6) + "㎡";
        this.orderNum = num.split('') // 将其便变成数据，渲染至滚动数组
      } else {
        // 具体值value数字超过4位显示异常
        this.$message.warning('xxx数量过大，显示异常，请联系后台管理员')
      }
    },
  }
}
</script>
<style scoped lang='scss'>
/*具体值value总量滚动数字设置*/
.box-item {
  position: relative;
  height: 100px;
  font-size: 54px !important;
  line-height: 41px;
  text-align: center;
  list-style: none;
  color: #333;
  writing-mode: vertical-lr;
  text-orientation: upright;
  /*文字禁止编辑*/
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;

  span {
    font-size: 66px !important;
  }

  li {
    position: relative;
    overflow-x: hidden;
  }

  /* overflow: hidden; */
}

/* 默认逗号设置 */
.mark-item {
  width: 10px;
  height: 100px;
  margin-right: 10px;
  line-height: 10px;
  position: relative;

  & > span {
    font-size: 48px !important;
    position: absolute;
    width: 100%;
    bottom: 10px;
    writing-mode: vertical-rl;
    text-orientation: upright;
    color: #219AFF;
  }

  &:nth-last-child(1) {
    overflow-x: visible;
    margin-right: 0;
    & > span {
      font-size: 15px !important;
      bottom: 20px;
    }
  }
}

/*滚动数字设置*/
.number-item {
  width: 60px;
  height: 100px;
  list-style: none;
  margin-right: 10px;
  background: #14425F;
  border-radius: 4px;
  border: 1px solid #23546E;

  & > span {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    width: 100%;
    height: 100%;
    writing-mode: vertical-rl;
    text-orientation: upright;
    overflow: hidden;
    color: #fff;

    & > i {
      font-style: normal;
      position: absolute;
      top: 17px;
      font-family: "Microsoft YaHei";
      left: 50%;
      transform: translate(-50%, 0);
      transition: transform 1s ease-in-out;
      letter-spacing: 10px;
    }
  }
}

.number-item:last-child {
  margin-right: 0;
}
</style>
