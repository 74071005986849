//路线测试数据
export default {
  0: [
    [116.478935, 39.997761],
    [116.478939, 39.997825],
    [116.478912, 39.998549],
    [116.478912, 39.998549],
    [116.478998, 39.998555],
    [116.478998, 39.998555],
    [116.479282, 39.99856],
    [116.479658, 39.998528],
    [116.480151, 39.998453],
    [116.480784, 39.998302],
    [116.480784, 39.998302],
    [116.481149, 39.998184],
    [116.481573, 39.997997],
    [116.481863, 39.997846],
    [116.482072, 39.997718],
    [116.482362, 39.997718],
    [116.483633, 39.998935],
    [116.48367, 39.998968],
    [116.484648, 39.999861],
  ],
  1: [
    [106.552843, 29.561472],
    [106.552843, 29.561472],
    [106.552586, 29.564496],
    [106.547093, 29.564533],
  ],
  2: [
    [106.556577, 29.561136],
    [106.559538, 29.559008],
    [106.555719, 29.555947],
    [106.5525, 29.555984],
    [106.550826, 29.557104],
    [106.548895, 29.553446],
  ],
  3: [
    [106.504092,29.550921],
    [106.498309,29.535539],
    [106.502386,29.523086],
    [106.502375,29.516527],
    [106.513823,29.507938],//杨家坪站
    [106.527001,29.499468],
    [106.539014,29.49662],
    [106.543091,29.489523],
    [106.540645,29.483733],//电厂站
    [106.528435,29.469498],//长江二桥站
    [106.520834,29.460517],
    [106.517358,29.446691],
    [106.517358,29.432376],
    [106.504655,29.425611],
    [106.492006,29.414565],
    [106.478916,29.416696],
    [106.46381,29.41649],
    [106.437589,29.408228],
    [106.463724,29.416565],
  ],
};

export let stationNames = [
  '富华路站',
  '歇台子站',
  '奥体中心站',
  '石坪桥站',
  '杨家坪站',
  '滩子口站',
  '黄桷坪站',
  '四川美院站',
  '电厂站',
  '长江二桥站',
  '外河坪北站',
  '外河坪南站',
  '简家槽站',
  '茄子溪站',
  '白居寺站',
  '伏牛溪站',
  '金鳌山站',
  '跳磴南站',
  '跣磴站',
];
