<template>
  <a-config-provider :locale="zh_CN">
    <div class="app">
      <a-spin :spinning="spinning" size="large">
        <keep-alive>
          <!--使用keep-alive会将页面缓存-->
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </a-spin>
    </div>
  </a-config-provider>
</template>

<script>
import Vue from "vue";
import utils from "@/utils/utils.js";
import MutationType from "@/store/mutation-type.js";
import axios from "axios";
import rem from "@/utils/rem.js";
import { Toast } from "vant";
import VConsole from "vconsole";
import NavBar from "@/components/nav/index/index.vue";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
import { mapGetters } from "vuex";
moment.locale("zh-cn");

export default {
  data() {
    return {
      zh_CN,
    };
  },
  components: {
    NavBar,
  },
  computed: {
    ...mapGetters(["spinning"]),
  },
  created() {},
  watch: {
    $route(to, from) {},
  },
  mounted() {
    // new VConsole();
    this.$store.commit(
      MutationType.SET_SYSTITLE,
      "轨道交通十八号线指挥调度系统"
    );
  },
  methods: {},
};
</script>

<style lang="scss">
@import "~@/styles/reset.scss";
@import "~@/styles/common.scss";
</style>

<style lang="scss">
.app {
  $font-size: 14px;
  position: relative;
  color: rgba($color: #fff, $alpha: 0.65);
  font-size: 14px;
  button,
  em,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  input,
  p,
  span,
  strong,
  textarea {
    font-size: $font-size;
    font-weight: bold;
  }
  p {
    margin-bottom: 0;
  }
  *,
  :after,
  :before {
    box-sizing: border-box;
  }
}
</style>
<style lang="scss">
@import "~@/styles/antcs.scss";
</style>
