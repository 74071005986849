import http from '../http.js';

const api = {
  /** 升降机在线统计 */
  getonline: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/shengjiangji/getonline`,
      data: params,
    });
  },
  /** 升降机实时统计 */
  getrealtime: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/shengjiangji/getrealtime`,
      data: params,
    });
  },
  /** 升降机报警 */
  getwarning: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/shengjiangji/getwarning`,
      data: params,
    });
  },
  /** 报警统计 */
  getwarningtotal: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/shengjiangji/getwarningtotal`,
      data: params,
    });
  },
  /** 报警统计按天 */
  getwarningday: function(params = {}) {
    return http({
      apiDomainIndex: 0,
      method: 'get',
      url: `/datav/shengjiangji/getwarningday`,
      data: params,
    });
  },
};

export default api;
